import { Reducer } from "redux";
import { EventState} from "./types";
import { EventActions } from "./actions";
import { EventActionsTypes } from "./types";

const defaultState: EventState = {
    errorMessage: '',
    addresses: [],
    selectedAddress: null,
    addressValue: '',
    eventError: null,
    eventSuccess: null,
    geoPointNull: false,
    rollUpCards: [],
    openEmployeesCard: false,
    employeesCount: 0,
    openActiveOrders: false,
    activeOrders: [],
    openOrder: false,
    points: [],
    openOrderRegistration: false,
    activeEmployees: [],
    updateGpsData: null,
    routes: [{id: "1"}, {id: "2"}],
    selectedPoint: "",
    additionalServices: [],
    calculateCost: null,
    currentCalcCost: undefined,
    isRegistration: true,
    ratios: [{ id: "1" }],
    propertySet: undefined,
    propertyValueSet: [],
    currentPropertyValueSet: undefined,
    loading: false,
    shortGroups: [],
    employeeRoles: [],
    routingInfo: null,
    cars: [],
    currentCar: undefined
};

export const authReducer: Reducer<EventState, EventActions> = (state = defaultState, action) => {
    switch (action.type) {
        case EventActionsTypes.CURRENT_CAR:
            return {
                ...state,
                currentCar: action.currentCar
            };
        case EventActionsTypes.CARS:
            return {
                ...state,
                cars: action.cars
            };
        case EventActionsTypes.ROUTING_INFO:
            return {
                ...state,
                routingInfo: action.routingInfo
            };
        case EventActionsTypes.EVENT_SUCCESS:
            return {
                ...state,
                eventSuccess: action.eventSuccess
            };
        case EventActionsTypes.EMPLOYEE_ROLE:
            return {
                ...state,
                employeeRoles: action.employeeRoles
            };
        case EventActionsTypes.SHORT_GROUPS:
            return {
                ...state,
                shortGroups: action.shortGroups
            };
        case EventActionsTypes.LOADING:
            return {
                ...state,
                loading: action.loading
            };
        case EventActionsTypes.CURRENT_PROPERTY_VALUE_SET:
            return {
                ...state,
                currentPropertyValueSet: action.currentPropertyValueSet
            };
        case EventActionsTypes.PROPERTY_VALUE_SET:
            return {
                ...state,
                propertyValueSet: action.propertyValueSet
            };
        case EventActionsTypes.PROPERTY_SET:
            return {
                ...state,
                propertySet: action.propertySet
            };
        case EventActionsTypes.RATIOS:
            return {
                ...state,
                ratios: action.ratios
            };
        case EventActionsTypes.RATIOS:
            return {
                ...state,
                ratios: action.ratios
            };
        case EventActionsTypes.IS_REGISTRATION:
            return {
                ...state,
                isRegistration: action.isRegistration
            };
        case EventActionsTypes.CALCULATE_COST:
            return {
                ...state,
                calculateCost: action.calculateCost
            };
        case EventActionsTypes.CURRENT_CALC_COST:
            return {
                ...state,
                currentCalcCost: action.currentCalcCost
            };
        case EventActionsTypes.ADDITIONAL_SERVICES:
            return {
                ...state,
                additionalServices: action.additionalServices,
                isRegistration: false
            };
        case EventActionsTypes.SET_SELECTED_POINT:
            return {
                ...state,
                selectedPoint: action.selectedPoint
            };
        case EventActionsTypes.SET_ROUTES:
            return {
                ...state,
                routes: action.routes
            };
        case EventActionsTypes.SET_ROUTE:
            const newRoutes = state.routes.concat();
            newRoutes.splice(action.index + 1, 0, action.route);
            return {
                ...state,
                routes: newRoutes
            };
        case EventActionsTypes.GET_ACTIVE_EMPLOYEES:
            return {
                ...state,
                activeEmployees: action.activeEmployees
            };
        case EventActionsTypes.UPDATE__GPS_DATA:
            return {
                ...state,
                updateGpsData: action.updateGpsData
            };
        case EventActionsTypes.OPEN_ORDER_REGISTRATION:
            return {
                ...state,
                openOrderRegistration: action.openOrderRegistration
            };
        case EventActionsTypes.SET_POINTS:
            return {
                ...state,
                points: action.points
            };
        case EventActionsTypes.OPEN_ORDER:
            return {
                ...state,
                openOrder: action.openOrder
            };
        case EventActionsTypes.ACTIVE_ORDERS:
            return {
                ...state,
                activeOrders: action.activeOrders
            };
        case EventActionsTypes.OPEN_ACTIVE_ORDERS:
            return {
                ...state,
                openActiveOrders: action.openActiveOrders
            };
        case EventActionsTypes.EMPLOYEES_COUNT:
            return {
                ...state,
                employeesCount: action.employeesCount
            };
        case EventActionsTypes.OPEN_EMPLOYEES_CARD:
            return {
                ...state,
                openEmployeesCard: action.openEmployeesCard
            };
        case EventActionsTypes.ROLL_UP_CARDS:
            return {
                ...state,
                rollUpCards: action.rollUpCards
            };
        case EventActionsTypes.ROLL_UP_CARD:
            const rollUpCards = state.rollUpCards.concat(action.rollUpCard);
            return {
                ...state,
                rollUpCards
            };
        case EventActionsTypes.GEO_POINT_NULL:
            return {
                ...state,
                geoPointNull: action.geoPointNull
            };
        case EventActionsTypes.EVENT_ERROR:
            return {
                ...state,
                eventError: action.eventError
            };
        case EventActionsTypes.ADDRESS_VALUE:
            return {
                ...state,
                addressValue: action.addressValue
            };
        case EventActionsTypes.SELECTED_ADDRESS:
            return {
                ...state,
                selectedAddress: action.selectedAddress
            };
        case EventActionsTypes.GET_ADDRESSES:
            return {
                ...state,
                addresses: action.addresses
            };
        case EventActionsTypes.ERROR_MESSAGE:
            return {
                ...state,
                errorMessage: action.errorMessage
            };
        default:
            return state;
    }
};

export default authReducer;