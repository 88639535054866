import { Trans, useTranslation } from 'react-i18next';
import GetAddressAutocomplete from '../bricks/GetAddressAutocomplete';
import GetCityAutocomplete from '../bricks/GetCityAutocomplete';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Routes } from '../../store/event/types';
import { Box,Grid, IconButton, Tooltip } from '@material-ui/core';

type Props = {
    cityName?: string;
    handleCityChange: (value: string) => void;
    addItem: (id: string) => void;
    routes: Routes[];
    route: Routes;
    deleteItem: (id: string) => void;
};

export const RouteItem = (props: Props) => {
    const { t } = useTranslation();
    const {
        cityName,
        handleCityChange,
        addItem,
        deleteItem,
        routes,
        route
    } = props;

    const deleteButton = () => {
        if(routes.length <= 2) {
            return (
                <IconButton
                    size="small"
                    disabled={true}>
                    <DeleteIcon />
                </IconButton>
            )
        }

        return (
            <Tooltip placement="top" title={<Trans i18nKey="delete" />}>
                <IconButton 
                    aria-label="delete" 
                    size="small"
                    disabled={routes.length <= 2}
                    onClick={() => deleteItem(route.id)}>
                    <DeleteIcon />
                </IconButton>
            </Tooltip>
        )
    }

    return (
        <Grid 
            item xs={12}>
            <Grid container spacing={1} alignItems="center">
                <Grid item>
                    <GetCityAutocomplete 
                        addressInfo={null} 
                        placeholder={t("city")+" *"}
                        value={cityName}
                        inputClassName="city-input" 
                        onChange={(value: string) => handleCityChange(value)} 
                    />
                </Grid>
                <Grid item xs={7}>
                    <GetAddressAutocomplete 
                        addressInfo={null} 
                        placeholder={t("streetHouseNumber")}
                        cityName={cityName} 
                        route={route} />
                </Grid>
                <Grid item>
                    <Box display="flex" justifyContent="flex-end">
                        <Tooltip placement="top" title={<Trans i18nKey="add" />}>
                            <IconButton 
                                aria-label="add" size="small"
                                onClick={() => addItem(route.id)}>
                                <AddCircleIcon />
                            </IconButton>
                        </Tooltip>
                        {deleteButton()}
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );
};