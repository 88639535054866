import React from 'react';
import { useTranslation } from 'react-i18next';
type Props = {
  
};

export function Settings(props: Props) {
    const { t } = useTranslation();
    return (
        <div style={{width: '100%', padding: 15}}>
            {t("settings")}
        </div>
    );
};