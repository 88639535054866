import { Action } from 'redux';
import { IEventUserIdentification } from '../../RemoteCommands/type';
import { UserActionTypes } from './types';

export interface IUserIdentification extends Action<UserActionTypes.GET_IDENTIFICATION> {
    identificationInfo: IEventUserIdentification | null;
}

export type UserActions =
    | IUserIdentification;

export function identificationInfoAction(identificationInfo: IEventUserIdentification | null) {
    if(identificationInfo) {
        // identificationInfo.availableApplicationFunctions = ["ChangeEmployeeData"];
        localStorage.setItem('licenceInfo', JSON.stringify(identificationInfo));
    } else {
        localStorage.removeItem("licenceInfo");
    }
    const action: IUserIdentification = {
        type: UserActionTypes.GET_IDENTIFICATION,
        identificationInfo
    }
    return action;
}