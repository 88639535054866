import { Dispatch } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { actions, RootState } from '../../store';
import { connect } from 'react-redux';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import taxiIcon from '../../img/taxi-icon.png';
import taxiMinivanIcon from '../../img/taxi-minivan.png';
import { CalculatedCosts } from '../../RemoteCommands/type';
import './TariffsCarousel.scss';
import { useTranslation } from 'react-i18next';

type Props = 
    ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps> & {
    calculatedCosts: CalculatedCosts[];
}

function TariffsCarousel(props: Props) {
    const { t } = useTranslation();
    const {
        calculatedCosts,
        currentCalcCost,
        currentCalcCostAction,
        routes
    } = props;

    const isRoutes =  routes.filter(item => item.geoPoint !== undefined).length < 2;
    const costPrefix = isRoutes ? t("from") : "";

    const handleClick = (tariff: CalculatedCosts) => {
        currentCalcCostAction(tariff);
    }

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1
    };
    return (
        <Slider 
            {...settings}
            className="tariff">
            {calculatedCosts.map(item => {
                const active = currentCalcCost && item.costCalculationId === currentCalcCost.costCalculationId ? "active" : "";
                const minivan = item.tariffDescription === "Минивен";
                return (
                    <div key={item.costCalculationId}>
                    <div 
                        className={`tariff__item ${active}`}
                        onClick={() => handleClick(item)}>
                        <div>
                            <img className="tariff__img" src={minivan ? taxiMinivanIcon : taxiIcon} alt="" />
                            <div className="tariff__title">{item.tariffDescription}</div>
                            <div className="tariff__price">{`${costPrefix} ${item.cost}${item.currency}`}</div>
                        </div>
                    </div>
                </div>
                )
            })}
        </Slider>
    );
}

const mapStateToProps = (state: RootState) => ({
    currentCalcCost: state.event.currentCalcCost,
    routes: state.event.routes
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({ 
    currentCalcCostAction: (currentCalcCost: CalculatedCosts | undefined) => 
        dispatch(actions.event.currentCalcCostAction(currentCalcCost))
});

export default connect(mapStateToProps, mapDispatchToProps)(TariffsCarousel);