import { Dispatch, useState } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { RootState, actions } from '../../store';
import { connect } from 'react-redux';
import { withLocalize, LocalizeContextProps } from "react-localize-redux";
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import GTranslateIcon from '@material-ui/icons/GTranslate';
import { useTranslation } from 'react-i18next';
import "./LanguageSelector.scss";

type Props = 
    ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps>
    & LocalizeContextProps;

const LanguageSelector = (props: Props) => {
    const {
        lang,
        langAction
    } = props;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const { i18n } = useTranslation();

    const changeLanguage = (_lang: string) => {
        i18n.changeLanguage(_lang);
        langAction(_lang);
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleRuClose = () => {
        // props.setActiveLanguage("ru"); 
        // localStorageSetItem("lang", "ru");
        changeLanguage("ru");
        setAnchorEl(null);
    };

    const handleDeClose = () => {
        changeLanguage("de");
        setAnchorEl(null);
    }

    const handleEnClose = () => {
        changeLanguage("en");
        setAnchorEl(null);
    };

    return (
        <>
            <Button 
                aria-controls="simple-menu"
                aria-haspopup="true" 
                onClick={handleClick} 
                className="lang-button">
                <GTranslateIcon 
                    className="pr-1" />
                {lang}
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem 
                    onClick={handleEnClose}>
                    <span className="lang-icon"><span className="flag-icon flag-icon-us flag-icon-squared"/>EN</span>
                </MenuItem>
                <MenuItem 
                    onClick={handleDeClose}>
                    <span className="lang-icon"><span className="flag-icon flag-icon-de flag-icon-squared"/>DE</span>
                </MenuItem>
                <MenuItem 
                    onClick={handleRuClose}>
                    <span className="lang-icon"><span className="flag-icon flag-icon-ru flag-icon-squared"/>RU</span>
                </MenuItem>
            </Menu>
        </>
    )
};

const mapStateToProps = (state: RootState) => ({
    lang: state.app.lang,
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    langAction: (lang: string) => 
        dispatch(actions.app.langAction(lang)),
});

export default withLocalize(connect(
    mapStateToProps,
    mapDispatchToProps
)(LanguageSelector));
