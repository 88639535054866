import { Dispatch, useEffect, useState } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { actions, RootState } from '../../store';
import { connect } from 'react-redux';
import TariffsContent from './TariffsContent';
import { Tariff } from '../../RemoteCommands/type';
import { errorCallback, SystemEvent } from '../../RemoteCommands/SystemEvent';

type Props = 
    ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps>;

function TariffsContentContainer(props: Props) {
    const {
        currentTariff,
        openAddTariffAction,
        currentTariffAction,
        currentPropertyValueSet,
        loadingAction,
        loading,
        addTariffSuccessAction
    } = props;

    const [tabValue, setTabValue] = useState(0);

    const tabValueChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        SystemEvent.SubscribeEventGetTariff(
            "TariffsContentContainer", 
            (answer) => {
                const tariff = new Tariff(answer.tariff);
                currentTariffAction(tariff);
                setTimeout(() => {
                    loadingAction(false);
                }, 500);
            }, 
            (error) => {
                loadingAction(false);
                errorCallback(error);
            }
        );
    }, []);

    return (
        <TariffsContent   
            tabValue={tabValue} 
            tabValueChange={tabValueChange} 
            currentTariff={currentTariff}
            openAddTariffAction={openAddTariffAction}
            loading={loading} 
            currentPropertyValueSet={currentPropertyValueSet} 
            addTariffSuccessAction={addTariffSuccessAction} />
    );
};

const mapStateToProps = (state: RootState) => ({
    currentTariff: state.tariffs.currentTariff,
    currentPropertyValueSet: state.event.currentPropertyValueSet,
    loading: state.event.loading
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({ 
    openAddTariffAction: (openAddTariff: boolean) => 
        dispatch(actions.tariffs.openAddTariffAction(openAddTariff)),
    currentTariffAction: (currentTariff: Tariff | undefined) => 
        dispatch(actions.tariffs.currentTariffAction(currentTariff)),
    loadingAction: (loading: boolean) => dispatch(actions.event.loadingAction(loading)),
    addTariffSuccessAction: (addTariffSuccess: boolean) => 
        dispatch(actions.tariffs.addTariffSuccessAction(addTariffSuccess))
});

export default connect(mapStateToProps, mapDispatchToProps)(TariffsContentContainer);