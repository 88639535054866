import { UserActions } from "./actions";
import { UserActionTypes, UserState } from './types';
import { Reducer } from "redux";

const defaultState: UserState = {
    identificationInfo: JSON.parse(localStorage.getItem('licenceInfo') || '{}'),
};

export const userReducer: Reducer<UserState, UserActions> = (
    state = defaultState,
    action) => {
    switch (action.type) {
        case UserActionTypes.GET_IDENTIFICATION:
            {
                return {
                    ...state,
                    identificationInfo: action.identificationInfo
                };
            }
        default:
            return state;
    }
};
export default userReducer;