import { useEffect } from 'react';
import { useState } from 'react';
import { errorCallback, SystemEvent } from '../../RemoteCommands/SystemEvent';
import { HistoryEvents, IOrderInfo } from '../../RemoteCommands/type';
import { HistoryOrder } from './HistoryOrder';
import './HistoryOrder.scss';

type Props = {
    orderInfo: IOrderInfo;
    updateHistory: boolean;
};

function HistoryOrderContainer(props: Props) {
    const { 
        orderInfo,
        updateHistory
    } = props;

    const [historyEvents, setHistoryEvents] = useState<HistoryEvents[]>([]);

    useEffect(() => {
        SystemEvent.SubscribeEventGetHistoryEvents(
            "EventGetHistoryEvents", 
            (answer) => {
                setHistoryEvents(answer.historyEvents);
            }, 
            (error) => {
                alert(error.errorMessage);
                errorCallback(error);
            }
        );
        SystemEvent.EventGetHistoryEvents(orderInfo.orderId);
    }, [updateHistory]);

    return (
        <HistoryOrder 
            historyEvents={historyEvents} />
    );
};

export default HistoryOrderContainer