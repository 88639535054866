import { combineReducers } from 'redux';
import { localizeReducer } from "react-localize-redux";

import sip from './sip/reducer';
import translations from './translations/reducer';
import app from './app/reducer';
import event from './event/reducer';
import tariffs from './tariffs/reducer';
import user from './user/reducer';

const rootReducer = combineReducers({
    sip,
    localize: localizeReducer,
    translations,
    user,
    app,
    event,
    tariffs
});


export default rootReducer;