import { useEffect } from 'react';
import { 
    Button, Dialog, DialogActions, DialogContent, DialogTitle 
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Tariff } from '../../RemoteCommands/type';
import { errorCallback, SystemEvent } from '../../RemoteCommands/SystemEvent';
import { removeItemAtIndex } from '../../utils/replaceItem';

type Props = {
    open: boolean;
    handleClose: () => void;
    basicTariffId: number;
    rowId: number;
    array: any[];
    name: string;
    currentTariffAction: (currentTariff: Tariff | undefined) => void;
    currentTariff: Tariff;
};

function PropertyDeleteModal(props: Props) {
    const { t } = useTranslation();
    const { open, handleClose, basicTariffId, rowId, currentTariff, currentTariffAction, array, name } = props;
    const index = array.findIndex((listItem) => listItem.rowId === rowId);
    useEffect(() => {
        SystemEvent.SubscribeEventDeleteTariffParameters(
            "PropertyDeleteModal", 
            (answer) => {
                if(!currentTariff) {
                    return;
                }
                const newList = removeItemAtIndex(array, index);
                currentTariffAction({
                    ...currentTariff,
                    [name]: newList
                })
            }, 
            (error) => {
                errorCallback(error);
            }
        );
    }, []);

    const deleteClick = () => {
        if(rowId > 0) {
            SystemEvent.EventDeleteTariffParameters(basicTariffId, rowId);
        } else {
            const index = array.findIndex((listItem) => listItem.rowId === rowId);
            const newList = removeItemAtIndex(array, index);
            currentTariffAction({
                ...currentTariff,
                [name]: newList
            })
        }

        handleClose();
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>
                {t("delete")}
            </DialogTitle>
            <DialogContent>
                {t("deleteQuestion")}?
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={handleClose}>{t("cancel")}</Button>
                <Button color="primary" onClick={deleteClick} autoFocus>Ok</Button>
            </DialogActions>
        </Dialog>
    );
};

export default PropertyDeleteModal;