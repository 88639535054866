import * as React from 'react';
import { 
    Button, Dialog, DialogActions, DialogContent, DialogTitle 
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Tariff } from '../../RemoteCommands/type';
import { errorCallback, SystemEvent } from '../../RemoteCommands/SystemEvent';
import { removeItemAtIndex } from '../../utils/replaceItem';

type Props = {
    open: boolean;
    handleClose: () => void;
    tariff: Tariff;
    tariffs: Tariff[];
    tariffsAction: (tariffs: Tariff[]) => void;
};

function TariffDeleteModal(props: Props) {
    const { t } = useTranslation();
    const { open, handleClose, tariff, tariffsAction, tariffs } = props;

    React.useEffect(() => {
        SystemEvent.SubscribeEventDeleteTariff(
            "TariffDeleteModal", 
            (answer) => {
                const index = tariffs.findIndex((listItem) => listItem.basicTariffId === tariff.basicTariffId);
                const newList = removeItemAtIndex(tariffs, index);
                tariffsAction(newList);
            }, 
            (error) => {
                errorCallback(error);
            }
        );
    }, []);


    const deleteClick = () => {
        SystemEvent.EventDeleteTariff(tariff.basicTariffId);
        handleClose();
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogTitle>
                {t("delete")} {tariff.description}
            </DialogTitle>
            <DialogContent>
                {t("deleteQuestion")}  <strong>{tariff.description}</strong> ?
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={handleClose}>{t("cancel")}</Button>
                <Button color="primary" onClick={deleteClick} autoFocus>Ok</Button>
            </DialogActions>
        </Dialog>
    );
};

export default TariffDeleteModal;