import { TranslationActions, TranslationActionTypes } from "./actions";
import { TranslationState } from './types';
import { Reducer } from "redux";

const defaultState: TranslationState = {
    translationsLoaded: false,
    translationsLoading: false,
    translations: []
};

export const translationReducer: Reducer<TranslationState, TranslationActions> = (
    state = defaultState,
    action) => {
    switch (action.type) {
        case TranslationActionTypes.FETCH_TRANSLATIONS:
            {
                return {
                    ...state,
                    translationsLoaded: false,
                    translationsLoading: true
                };
            }
        case TranslationActionTypes.FETCH_TRANSLATIONS_ERROR:
            {
                return {
                    ...state,
                    translationsLoaded: false,
                    translationsLoading: false
                };
            }
        case TranslationActionTypes.FETCH_TRANSLATIONS_SUCCESS:
            {
                return {
                    ...state,
                    translationsLoaded: true,
                    translationsLoading: false,
                    translations: action.translations
                };
            }
        default:
            return state;
    }
};
export default translationReducer;