import { Button } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { Sip } from '../../../../../sip/sip';
import { RootState } from '../../../../../store';
import PhoneEnabledIcon from '@material-ui/icons/PhoneEnabled';
import PhoneDisabledIcon from '@material-ui/icons/PhoneDisabled';

type Props = ReturnType<typeof mapStateToProps>;


class CallButtons extends React.Component<Props>{

    acceptCall() {
        try {
            Sip.Answer();
        } catch (e) {
            console.log(e);
        }
    }

    refuseCall() {
        try {
            Sip.HangUp();
        } catch (e) {
            console.log(e);
        }
    }

    render() {
        const { isEstablished } = this.props;

        return (
            <div className="btn-group" role="group" aria-label="Basic example">
                {!isEstablished &&
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<PhoneEnabledIcon />}
                        onClick={this.acceptCall.bind(this)}
                        className="btn-success"
                    />
                }
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<PhoneDisabledIcon />}
                    onClick={this.refuseCall.bind(this)}
                    className="btn-secondary"
                />
            </div>
        );
    }
}


const mapStateToProps = (state: RootState) => ({
    isEstablished: state.sip.isEstablished
});


export default connect(
    mapStateToProps
)(CallButtons);