import Draggable from 'react-draggable';
import { DraggablePosition, ResizeSize } from '../../store/app/types';
import { Card, CardHeader, CardContent, IconButton, Tooltip, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import RemoveIcon from '@material-ui/icons/Remove';
import { ActiveOrdersTableContainer } from '../ActiveOrdersTable';
import { Resizable } from 'react-resizable';
import { Trans, useTranslation } from 'react-i18next';

type Props = {
    draggable: boolean;
    draggablePosition: DraggablePosition | undefined;
    rollUpCardHas: boolean;
    onStop: (event: any, ui: any) => void;
    openActiveOrders: boolean;
    rolleUpClick: () => void;
    openActiveOrdersAction: (openActiveOrders: boolean) => void;
    resize: ResizeSize | undefined;
    onResize: (event: any, ui: any) => void;
};

export function ActiveOrders(props: Props) {
    const { t } = useTranslation();
    const {
        draggable,
        draggablePosition,
        rollUpCardHas,
        onStop,
        openActiveOrders,
        rolleUpClick,
        openActiveOrdersAction,
        resize,
        onResize
    } = props;

    return (
        <Draggable
            handle=".activeOrders"
            defaultPosition={draggablePosition ? draggablePosition.position : undefined}
            disabled={!draggable}
            onStop={onStop}
            cancel=".cancelDraggable">
                <Resizable 
                    width={resize ? resize.size.width : 1000}
                    height={resize ? resize.size.height : 500} 
                    onResize={onResize}
                    minConstraints={[400, 200]} 
                    maxConstraints={[1400, 700]}
                >
                    <Card
                        className={`
                            draggableCard
                            activeOrders 
                            ${openActiveOrders ? 'openSelectedCard' : 'closeSelectedCard'}
                            ${rollUpCardHas ? 'rollUp' : 'rollUpNone'}
                        `}
                        style={{
                            cursor: draggable ? 'move' : 'auto',
                            width: resize ? resize.size.width : 1000 + 'px', 
                            height: resize ? resize.size.height : 500 + 'px'
                        }}>
                        <CardHeader
                            action={
                                <div className="draggableCard__wrap">
                                    <Tooltip
                                        title={<Trans i18nKey="rollUp" />}
                                        placement="top">
                                        <IconButton
                                            onClick={rolleUpClick}>
                                            <RemoveIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <IconButton
                                        onClick={() => openActiveOrdersAction(false)}>
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                            }
                            title={
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item>
                                        <span className="draggableCard__title">{t("activeOrders")}</span>
                                    </Grid>
                                </Grid>
                            }
                        />
                        <CardContent>
                            <ActiveOrdersTableContainer />
                        </CardContent>
                    </Card>
                </Resizable>
        </Draggable>
    );
};