import { Dispatch } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { RootState } from '../../store';
import { connect } from 'react-redux';
import { 
    Box, ButtonGroup,
    InputAdornment, TextField 
} from '@material-ui/core';
import { TooltipCustom } from '../../utils/TooltipCustom';
import { Trans, useTranslation } from 'react-i18next';
import { Waiting } from '../../RemoteCommands/type';

type Props = 
    ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps> & {
    waiting?: Waiting;
    handleWaiting: (waiting: Waiting) => void;
};

function WaitingItem(props: Props) {
    const {t} = useTranslation();
    const {
        waiting,
        handleWaiting
    } = props;

    const waitinItemChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        if(name === "freeMinutes") {
            return handleWaiting({
                ...waiting!,
                [name]: {
                    isEnabled: true,
                    value: Number(value)
                }
            })
        } 
        handleWaiting({
            ...waiting!,
            [name]: Number(value)
        })
    }

    return (
        <Box className="simplified">
            <Box className="simplified-sub-title">{t("WaitingtimefreeXminutes")}&nbsp; / &nbsp;{t("everynextminuteitcostsinrubles.")}</Box>
            <ButtonGroup 
                color="primary"
                size="small"
                className="btn-group"
                fullWidth>
                <TooltipCustom title={<Trans i18nKey="WaitingtimefreeXminutes" />} placement="top">
                    <TextField 
                        placeholder="0"
                        size="small" 
                        type="number" 
                        fullWidth
                        name="freeMinutes"
                        value={waiting && waiting.freeMinutes ? waiting.freeMinutes.value : ""}
                        onChange={waitinItemChange}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{t("mines.")}</InputAdornment>
                        }}
                    />
                </TooltipCustom>
                <TooltipCustom title={<Trans i18nKey="everynextminuteitcostsinrubles." />} placement="top">
                    <TextField 
                        placeholder="0.00"
                        size="small" 
                        type="number" 
                        fullWidth
                        name="cost"
                        value={waiting ? waiting.cost : ""}
                        onChange={waitinItemChange}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{t("currency")}</InputAdornment>
                        }}
                    />
                </TooltipCustom>
            </ButtonGroup>
        </Box>
    );
};

const mapStateToProps = (state: RootState) => ({
    
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({ 
    
});

export default connect(mapStateToProps, mapDispatchToProps)(WaitingItem);