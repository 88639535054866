import { Dispatch } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { RootState, actions } from '../../store';
import { connect } from 'react-redux';
import Menu from './Menu';
import { RollUpCard } from '../../store/app/types';
import { IEventUserIdentification } from '../../RemoteCommands/type';
import './Menu.scss';

type Props = 
    ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps>;

function MenuContainer(props: Props) {
    const {
        rollUpCards,
        toggleMenu,
        rollUpCardsAction,
        showFullMenu,
        openEmployeesCardAction,
        openEmployeesCard,
        openActiveOrders,
        openActiveOrdersAction,
        openOrderRegistration,
        openOrderRegistrationAction,
        openTariffsAction,
        openTariffs,
        identificationInfo,
        identificationInfoAction
    } = props;

    return (
        <Menu 
            toggleMenu={toggleMenu} 
            rollUpCards={rollUpCards}
            rollUpCardsAction={rollUpCardsAction}
            showFullMenu={showFullMenu}
            openEmployeesCardAction={openEmployeesCardAction}
            openEmployeesCard={openEmployeesCard}
            openActiveOrders={openActiveOrders}
            openActiveOrdersAction={openActiveOrdersAction}
            openOrderRegistration={openOrderRegistration}
            openOrderRegistrationAction={openOrderRegistrationAction}
            openTariffsAction={openTariffsAction}
            openTariffs={openTariffs}
            identificationInfo={identificationInfo}
            identificationInfoAction={identificationInfoAction}
        />
    );
}

const mapStateToProps = (state: RootState) => ({
    showFullMenu: state.app.showFullMenu,
    rollUpCards: state.app.rollUpCards,
    openEmployeesCard: state.event.openEmployeesCard,
    openActiveOrders: state.event.openActiveOrders,
    openOrderRegistration: state.event.openOrderRegistration,
    openTariffs: state.tariffs.openTariffs,
    identificationInfo: state.user.identificationInfo
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    toggleMenu: () => dispatch(actions.app.changeMenuStateAction()),
    rollUpCardsAction: (rollUpCards: RollUpCard[]) => 
        dispatch(actions.app.rollUpCardsAction(rollUpCards)),
    openEmployeesCardAction: (openEmployeesCard: boolean) => 
        dispatch(actions.event.openEmployeesCardAction(openEmployeesCard)),
    openActiveOrdersAction: (openActiveOrders: boolean) => 
        dispatch(actions.event.openActiveOrdersAction(openActiveOrders)),
    openOrderRegistrationAction: (openOrderRegistration: boolean) => 
        dispatch(actions.event.openOrderRegistrationAction(openOrderRegistration)),
    openTariffsAction: (openTariffs: boolean) => 
        dispatch(actions.tariffs.openTariffsAction(openTariffs)),
    identificationInfoAction: (identificationInfo: IEventUserIdentification | null) => 
        dispatch(actions.user.identificationInfoAction(identificationInfo))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MenuContainer);