import Draggable from 'react-draggable';
import { DraggablePosition } from '../../store/app/types';
import { Card, CardHeader, CardContent, IconButton, Tooltip, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import RemoveIcon from '@material-ui/icons/Remove';
import { Trans, useTranslation } from 'react-i18next';
import { OrderContentContainer } from '../OrderContent';
import { useEffect, useState } from 'react';
import { useRef } from 'react';
import { Routes } from '../../store/event/types';

type Props = {
    draggable: boolean;
    draggablePosition: DraggablePosition | undefined;
    rollUpCardHas: boolean;
    onStop: (event: any, ui: any) => void;
    openOrderRegistration: boolean;
    rolleUpClick: () => void;
    openOrderRegistrationAction: (openOrderRegistration: boolean) => void;
    routes: Routes[];
    orderRegistrationClose: () => void
};

export function OrderRegistration(props: Props) {
    const { t } = useTranslation();
    const {
        draggable,
        draggablePosition,
        rollUpCardHas,
        onStop,
        openOrderRegistration,
        rolleUpClick,
        routes,
        orderRegistrationClose
    } = props;

    const [height, setHeight] = useState(0)
    const ref = useRef<any>(null);

    useEffect(() => {
        if(ref && ref.current) {
            setHeight(ref.current.clientHeight);
        }
    }, [routes.length]);

    return (
        <Draggable
            handle=".orderRegistration"
            defaultPosition={draggablePosition ? draggablePosition.position : undefined}
            disabled={!draggable}
            onStop={onStop}
            cancel=".cancelDraggable">
            <Card
                className={`
                    draggableCard 
                    orderRegistration
                    ${openOrderRegistration ? 'openSelectedCard' : 'closeSelectedCard'}
                    ${rollUpCardHas ? 'rollUp' : 'rollUpNone'}
                `}
                style={{cursor: draggable ? 'move' : 'auto'}}>
                <CardHeader
                    action={
                        <div className="draggableCard__wrap">
                            <Tooltip
                                title={<Trans i18nKey="rollUp" />}
                                placement="top">
                                <IconButton
                                    onClick={rolleUpClick}>
                                    <RemoveIcon />
                                </IconButton>
                            </Tooltip>
                            <IconButton
                                onClick={orderRegistrationClose}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    }
                    title={
                        <Grid container spacing={2} alignItems="center">
                            <Grid item>
                                <span className="draggableCard__title">{t("makingAnOrder")}</span>
                            </Grid>
                        </Grid>
                    }
                />
                <CardContent
                    style={height > 750 ? {maxHeight: 749, overflowY: "auto"} : {}}
                    ref={ref}>
                    <OrderContentContainer />
                </CardContent>
            </Card>
        </Draggable>
    );
};