import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import BUILD_PARAMS from '../utils/build'

i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init({
    lng: localStorage. getItem("i18nextLng") || BUILD_PARAMS.DEFAULT_LANG,
    fallbackLng: localStorage. getItem("i18nextLng") || BUILD_PARAMS.DEFAULT_LANG,
    debug: true,
    detection: {
        order: ['queryString', 'cookie'],
        cache: ['cookie'],
        store: window.localStorage,
    },
    whitelist: ['en', 'de', 'ru'],
    interpolation: {
        escapeValue: false
    }
})

export default i18n