import React, { useState } from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import { Tooltip } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import PeopleIcon from '@material-ui/icons/People';
import { RollUpCard } from '../../store/app/types';
import { Trans, useTranslation } from 'react-i18next';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import AddIcon from '@material-ui/icons/Add';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import { IEventUserIdentification } from '../../RemoteCommands/type';
import { AccessList } from '../../utils/AccessList';
import { LicenceKey } from '../../utils/licenceKey';
import BUILD_PARAMS from '../../utils/build';

var packageJson = require('../../../package.json');

type Props = {
    toggleMenu: () => void;
    rollUpCards: RollUpCard[];
    rollUpCardsAction: (rollUpCards: RollUpCard[]) => void;
    showFullMenu: boolean;
    openEmployeesCardAction: (openEmployeesCard: boolean) => void;
    openEmployeesCard: boolean;
    openActiveOrders: boolean;
    openActiveOrdersAction: (openActiveOrders: boolean) => void;
    openOrderRegistration: boolean;
    openOrderRegistrationAction: (openOrderRegistration: boolean) => void;
    openTariffsAction: (openTariffs: boolean) => void;
    openTariffs: boolean;
    identificationInfo: IEventUserIdentification | null;
    identificationInfoAction: (identificationInfo: IEventUserIdentification | null) => void
}

const Menu:React.FC<Props> = (props) => {
    const {
        toggleMenu,
        rollUpCards,
        rollUpCardsAction,
        showFullMenu,
        openEmployeesCardAction,
        openEmployeesCard,
        openActiveOrders,
        openActiveOrdersAction,
        openOrderRegistration,
        openOrderRegistrationAction,
        openTariffs,
        openTariffsAction,
        identificationInfo,
        identificationInfoAction
    } = props;

    const { t } = useTranslation();

    const [loginToggle, setLoginToggle] = useState(false);

    const logoutClick = () => {
        identificationInfoAction(null);
        LicenceKey.licenceKey = BUILD_PARAMS.WEBSERVER_APIKEY!
    }

    const loginToggleClick = () => {
        setLoginToggle(!loginToggle);
    };

    const openEmployeesClick = () => {
        openEmployeesCardAction(true);
        const idx = rollUpCards.findIndex((item) => item.key === "Employees");
        rollUpCardsAction([
            ...rollUpCards.slice(0, idx),
            ...rollUpCards.slice(idx + 1)
        ]);
    }

    const openActiveOrdersClick = () => {
        openActiveOrdersAction(true);
        const idx = rollUpCards.findIndex((item) => item.key === "ActiveOrders");
        rollUpCardsAction([
            ...rollUpCards.slice(0, idx),
            ...rollUpCards.slice(idx + 1)
        ]);
    }

    const openOrderRegistrationClick = () => {
        openOrderRegistrationAction(true);
        const idx = rollUpCards.findIndex((item) => item.key === "OrderRegistration");
        rollUpCardsAction([
            ...rollUpCards.slice(0, idx),
            ...rollUpCards.slice(idx + 1)
        ]);
    }

    const openTariffsClick = () => {
        openTariffsAction(true);
        const idx = rollUpCards.findIndex((item) => item.key === "Tariffs");
        rollUpCardsAction([
            ...rollUpCards.slice(0, idx),
            ...rollUpCards.slice(idx + 1)
        ]);
    }

    return (
        <nav id="sidebar" className={`sidebar ${showFullMenu ? "sidebar_large" : "sidebar_small"}`}>
            <div className="sidebar__header" onClick={toggleMenu}>
                <div className="sidebar__logo" id="sidebarCollapse">
                    <span className="sidebar__logo-desctop">Sedi Taxi</span>
                    <span className="sidebar__logo-mobile">ST</span>
                </div>
                <div className="sidebar__burger">
                    <MenuIcon />
                </div>
            </div>
            <div className="sidebar-lang">
                <LanguageSelector />
            </div>
            <ul className="menu-nav">
                {/* <Tooltip  
                    placement="right"
                    title={
                        !showFullMenu ? <Trans i18nKey="billboardsMap" /> : ""
                    }>
                    <li className="menu-nav__item" key='billboardsMap'>
                        <NavLink 
                            activeClassName='is-active' 
                            to="/map"
                        >
                            <MapIcon /><span className="text">{t("billboardsMap")}</span>
                        </NavLink>
                    </li>
                </Tooltip> */}
                <Tooltip  
                    placement="right"
                    title={
                        !showFullMenu ? <Trans i18nKey="activeOrders" /> : ""
                    }>
                    <li className="menu-nav__item" key='activeOrders'>
                        <NavLink 
                            to='/map'
                            className={openActiveOrders ? 'is-active' : ''} 
                            onClick={openActiveOrdersClick}>
                            <NotificationsActiveIcon /><span className="text">{t("activeOrders")}</span>
                        </NavLink>
                    </li>
                </Tooltip>
                {AccessList.RegisterOrder() && 
                    <Tooltip  
                        placement="right"
                        title={
                            !showFullMenu ? <Trans i18nKey="arrange" /> : ""
                        }>
                        <li className="menu-nav__item" key='arrange'>
                            <NavLink 
                                to='/map'
                                className={openOrderRegistration ? 'is-active' : ''} 
                                onClick={openOrderRegistrationClick}>
                                <AddIcon /><span className="text">{t("arrange")}</span>
                            </NavLink>
                        </li>
                    </Tooltip>
                }
                <Tooltip  
                    placement="right"
                    title={
                        !showFullMenu ? <Trans i18nKey="employees" /> : ""
                    }>
                    <li className="menu-nav__item" key='employees'>
                        <NavLink 
                            to='/map'
                            className={openEmployeesCard ? 'is-active' : ''} 
                            onClick={openEmployeesClick}>
                            <PeopleIcon /><span className="text">{t("employees")}</span>
                        </NavLink>
                    </li>
                </Tooltip>
                {AccessList.GetTariffs() && 
                    <Tooltip  
                        placement="right"
                        title={
                            !showFullMenu ? <Trans i18nKey="Tariffs" /> : ""
                        }>
                        <li className="menu-nav__item" key='tariffs'>
                            <NavLink 
                                to='/map'
                                className={openTariffs ? 'is-active' : ''} 
                                onClick={openTariffsClick}>
                                <FormatListBulletedIcon /><span className="text">{t("Tariffs")}</span>
                            </NavLink>
                        </li>
                    </Tooltip>
                }
                {/* <Tooltip  
                    placement="right"
                    title={
                        !showFullMenu ? <Trans i18nKey="settings" /> : ""
                    }
                >
                    <li className="menu-nav__item" key='settings'>
                        <NavLink activeClassName='is-active' to='/settings'>
                            <SettingsIcon /><span className="text">{t("settings")}</span>
                        </NavLink>
                    </li>
                </Tooltip> */}
            </ul>
            <ul className="profile-nav">
                <Tooltip  
                    placement="right-start"
                    title={
                        !showFullMenu ? <Trans i18nKey="profile" /> : ""
                    }>
                    <li className="profile-nav__item" key='profile'>
                        <span className="login-menu" data-toggle="collapse" aria-expanded="false" onClick={loginToggleClick}>
                            <AccountCircleIcon /><span className="text">{identificationInfo ? identificationInfo.userName : t("profile")}</span>
                        </span>
                        <ul className={`profile-nav__drop ${loginToggle && 'active'}`}>
                            <li key='logout'>
                                <div className="logout__item"  style={{ cursor: 'pointer' }} onClick={logoutClick}>
                                    <ExitToAppIcon /> 
                                    <span className="text">{t("logout")}</span>
                                </div>
                            </li>
                        </ul>
                    </li>
                </Tooltip>
            </ul> 
            <div className="sidebar-version">
                v {packageJson.version}
            </div>
        </nav>
    );
}

export default Menu;