export class LicenceKey {
    private static _licenceKey: string | null = null;
 
    public static get licenceKey() {
        return this._licenceKey;
    }

    public static set licenceKey(value: string | null) {
        this._licenceKey = value;
    }
}