import { StateType } from 'typesafe-actions';
import rootReducer from './root-reducer';

import * as sipSelectors from './sip/selectors';
import * as sipActions from './sip/actions';
import * as appActions from './app/actions';
import * as appSelectors from './app/selectors';
import * as eventActions from './event/actions';
import * as tariffsActions from './tariffs/actions';
import * as userActions from './user/actions';

import * as translationActions from './translations/actions';

export { default } from './store';
export { default as rootReducer } from './root-reducer';

export const selectors = {
    sip: sipSelectors,
    app: appSelectors
};

export const actions = {
    sip: sipActions,
    translations: translationActions,
    user: userActions,
    app: appActions,
    event: eventActions,
    tariffs: tariffsActions
}

export type RootState = StateType<typeof rootReducer>;
