import { Dispatch } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { RootState, actions } from '../../store';
import { connect } from 'react-redux';
import { AddTariff } from './AddTariff';
import { DefaultPosition, DraggablePosition, RollUpCard } from '../../store/app/types';
import { useTranslation } from 'react-i18next';
import './AddTariff.scss';

type Props = 
    ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps>;

function AddTariffContainer(props: Props) {
    const { t } = useTranslation();
    const {
        draggable,
        draggablePositions,
        rollUpCards,
        draggablePositionAction,
        rollUpCardAction,
        openAddTariff,
        openAddTariffAction,
        ratios
    } = props;

    const addTariffClose = () => {
        openAddTariffAction(false);
    }
    
    const onStop = (event: any, ui: any) => {
        const { x, y } = ui;
        const position: DefaultPosition = { x, y };
        draggablePositionAction({
            key: 'AddTariff',
            position
        });
    };

    const rolleUpClick = () => {
        rollUpCardAction({
            title: t("AddTariff"),
            key: "AddTariff"
        });
    }
    const draggablePosition = draggablePositions.find(item => item.key === "AddTariff");
    const rollUpCardHas = rollUpCards.some(item => item.key === "AddTariff");

    return (
        <AddTariff 
            draggable={draggable} 
            draggablePosition={draggablePosition} 
            rollUpCardHas={rollUpCardHas}  
            onStop={onStop}  
            rolleUpClick={rolleUpClick} 
            openAddTariff={openAddTariff} 
            ratios={ratios} 
            addTariffClose={addTariffClose} />
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        draggable: state.app.draggable,
        draggablePositions: state.app.draggablePositions,
        rollUpCards: state.event.rollUpCards,
        openAddTariff: state.tariffs.openAddTariff,
        ratios: state.event.ratios
    }
};

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({ 
    draggablePositionAction: (draggablePosition: DraggablePosition) => 
        dispatch(actions.app.draggablePositionAction(draggablePosition)),
    rollUpCardAction: (rollUpCard: RollUpCard) => 
        dispatch(actions.event.rollUpCardAction(rollUpCard)),
    openAddTariffAction: (openAddTariff: boolean) => 
        dispatch(actions.tariffs.openAddTariffAction(openAddTariff))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddTariffContainer);