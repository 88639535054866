import React, { Dispatch, useEffect, useState } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { RootState, actions } from '../../store';
import { connect } from 'react-redux';
import { errorCallback, SystemEvent } from '../../RemoteCommands/SystemEvent';
import { LicenceKey } from '../../utils/licenceKey';
import Authorization from './Authorization';
import { EventError, IEventUserIdentification } from '../../RemoteCommands/type';
import MessageShackbar from '../bricks/MessageShackbar';
import { AccessList } from '../../utils/AccessList';
import './Authorization.scss';

type Props = 
    ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps>

function AuthorizationContainer(props: Props) {
    const { eventError, eventErrorAction, identificationInfoAction } = props;
    const login = useInput('');
    const password = useInput('');
    const phoneNumber = useInput('');
    const PIN = useInput('');
    const [needVerify, setNeedVerify] = useState(false);
    const [disabledSandToKey, setDisabledSandToKey] = useState(false);

    useEffect(() => {
        if(phoneNumber.value.length === 0 || (login.value.length > 0 && password.value.length > 0)) {
            setDisabledSandToKey(true);
        } else {
            setDisabledSandToKey(false);
        }
    }, [phoneNumber.value, login.value, password.value])

    const sendToKey = () => {
        SystemEvent.EventUserIdentification(phoneNumber.value, "sms");
    }

    const userIdentificationPin = () => {
        if(login.value.length === 0 && password.value.length === 0) {
            SystemEvent.EventUserIdentificationPin(PIN.value);
        } else {
            SystemEvent.EventUserIdentification(login.value + " " + password.value, "login");
        }
    }

    useEffect(() => {
        SystemEvent.SubscribeEventUserIdentification(
            "AuthorizationContainer", 
            (answer) => {
                AccessList.initialization(answer.availableApplicationFunctions);
                if(answer.needVerify) {
                    setNeedVerify(true);
                } else {
                    identificationInfoAction(answer);
                    LicenceKey.licenceKey = answer.userKey;
                }
            }, 
            (error) => {
                errorCallback(error);
            }
        );
        SystemEvent.SubscribeEventUserIdentificationPin(
            "AuthorizationContainer", 
            (answer) => {
                identificationInfoAction(answer)
                LicenceKey.licenceKey = answer.userKey;
                AccessList.initialization(answer.availableApplicationFunctions);
            }, 
            (error) => {
                errorCallback(error);
            }
        );
    }, []);

    const handleMessageClose = () => {
        eventErrorAction(null);
    }
    
    return (
        <>
            <Authorization 
                login={login}
                password={password}
                phoneNumber={phoneNumber}
                PIN={PIN}
                sendToKey={sendToKey} 
                userIdentificationPin={userIdentificationPin}
                needVerify={needVerify}
                disabledSandToKey={disabledSandToKey}
            />
            {eventError && 
                <MessageShackbar 
                    message={eventError.errorMessage}
                    variant="error"
                    messageOpen={Boolean(eventError.errorCode)} 
                    vertical="top"
                    horizontal="center" 
                    messageShackbarClose={handleMessageClose} />
            }
        </>
    );
}

const mapStateToProps = (state: RootState) => ({
    eventError: state.event.eventError
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({ 
    eventErrorAction: (eventError: EventError | null) => 
        dispatch(actions.event.eventErrorAction(eventError)),
    identificationInfoAction: (identificationInfo: IEventUserIdentification | null) => 
        dispatch(actions.user.identificationInfoAction(identificationInfo))
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthorizationContainer);

function useInput(initialValue: string) {
    const [value, setValue] = useState(initialValue)
  
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value)
    }
    
    const clear = () => setValue('')
  
    return {
        bind: {value, onChange},
        value,
        clear
    }
}