
import React, { Dispatch, useEffect } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { actions, RootState } from '../../store';
import { connect } from 'react-redux';import { Checkbox, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { EmployeeRole } from '../../store/event/types';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { errorCallback, SystemEvent } from '../../RemoteCommands/SystemEvent';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type Props = 
    ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps> & {
    currentRoles: EmployeeRole[];
    handleRole: (roles: EmployeeRole[]) => void;
    error?: boolean;
    variant?: "standard" | "filled" | "outlined";
    edit?: boolean;
    label?: string;
};

function RoleMultipleAutocomplete(props: Props) {
    const { t } = useTranslation();
    const { handleRole, employeeRoles, variant, currentRoles, edit, label, employeeRolesAction, lang } = props;

    useEffect(() => {
        SystemEvent.SubscribeEventGetEmployeeRolesList(
            "RoleMultipleAutocomplete", 
            (answer) => {
                employeeRolesAction(answer.roles);
            }, 
            (error) => {
                errorCallback(error);
            }
        );
        SystemEvent.EventGetEmployeeRolesList(lang);
    }, []);

    const roleOnChange = (changeValue: EmployeeRole[]) => {
        handleRole(changeValue);
    }
    

    return (
        <Autocomplete
            multiple
            options={employeeRoles}
            disableCloseOnSelect
            className="multiInputAutocomplate cancelDraggable"
            getOptionLabel={(option: EmployeeRole) => option.roleLocalization}
            onChange={(event: React.ChangeEvent<{}>, changeValue: EmployeeRole[]) => roleOnChange(changeValue)}
            defaultValue={currentRoles}
            value={currentRoles}
            disabled={!edit}
            size="small"
            renderOption={(option: EmployeeRole, { selected }) => (
                <React.Fragment>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    {option.roleLocalization}
                </React.Fragment>
            )}
            renderInput={params => (
                <TextField
                    {...params}
                    variant={variant}
                    label={label || t("roles")}
                    fullWidth
                    size="small"
                    className="multiInput"
                />
            )}
        />
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        employeeRoles: state.event.employeeRoles,
        lang: state.app.lang
    }
};

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({ 
    employeeRolesAction: (employeeRoles: EmployeeRole[]) => 
        dispatch(actions.event.employeeRolesAction(employeeRoles))
});

export default connect(mapStateToProps, mapDispatchToProps)(RoleMultipleAutocomplete);