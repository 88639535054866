import React, { Dispatch } from "react";
import { Sip } from '../../sip/sip';
import { AnyAction, Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { getTranslate, Translate} from "react-localize-redux";
import { RootState, selectors, actions } from '../../store';
import CallHistoryList from "../CallHistoryList/CallHistoryList";
import { CallInfo } from "../../store/sip/types";
import { Button, TextField } from "@material-ui/core";
import CallEndIcon from '@material-ui/icons/CallEnd';
import { Trans } from "react-i18next";
import { withTranslation } from 'react-i18next';
import './SipPhone.scss';


type Props = 
    ReturnType<typeof mapStateToProps> 
    & ReturnType<typeof mapDispatchToProps>;


type State = {
    phone:string;
}


class SipPhone extends React.Component<Props, State> {
    state = {
        phone: ''
    }
    nameInput: HTMLInputElement | null | undefined;

    componentDidMount() {
        var self = this;
        Sip.OnSipChanged.subscribe(() => {
            self.forceUpdate();
        });
    }

    componentDidUpdate(prevProps: Props) {
        if (this.nameInput) {
            if (this.state.phone)
                this.nameInput.focus();
            else
                this.nameInput.focus();
        }
    }

    setPhoneControl(call: CallInfo) {
        this.setState({
            phone: call.number
        });
    }
    setPhoneEvent(evt: any) {
        this.setState({
            phone: evt.target.value
        });
    }

    createIncomingTest() {
        this.props.incomingCallAction("+79512345678");
    }

    callBlock() {
        if (Sip.HasActiveCall) {
            return null;
        }

        return (
            <div className="input-group">
                <TextField 
                    type="text"
                    ref={(input: HTMLInputElement | null) => { this.nameInput = input; }}
                    variant="outlined" 
                    size="small"
                    fullWidth
                    autoComplete="off"
                    //@ts-ignore
                    placeholder={this.props.t('phone')} 
                    value={this.state.phone} 
                    onChange={this.setPhoneEvent.bind(this)} 
                />
                <Button 
                    variant="outlined"
                    color="primary" 
                    disableElevation
                    onClick={() => {
                        Sip.Call(this.props.sipNumber!, this.state.phone);
                        this.setState({
                            phone: ''
                        });
                    }}>
                    <Trans i18nKey="call" />
                </Button>
            </div>
        );
    }

    activeCallBlock() {
        if (!Sip.HasActiveCall) {
            return null;
        }
        var answerButton = Sip.IsIncomingCall && !Sip.IsEstablished
            ? 
            (
                <div 
                    className='callEnd callEnd-green' 
                    onClick={() => Sip.Answer()}>
                        <div className="callEnd__icon">
                            <CallEndIcon 
                                titleAccess={this.props.translate('SipAnswer').toString()} 
                            />
                        </div>
                </div>
            )
            : null;

        var text = this.props.translate(Sip.IsIncomingCall ? "SipCallFrom" : "SipCallTo");
        if (Sip.IsEstablished)
            text = this.props.translate("SipInCallWith");

        return (
            <div>
                <div className="input-group">
                    <div className="input-group-prepend">
                        <span className="input-group-text"> {text} </span>
                        <span className="input-group-text">{this.props.phoneNumber}</span>
                    </div>
                    <div className="input-group-append">
                        {answerButton}
                        <div 
                            className='callEnd' 
                             onClick={() => Sip.HangUp()}>
                            <div className="callEnd__icon">
                                <CallEndIcon 
                                    titleAccess={this.props.translate('SipHangUp').toString()} 
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>);
    }


    render() {
        if (!Sip.IsInitialized)
            return false;
        // if (!this.props.showControl && !Sip.HasActiveCall)
        //     return null;
        //<button className='btn btn-outline-info' type="button" onClick={this.createIncomingTest.bind(this)}>Test</button>
        return (
            <>
                <span>
                    <span><Trans i18nKey="yourNumber" /></span> {this.props.sipNumber}
                </span>
                <div>
                    {this.callBlock()}

                    {this.activeCallBlock()}
                </div>
                <CallHistoryList onClick={this.setPhoneControl.bind(this)}/>
            </>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    translate: getTranslate(state.localize),
    sipNumber: selectors.sip.sipNumber(state.sip),
    phoneNumber: selectors.sip.GetCallerPhoneNumber(state.sip),
});
const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    changeSipState: (number: string, hasActiveCall: boolean, isEstablished: boolean, isIncoming: boolean, isMuted: boolean) => dispatch(actions.sip.sipStateChangedAction(number, hasActiveCall, isEstablished, isIncoming, isMuted)),
    incomingCallAction: (number:string) => dispatch(actions.sip.sipIncomingCallAction(number))
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(SipPhone));