import { Dispatch } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { actions, RootState } from '../../store';
import { connect } from 'react-redux';
import { 
    Box, ButtonGroup, IconButton, TextField, Tooltip 
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { TooltipCustom } from '../../utils/TooltipCustom';
import { Trans } from 'react-i18next';
import { RateAllowance, Tariff, TimeRange } from '../../RemoteCommands/type';
import { removeItemAtIndex, replaceItemAtIndex } from '../../utils/replaceItem';
import PeriodDate from './PeriodDate';

type Props = 
    ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps> & {
    rateAllowances: RateAllowance[];
    rateAllowance: RateAllowance;
    rateAllowancesChange: (array: RateAllowance[]) => void;
};

function RateAllowanceItem(props: Props) {
    const {
        rateAllowances,
        rateAllowance,
        rateAllowancesChange,
        currentTariff,
        currentTariffAction
    } = props;

    const index = rateAllowances.findIndex((listItem) => listItem.rowId === rateAllowance.rowId);

    const handleTimeRange = (timeRange: TimeRange) => {
        const newList = replaceItemAtIndex(rateAllowances, index, {
            ...rateAllowance,
            timeRange
        });
        if(currentTariff) {
            currentTariffAction({
                ...currentTariff,
                rateAllowances: newList
            })
        }
    }

    const rateAllowanceChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        const newList = replaceItemAtIndex(rateAllowances, index, {
            ...rateAllowance,
            [name]: Number(value),
        });
        if(currentTariff) {
            currentTariffAction({
                ...currentTariff,
                rateAllowances: newList
            })
        }
    }

    const deleteRatio = (rowId: number | string) => {
        const index = rateAllowances.findIndex((listItem) => listItem.rowId === rowId);
        const newList = removeItemAtIndex(rateAllowances, index);
        rateAllowancesChange(newList);
    };

    return (
        <Box
            className="simplified__item">
            {rateAllowance && 
                <Tooltip title={<Trans i18nKey="delete" />} placement="top">
                    <IconButton 
                        size="small" 
                        className="delete"
                        color="secondary"
                        onClick={() => deleteRatio(rateAllowance.rowId)}>
                        <CloseIcon />
                    </IconButton>
                </Tooltip>
            }
            <ButtonGroup 
                color="primary"
                size="small"
                className="btn-group"
                fullWidth>
                <TooltipCustom title={<Trans i18nKey="Applythecoefficient" />} placement="top">
                    <TextField 
                        placeholder="0"
                        size="small" 
                        type="number"
                        name="value"
                        value={rateAllowance ? rateAllowance.value: ""}
                        onChange={rateAllowanceChange}
                        style={{ minWidth: 70, width: 70 }}
                    />
                </TooltipCustom>
                <PeriodDate 
                    timeRange={rateAllowance.timeRange} 
                    handleTimeRange={handleTimeRange} />
            </ButtonGroup>
        </Box>
    );
};

const mapStateToProps = (state: RootState) => ({
    currentTariff: state.tariffs.currentTariff
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({ 
    currentTariffAction: (currentTariff: Tariff | undefined) => 
        dispatch(actions.tariffs.currentTariffAction(currentTariff)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RateAllowanceItem);