import { Dispatch, useEffect } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { RootState, actions } from '../../store';
import { connect } from 'react-redux';
import { ActiveOrders } from './ActiveOrders';
import { DefaultPosition, DraggablePosition, ResizeSize, RollUpCard } from '../../store/app/types';
import { errorCallback, SystemEvent } from '../../RemoteCommands/SystemEvent';
import { IActiveOrders, Route } from '../../RemoteCommands/type';
import './ActiveOrders.scss';
import { RoutingInfo } from '../../store/event/types';

type Props = 
    ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps>;

function ActiveOrdersContainer(props: Props) {
    const {
        draggable,
        draggablePositions,
        rollUpCards,
        draggablePositionAction,
        rollUpCardAction,
        openActiveOrders,
        openActiveOrdersAction,
        resizeSize,
        resizeSizeAction,
        activeOrdersAction,
        lang,
        routingInfoAction,
        setPointsAction
    } = props;

    const onStop = (event: any, ui: any) => {
        const { x, y } = ui;
        const position: DefaultPosition = { x, y };
        draggablePositionAction({
            key: 'ActiveOrders',
            position
        });
    };

    const rolleUpClick = () => {
        rollUpCardAction({
            title: "Активные заказы",
            key: "ActiveOrders"
        });
    }

    const onResize = (event: any, ui: any) => {
        const { size } = ui;
        resizeSizeAction({
            key: 'ActiveOrders',
            size
        })
    };

    useEffect(() => {
        SystemEvent.SubscribeEventCancelOrder(
            "ActiveOrdersContainer", 
            (answer) => {
                SystemEvent.EventGetActiveOrdersForDispatcher();
                setPointsAction([]);
                routingInfoAction(null);
            }, 
            (error) => {
                errorCallback(error);
            }
        );
    }, []);

    useEffect(() => {
        SystemEvent.SubscribeEventGetActiveOrdersForDispatcher(
            "ActiveOrdersContainer", 
            (answer) => {
                activeOrdersAction(answer.activeOrders);
            }, 
            (error) => {
                errorCallback(error);
            }
        );
        SystemEvent.EventGetActiveOrdersForDispatcher();
    }, [lang]);

    const resize = resizeSize.find(item => item.key === "ActiveOrders");
    const draggablePosition = draggablePositions.find(item => item.key === "ActiveOrders");
    const rollUpCardHas = rollUpCards.some(item => item.key === "ActiveOrders");

    return (
        <ActiveOrders 
            draggable={draggable} 
            draggablePosition={draggablePosition} 
            rollUpCardHas={rollUpCardHas} 
            onStop={onStop} 
            openActiveOrders={openActiveOrders} 
            rolleUpClick={rolleUpClick} 
            openActiveOrdersAction={openActiveOrdersAction} 
            resize={resize}
            onResize={onResize} />
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        draggable: state.app.draggable,
        draggablePositions: state.app.draggablePositions,
        rollUpCards: state.event.rollUpCards,
        openActiveOrders: state.event.openActiveOrders,
        resizeSize: state.app.resizeSize,
        lang: state.app.lang
    }
};

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({ 
    draggablePositionAction: (draggablePosition: DraggablePosition) => 
        dispatch(actions.app.draggablePositionAction(draggablePosition)),
    rollUpCardAction: (rollUpCard: RollUpCard) => 
        dispatch(actions.event.rollUpCardAction(rollUpCard)),
    openActiveOrdersAction: (openActiveOrders: boolean) => 
        dispatch(actions.event.openActiveOrdersAction(openActiveOrders)),
    resizeSizeAction: (resizeSize: ResizeSize) => 
        dispatch(actions.app.resizeSizeAction(resizeSize)),
    activeOrdersAction: (activeOrders: IActiveOrders[]) => 
        dispatch(actions.event.activeOrdersAction(activeOrders)),
    routingInfoAction: (routingInfo: RoutingInfo | null) => 
        dispatch(actions.event.routingInfoAction(routingInfo)),
    setPointsAction: (points: Route[]) => 
        dispatch(actions.event.setPointsAction(points)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActiveOrdersContainer);