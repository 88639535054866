export class AccessList {
    private static availableApplications: AavailableApplication[] = [];

    public static initialization(available: AavailableApplication[]) {
        AccessList.availableApplications = available;
    }

    // Для показа кнопки добавить сотрудника
    public static ChangeEmployeeData = () => AccessList.availableApplications.some(item => item.eventName === "ChangeEmployeeData");

    // Для фильтрации групп для создания сотрудника
    public static GetChangeEmployeeDataGroupIds = () =>  {
        const result = AccessList.availableApplications.find(item => item.eventName === "ChangeEmployeeData");
        return result ? result.groupIds : [];
    };

    // Для показа кнопки оформить заказ
    public static RegisterOrder = () => AccessList.availableApplications.some(item => item.eventName === "RegisterOrder");

    // Для фильтрации групп для оформления заказа
    public static GetRegisterOrderGroupIds = () =>  {
        const result = AccessList.availableApplications.find(item => item.eventName === "RegisterOrder");
        return result ? result.groupIds : [];
    };

    // Для показа кнопки тарифа
    public static GetTariffs = () => AccessList.availableApplications.some(item => item.eventName === "GetTariffs");

    // Для фильтрации групп для тарифов
    public static GetTariffsGroupIds = () =>  {
        const result = AccessList.availableApplications.find(item => item.eventName === "GetTariffs");
        return result ? result.groupIds : [];
    };

    // Если известна группа
    public static EventName = (groupId: number) =>  AccessList.availableApplications.some(item => 
        (item.eventName === "ChangeEmployeeData" && item.groupIds.some(id => id === groupId))
    );
}

export type AavailableApplication = { 
    eventName: string;
    groupIds: number[];
}