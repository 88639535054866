import React, { Dispatch, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { actions, RootState } from '../../store';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { 
    Avatar, Button, CircularProgress, Dialog, DialogActions, 
    DialogContent, DialogTitle, IconButton, MenuItem,
    Menu, 
    Grid,
    TextField,
    InputAdornment,
    Tooltip,
    ButtonGroup,
    Box,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormGroup,
    Checkbox
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import { EmployeeInfoRole, IDetailedEmployeeInfo, Phones, ShortGroups } from '../../RemoteCommands/type';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { errorCallback, SystemEvent } from '../../RemoteCommands/SystemEvent';
import GetAddressAutocomplete from '../bricks/GetAddressAutocomplete';
import GetCityAutocomplete from '../bricks/GetCityAutocomplete';
import { Trans, useTranslation } from 'react-i18next';
import { removeItemAtIndex, replaceItemAtIndex } from '../../utils/replaceItem';
import GroupAutocomplete from '../bricks/GroupAutocomplete';
import { EmployeeRole } from '../../store/event/types';
import RoleAutocomplete from '../bricks/RoleAutocomplete';
import { useFormik } from 'formik';
import * as yup from 'yup';
import PersonIcon from '@material-ui/icons/Person';
import DriverProfile from '../DriverProfile/DriverProfile';
import { AccessList } from '../../utils/AccessList';
import './EmployeeModal.scss';

type Props = ReturnType<typeof mapStateToProps> 
    & ReturnType<typeof mapDispatchToProps> & {
    employeeOpen: boolean;
    employeeClickClose: () => void;
    employeeDetailInfo: IDetailedEmployeeInfo;
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setEmployeeId: (value: React.SetStateAction<number>) => void
}

const EmployeeModal:React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const {
        employeeOpen,
        employeeClickClose,
        employeeDetailInfo,
        loading,
        setLoading,
        selectedAddress,
        setEmployeeId,
        addressValueAction,
        employeeRoles,
        shortGroups
    } = props;

    const [edit, setEdit] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const inputPhoneFocus = useRef<HTMLInputElement>(null);
    const inputCountryFocus = useRef<HTMLInputElement>(null);
    const [enabledTaxiDriver, setEnabledTaxiDriver] = useState(false);
    const [profileOpen, setProfileOpen] = useState(false);

    const validationSchema = yup.object({
        firstName: yup.string().required(t("isRequired")),
        secondName: yup.string().required(t("isRequired")),
        login: yup.string().required(t("isRequired")),
        email: yup.string().email().required(t("isRequired"))
    });

    const formik = useFormik({
        initialValues: employeeDetailInfo,
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            let detailInfo: IDetailedEmployeeInfo = values;
            const newPhones = values.phones.filter(item => item.number.length !== 0);
            const newRoles = values.roles.filter(item => item.groupId !== -1);
            detailInfo.phones = newPhones;
            detailInfo.roles = newRoles;
            setLoading(true);
            SystemEvent.EventChangeEmployeeData(detailInfo);
        }
    });

    useEffect(() => {
        if(employeeDetailInfo.employeeId === -1) {
            setEdit(true);
        }
    }, [employeeDetailInfo]);


    const handleProfileOpen = () => {
        setProfileOpen(true);
    }

    const handleProfileClose = () => {
        setProfileOpen(false);
    }

    const handleAddClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleAddClose = () => {
        setAnchorEl(null);
    };

    const addNewPhoneClick = () => {
        const phone: Phones = {
            number: "",
            phoneType: "MobileWork"
        }
        formik.setValues((values) => ({
            ...values,
            phones: values.phones.concat(phone)
        }))
        handleAddClose();
    }

    const addNewRoleClick = () => {
        const role: EmployeeInfoRole = {
            groupId: -1,
            groupName: "",
            role: "",
            localizedRole: "",
            roleAllowed: false
        }
        formik.setValues((values) => ({
            ...values,
            roles: values.roles.concat(role)
        }))
        handleAddClose();
    }

    const addNewAddressClick = () => {
        if(formik.values.address === null) {
            formik.setValues(prevState => ({
                ...prevState,
                address: {
                    countryName: "",
                    cityName: "",
                    streetName: "",
                    houseNumber: "",
                    apartmentNumber: "",
                    geoPoint: {
                        latitude: 0,
                        longitude: 0
                    }
                }
            }));
        }
        handleAddClose();
    }

    useEffect(() => {
        if(formik.values.address) {
            let addressString = formik.values.address.streetName +' '+ formik.values.address.houseNumber;
            addressValueAction(addressString);
        }
        const enabled = formik.values.roles.some(item => item.role === "TaxiDrivers");
        setEnabledTaxiDriver(enabled);
    }, [formik.values]);

    const modalClose = () => {
        employeeClickClose();
        setEdit(false);
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, i?: number) => {
        const name = event.target.name;
        const value = event.target.value;
        if(name === "cityName" || name === "streetName" || name === "houseNumber" || name === "apartmentNumber") {
            formik.setValues((values) => ({
                ...values, 
                address: {
                    ...values.address,
                    [name]: value
                }
            }));
        } else if(name === "latitude" || name === "longitude") {
            formik.setValues((values) => ({
                ...values, 
                address: {
                    ...values.address!,
                    geoPoint: {
                        ...values.address!.geoPoint!,
                        [name]: value
                    }
                }
            }));
        } else {
            formik.setValues((values) => ({
                ...values, 
                [name]: value
            }));
        }
    }

    const handleGenderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = (event.target as HTMLInputElement).value;
        var isGender = (value === 'true');
        formik.setValues(prevState => ({
            ...prevState,
            gender: isGender
        }));
    };

    const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, i: number, phone: Phones) => {
        const value = event.target.value;
        const { phones } = formik.values;
        const newPhones = replaceItemAtIndex(phones, i, {
            ...phone,
            number: value
        });
        formik.setFieldValue("phones", newPhones);
    }

    const onCurrentRoleChange = (employeeRole: EmployeeRole | null, i: number, role: EmployeeInfoRole) => {
        const { roles } = formik.values;
        const newRoles = replaceItemAtIndex(roles, i, {
            ...role,
            role: employeeRole ? employeeRole.role : "",
            localizedRole: employeeRole ? employeeRole.roleLocalization : ""
        });
        formik.setFieldValue("roles", newRoles);
    }

    const handleGroup = (shortGroup: ShortGroups | null, i: number, role: EmployeeInfoRole) => {
        const { roles } = formik.values;
        const newRoles = replaceItemAtIndex(roles, i, {
            ...role,
            groupId: shortGroup ? shortGroup.id : -1,
            groupName: shortGroup ? shortGroup.name : ""
        });
        formik.setFieldValue("roles", newRoles);
    }

    const roleAllowedChange = (event: React.ChangeEvent<HTMLInputElement>, i: number, role: EmployeeInfoRole) => {
        const { roles } = formik.values;
        const newRoles = replaceItemAtIndex(roles, i, {
            ...role,
            roleAllowed: event.target.checked
        });
        formik.setFieldValue("roles", newRoles);
    };

    const handleCityChange = (newValue: string) => {
        formik.setValues(prevState => ({
            ...prevState,
            address: {
                ...prevState.address!,
                cityName: newValue
            }
        }));
    }

    useEffect(() => {
        if(selectedAddress) {
            formik.setValues((values) => ({
                ...values, 
                address: {
                    ...values.address!,
                    countryName: selectedAddress.co,
                    cityName: selectedAddress.t === "c" ? selectedAddress.v.replace(/ *\([^)]*\) */g, "") : selectedAddress.c.replace(/ *\([^)]*\) */g, ""),
                    streetName: selectedAddress.s,
                    houseNumber: selectedAddress.t === "h" ? selectedAddress.v : undefined,
                    apartmentNumber: "",
                    geoPoint: {
                        latitude: selectedAddress.g.lat,
                        longitude: selectedAddress.g.lon
                    }
                }
            }));
        }
    }, [selectedAddress]);

    useEffect(() => {
        SystemEvent.SubscribeEventChangeEmployeeData(
            "EmployeeModal", 
            (answer) => {
                setLoading(false);
                setEdit(false);
                setEmployeeId(answer.employeeId);
            }, 
            (error) => {
                setLoading(false);
                errorCallback(error);
            }
        );
    }, []);

    const deletePhone = (phone: Phones) => {
        const { phones } = formik.values;
        const index = phones.findIndex(item => item === phone);
        const newPhones = removeItemAtIndex(phones, index);
        formik.setValues(prevState => ({
            ...prevState,
            phones: newPhones
        }));
    }

    const deleteRole = (role: EmployeeInfoRole) => {
        const { roles } = formik.values;
        const index = roles.findIndex(item => item === role);
        const newRoles = removeItemAtIndex(roles, index);
        formik.setValues(prevState => ({
            ...prevState,
            roles: newRoles
        }));
    };

    const employeeInfoView = () => {
        if(employeeDetailInfo) {
            return (
                <DialogContent>
                    <div className="employee-modal__head">
                        <div className="employee-modal__head-left">
                            <Avatar className="employee-modal__avatar">
                                <PersonOutlineOutlinedIcon />
                            </Avatar>
                            <div className="employee-modal__photo-icon">
                                <PhotoCameraOutlinedIcon />
                            </div>
                        </div>
                        <div className="employee-modal__head-right">
                            <TextField
                                className="cancelDraggable employee-modal__text-field"
                                type="text"
                                size="small"
                                fullWidth
                                disabled={!edit}
                                label={t("name") + "*"}
                                name="firstName" 
                                value={formik.values.firstName}
                                onChange={formik.handleChange}
                                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                helperText={formik.touched.firstName && formik.errors.firstName}
                            />
                            <TextField
                                className="cancelDraggable employee-modal__text-field"
                                type="text"
                                size="small"
                                fullWidth
                                disabled={!edit}
                                label={t("secondName") + "*"} 
                                name="secondName" 
                                value={formik.values.secondName}
                                onChange={formik.handleChange}
                                error={formik.touched.secondName && Boolean(formik.errors.secondName)}
                                helperText={formik.touched.secondName && formik.errors.secondName}
                            />
                            <TextField
                                className="cancelDraggable employee-modal__text-field"
                                type="text"
                                size="small"
                                fullWidth
                                disabled={!edit}
                                label={t("login") + "*"} 
                                name="login" 
                                value={formik.values.login}
                                onChange={formik.handleChange}
                                error={formik.touched.login && Boolean(formik.errors.login)}
                                helperText={formik.touched.login && formik.errors.login}
                            />
                        </div>
                    </div>
                    <div className="employee-modal__content">
                        <div className="employee-modal__title">{t("floor")}*</div>
                        <FormGroup row>
                            <RadioGroup 
                                aria-label="gender" 
                                name="gender" 
                                value={formik.values.gender} 
                                onChange={handleGenderChange}
                                className="flex-direction-row"
                                >
                                <FormControlLabel value={true} control={<Radio color="primary" />} label={t("male")}  disabled={!edit} />
                                <FormControlLabel value={false} control={<Radio color="primary" />} label={t("female")} disabled={!edit} />
                            </RadioGroup>
                        </FormGroup>
                    </div>
                    <div className="employee-modal__content">
                        <div className="employee-modal__title">{t("contactDetails")}</div>
                        <TextField
                            className="cancelDraggable employee-modal__text-field"
                            type="email"
                            size="small"
                            fullWidth
                            disabled={!edit}
                            label={t("emailAddress") + "*"}
                            name="email" 
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                        />
                        {formik.values.phones.map((phone, i) => (
                            <TextField
                                key={`phone-${i}`}
                                className="cancelDraggable employee-modal__text-field"
                                type="text"
                                size="small"
                                fullWidth
                                disabled={!edit}
                                inputRef={inputPhoneFocus}
                                label={t("mobilePhone")} 
                                name="phones" 
                                value={phone.number}
                                onChange={(event) => handlePhoneChange(event, i, phone)}
                                InputProps={formik.values.phones.length > 1 ?
                                    {
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip 
                                                    placement="top" 
                                                    title={<Trans i18nKey="delete" />}>
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => deletePhone(phone)}>
                                                        <CloseIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </InputAdornment>
                                        )
                                    } : {}
                                }
                            />
                        ))}
                    </div>
                    {addressView(formik.values)}
                    <div className="employee-modal__content">
                        <div className="employee-modal__title">{t("notes")}</div>
                        <TextField
                            className="cancelDraggable employee-modal__text-field"
                            type="text"
                            size="small"
                            fullWidth
                            disabled={!edit}
                            multiline
                            rows={4}
                            variant="outlined"
                            inputRef={inputPhoneFocus}
                            label={t("addNotes")} 
                            name="notes" 
                            value={formik.values.notes}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box className={`simplified ${formik.values.roles.length === 1 && "employee-simplified"}`}>
                                {formik.values.roles.map((item, i) => {
                                    const currentRole = employeeRoles.find(role => role.role === item.role) || null;
                                    const currentGroup = shortGroups.find(group => group.id === item.groupId) || null;
                                    return (
                                        <Box
                                            key={`role-${item.role}`}
                                            className="simplified__item employee__item">
                                            {formik.values.roles.length > 1 &&
                                                <Tooltip title={<Trans i18nKey="delete" />} placement="top">
                                                    <IconButton 
                                                        size="small" 
                                                        className="delete"
                                                        color="secondary"
                                                        onClick={() => deleteRole(item)}>
                                                        <CloseIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                            <ButtonGroup 
                                                color="primary"
                                                size="small"
                                                className="btn-group"
                                                fullWidth>
                                                <RoleAutocomplete 
                                                    key={item.role}
                                                    currentRoles={currentRole} 
                                                    handleRole={(role: EmployeeRole | null) => onCurrentRoleChange(role, i, item)} 
                                                    edit={!edit} 
                                                    label={t("role")} />
                                                <GroupAutocomplete 
                                                    key={item.groupId}
                                                    currentGroup={currentGroup}
                                                    availableGroups={AccessList.GetChangeEmployeeDataGroupIds()}
                                                    handleGroup={(currentGroup: ShortGroups | null) => handleGroup(currentGroup, i, item)} 
                                                    edit={!edit} />
                                                <Checkbox
                                                    checked={item.roleAllowed}
                                                    color="primary"
                                                    disabled={!edit}
                                                    onChange={(event) => roleAllowedChange(event, i, item)}
                                                />
                                            </ButtonGroup>
                                        </Box>
                                    )
                                })}
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
            )
        }
    }

    const addressView = (employeeDetailInfo: IDetailedEmployeeInfo) => {
        const { address } = employeeDetailInfo;
        if(address) {
            let addressString = address.streetName +' '+ address.houseNumber;
            return (
                <div className="employee-modal__content">
                    <div className="employee-modal__title">{t("address")}</div>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <GetCityAutocomplete 
                                addressInfo={null} 
                                placeholder={t("city")+" *"}
                                value={address.cityName}
                                cityName={address.cityName}
                                disabled={!edit} 
                                inputClassName="employee-modal__address" 
                                onChange={(value: string) => handleCityChange(value)} />
                        </Grid>
                        <Grid item xs={9}>
                            <GetAddressAutocomplete 
                                addressInfo={null} 
                                placeholder={t("streetHouseNumber")} 
                                disabled={!edit} 
                                inputClassName="employee-modal__address" 
                                cityName={address.cityName} 
                                initialValue={addressString} />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                className="cancelDraggable employee-modal__text-field"
                                type="text"
                                variant="outlined"
                                size="small"
                                fullWidth
                                disabled={!edit}
                                inputRef={inputPhoneFocus}
                                label={t("flat")}
                                name="apartmentNumber" 
                                value={address.apartmentNumber}
                                onChange={(event) => handleChange(event)}
                                ref={inputCountryFocus}
                            />
                        </Grid>
                    </Grid>
                </div>
            )
        }
    }

    const employeeTitle = () => {
        if(employeeDetailInfo && employeeDetailInfo.employeeId > 0) {
            if(edit) {
                return t("editEmployee");
            } else {
                return t("employee");
            }
        } else {
            return t("addEmployee");
        }
    }

    const employeeInfoBtnGroup = () => {
        if(edit) {
            return (
                <DialogActions>
                    {enabledTaxiDriver && formik.values.employeeId > 0 &&
                        <Button 
                            variant="outlined" 
                            color="primary"
                            disableElevation
                            startIcon={<PersonIcon />}
                            size="small"
                            onClick={handleProfileOpen}>
                            {t("driverProfile")}
                        </Button>
                    }
                    <Button
                        color="primary"
                        startIcon={<AddIcon />}
                        endIcon={<ExpandMoreIcon />}
                        onClick={handleAddClick}
                    >
                        {t("add")}
                    </Button>
                    <Menu
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleAddClose}>
                        <MenuItem onClick={addNewPhoneClick}>{t("phone")}</MenuItem>
                        {(employeeDetailInfo && employeeDetailInfo.address === null || employeeDetailInfo === null) &&
                            <MenuItem onClick={addNewAddressClick}>{t("address")}</MenuItem>
                        }
                        <MenuItem onClick={addNewRoleClick}>{"Роль"}</MenuItem>
                    </Menu>
                    <Button 
                        className="mr-2" 
                        variant="contained" 
                        color="secondary"
                        disableElevation
                        disabled={loading}
                        onClick={() => setEdit(false)}>
                        {t("cancel")}
                    </Button>
                    <Button 
                        type="submit"
                        variant="contained" 
                        color="primary"
                        disableElevation
                        disabled={loading}
                        startIcon={loading && <CircularProgress size={16} color="secondary" />}>
                        {employeeDetailInfo ? t("save") : t("create")}
                    </Button>
                </DialogActions>
            )
        }
    }

    return (
        <>
            <Dialog
                open={employeeOpen}
                onClose={modalClose}
                fullWidth
                maxWidth="sm"
                className="employee-modal">
                <DialogTitle>
                    <IconButton 
                        style={{ position: 'absolute', top: '5px', right: '5px' }}
                        onClick={modalClose}>
                        <CloseIcon />
                    </IconButton>
                    {employeeDetailInfo && !edit &&
                        <IconButton 
                            style={{ position: 'absolute', top: '5px', right: '45px' }}
                            onClick={() => setEdit(true)}>
                            <EditIcon />
                        </IconButton>
                    }
                    {employeeTitle()}
                </DialogTitle>
                <form 
                    onSubmit={formik.handleSubmit}>
                    {employeeInfoView()}
                    {employeeInfoBtnGroup()}
                </form>
            </Dialog>
            {profileOpen &&
                <DriverProfile 
                    open={profileOpen} 
                    handleClose={handleProfileClose} 
                    carIds={formik.values.driverInfo?.carIds}
                    employeeId={formik.values.employeeId} />
            }
        </>
    );
}

const mapStateToProps = (state: RootState) => {
    return {
        selectedAddress: state.event.selectedAddress,
        employeeRoles: state.event.employeeRoles,
        shortGroups: state.event.shortGroups,
    }
};

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    addressValueAction: (addressValue: string) => 
        dispatch(actions.event.addressValueAction(addressValue))
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeModal);