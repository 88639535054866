import { Dispatch, useState } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { actions, RootState } from '../../store';
import { connect } from 'react-redux';
import AdditionalServicesModal from './AdditionalServicesModal';
import { OrderContent } from './OrderContent';
import { RequirementsCarsModal } from './RequirementsCarsModal';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useEffect } from 'react';
import { errorCallback, SystemEvent } from '../../RemoteCommands/SystemEvent';
import { CalculateCost, CalculatedCosts, EventCalculateCost, RegisterOrder, ShortGroups } from '../../RemoteCommands/type';
import moment from 'moment';
import { EventSuccess, Routes } from '../../store/event/types';
import { IGetAddressItem } from 'sedi-webserverproxy';
import { useTranslation } from 'react-i18next';
import './OrderContent.scss';

type Props = 
    ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps>;
function OrderContentContainer(props: Props) {
    const { t } = useTranslation();
    const {
        routes,
        lang,
        additionalServices,
        setRoutesAction,
        selectedAddressAction,
        addressValueAction,
        additionalServicesAction,
        openOrderRegistrationAction,
        calculateCost,
        calculateCostAction,
        currentCalcCost,
        currentCalcCostAction,
        isRegistrationAction,
        isRegistration,
        eventSuccessAction,
        openActiveOrdersAction
    } = props;

    const [openServices, setOpenServices] = useState(false);
    const [openRequirementsCar, setOpenRequirementsCar] = useState(false);
    const [shortGroups, setShortGroups] = useState<ShortGroups[]>([]);
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [orderTime, setOrderTime] = useState('urgently');
    const [loading, setLoading] = useState(false);

    const validationSchema = yup.object({
        customerPhone: yup.string().required(t("isRequired")),
        // groupId: yup.string().required(t("isRequired"))
    });

    const formik = useFormik({
        initialValues: {
            costCalculationId: undefined,
            customerPhone: "",
            customerName: "",
            customerId: undefined,
            groupId: undefined,
            companyId: undefined,
            comment: "",
            auctionNominalPercent: "",
            ownerChargePart: "",
            ownerChargeSum: ""
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            const registerOrder: RegisterOrder = {
                costCalculationId: currentCalcCost ? currentCalcCost.costCalculationId : undefined,
                customerPhone: values.customerPhone,
                groupId: values.groupId,
                customerId: values.customerId,
                customerName: values.customerName,
                comment: values.comment,
                auctionNominalPercent: values.auctionNominalPercent, 
                ownerChargePart: values.ownerChargePart,
                ownerChargeSum: values.ownerChargeSum
            }
            setLoading(true);
            SystemEvent.EventRegisterOrder(registerOrder);
        }
    });

    const orderTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = (event.target as HTMLInputElement).value;
        if(value === "urgently") {
            setSelectedDate(null);
        } else {
            setSelectedDate(new Date());
        }
        setOrderTime(value);
    };

    const handleDateChange = (date: Date | null) => {
        setSelectedDate(date);
    }

    useEffect(() => {
        setLoading(true);
        SystemEvent.SubscribeEventCalculateCost(
            "OrderContentContainer", 
            (answer) => {
                setLoading(false);
                isRegistrationAction(true);
                calculateCostAction(answer);
                currentCalcCostAction(answer.calculatedCosts[0]);
            }, 
            (error) => {
                setLoading(false);
                errorCallback(error);
            }
        );
        SystemEvent.SubscribeEventRegisterOrder(
            "OrderContentContainer", 
            (answer) => {
                setLoading(false);
                openOrderRegistrationAction(false);
                eventSuccessAction({
                    message: t("orderSuccessfullyRegistered"),
                    success: true
                });
                formik.resetForm();
                selectedAddressAction(null);
                addressValueAction("");
                additionalServicesAction([]);
                calculateCostAction(null);
                setRoutesAction([{id: "1"},{id: "2"}]);
                isRegistrationAction(true);
                openActiveOrdersAction(true);
            }, 
            (error) => {
                setLoading(false);
                errorCallback(error);
            }
        );
        SystemEvent.SubscribeEventGetGroups(
            "OrderContentContainer", 
            (answer) => {
                setLoading(false);
                setShortGroups(answer.shortGroups);
            }, 
            (error) => {
                setLoading(false);
                errorCallback(error);
            }
        );
    }, []);

    useEffect(() => {
        SystemEvent.EventGetGroups("");
    }, []);

    const calculateClick = () => {
        setLoading(true);
        let route: Routes[] = [];
        for(let point of routes) {
            if(point.geoPoint) {
                route.push(point);
            }
        }
        const calculateCost: CalculateCost = {
            orderTime: selectedDate ? moment(selectedDate).format("YYYY-MM-DDTHH:mm:ss") : undefined,
            isCashless: true,
            specialServices: additionalServices.join(';'),
            route: route,
            groupId: formik.values.groupId
        }
        SystemEvent.EventCalculateCost(calculateCost)
    }

    const formikHandleChange = (name: string, value: string | number | undefined) => {
        formik.setFieldValue(name, value);
    }

    const groupHandleValue = (value: string) => {
        const groupsEmpty = shortGroups.some((group) => {
            return group.name.toLowerCase().search(value.toLowerCase()) != -1;
        });
        if(shortGroups.length >= 200 || !groupsEmpty || value.length === 0) {
            SystemEvent.EventGetGroups(value)
        }
    }

    const handleServicesClick = () => {
        setOpenServices(true);
    };

    const handleServicesClose = () => {
        setOpenServices(false);
    };

    const handleCarClick = () => {
        setOpenRequirementsCar(true);
    };

    const handleCarClose = () => {
        setOpenRequirementsCar(false);
    };

    return (
        <>
            <OrderContent 
                handleServicesClick={handleServicesClick} 
                handleCarClick={handleCarClick} 
                formik={formik} 
                calculateClick={calculateClick} 
                routes={routes} 
                lang={lang} 
                formikHandleChange={formikHandleChange} 
                selectedDate={selectedDate} 
                handleDateChange={handleDateChange} 
                orderTime={orderTime} 
                orderTimeChange={orderTimeChange} 
                calculateCost={calculateCost}
                additionalServices={additionalServices} 
                loading={loading} 
                isRegistration={isRegistration} 
                shortGroups={shortGroups} 
                groupHandleValue={groupHandleValue} />
            {openServices && 
                <AdditionalServicesModal 
                    open={openServices}
                    handleClose={handleServicesClose} />
            }
            <RequirementsCarsModal 
               open={openRequirementsCar}
               handleClose={handleCarClose} />
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    routes: state.event.routes,
    lang: state.app.lang,
    additionalServices: state.event.additionalServices,
    calculateCost: state.event.calculateCost,
    currentCalcCost: state.event.currentCalcCost,
    isRegistration: state.event.isRegistration
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({ 
    setRoutesAction: (routes: Routes[]) => dispatch(actions.event.setRoutesAction(routes)),
    selectedAddressAction: (selectedAddress: IGetAddressItem | null) => 
        dispatch(actions.event.selectedAddressAction(selectedAddress)),
    addressValueAction: (addressValue: string) => 
        dispatch(actions.event.addressValueAction(addressValue)), 
    additionalServicesAction: (additionalServices: string[]) => 
        dispatch(actions.event.additionalServicesAction(additionalServices)),
    openOrderRegistrationAction: (openOrderRegistration: boolean) => 
        dispatch(actions.event.openOrderRegistrationAction(openOrderRegistration)),
    calculateCostAction: (calculateCost: EventCalculateCost | null) => 
        dispatch(actions.event.calculateCostAction(calculateCost)),
    currentCalcCostAction: (currentCalcCost: CalculatedCosts | undefined) => 
        dispatch(actions.event.currentCalcCostAction(currentCalcCost)),
    isRegistrationAction: (isRegistration: boolean) => 
        dispatch(actions.event.isRegistrationAction(isRegistration)),
    eventSuccessAction: (eventSuccess: EventSuccess | null) => 
        dispatch(actions.event.eventSuccessAction(eventSuccess)),
    openActiveOrdersAction: (openActiveOrders: boolean) => 
        dispatch(actions.event.openActiveOrdersAction(openActiveOrders)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderContentContainer);