import React from 'react';
import { Settings } from './Settings';
type Props = {
  
};

export function SettingsContainer(props: Props) {
    return (
        <Settings />
    );
};