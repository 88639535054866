import { Dispatch, useState } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { actions, RootState } from '../../store';
import { connect } from 'react-redux';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { useEffect } from 'react';
import { errorCallback, SystemEvent } from '../../RemoteCommands/SystemEvent';
import { SpecialServices } from '../../RemoteCommands/type';
import { Box, CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

type Props = 
    ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps> & {
    open: boolean;
    handleClose: () => void;
};

function AdditionalServicesModal(props: Props) {
    const { t } = useTranslation();
    const {
        open,
        handleClose,
        additionalServices,
        additionalServicesAction
    } = props;

    const [specialServices, setSpecialServices] = useState<SpecialServices[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        SystemEvent.SubscribeEventGetSpecialServices(
            "AdditionalServicesModal", 
            (answer) => {
                setLoading(false)
                setSpecialServices(answer.specialServices);
            }, 
            (error) => {
                setLoading(false);
                errorCallback(error);
            }
        );
        SystemEvent.EventGetSpecialServices();
    }, []);

    const handleToggle = (value: string) => () => {
        const currentIndex = additionalServices.indexOf(value);
        const newChecked = [...additionalServices];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        additionalServicesAction(newChecked);
    };

    const specialServicesView = () => {
        if(loading) {
            return (
                <Box className="loading loading_vertical-center" minHeight={300}>
                    <CircularProgress />
                </Box>
            )
        }
        return (
            <List>
                {specialServices.map((service) => {
                    const labelId = `checkbox-list-label-${service.serviceName}`;

                    return (
                        <ListItem 
                            key={service.serviceName} 
                            role={undefined} 
                            dense 
                            button 
                            onClick={handleToggle(service.serviceName)}>
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={additionalServices.indexOf(service.serviceName) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={service.displayName} />
                            <ListItemSecondaryAction>
                                +{service.cost}₱
                            </ListItemSecondaryAction>
                        </ListItem>
                    );
                })}
            </List>
        )
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth="xs"
            className="additional-services-modal"
        >
            <DialogTitle onClose={handleClose}>
                {t("additionalServices")}
            </DialogTitle>
            <DialogContent dividers>
                {specialServicesView()}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {t("cancel")}
                </Button>
                <Button onClick={handleClose} color="primary" autoFocus>
                    Ок
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = (state: RootState) => ({
    additionalServices: state.event.additionalServices
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({ 
    additionalServicesAction: (additionalServices: string[]) => 
        dispatch(actions.event.additionalServicesAction(additionalServices))
});

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalServicesModal)

const styles = (theme: Theme) =>
    createStyles({
        root: {
        margin: 0,
        padding: theme.spacing(2),
        },
        closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
        },
});

export interface DialogTitleProps extends WithStyles<typeof styles> {
  children: React.ReactNode;
  onClose: () => void;
}


const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <CloseIcon />
            </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
  });
  