import { Dispatch } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { RootState } from '../../store';
import { connect } from 'react-redux';
import { 
    Box, ButtonGroup,
    InputAdornment, TextField 
} from '@material-ui/core';
import { TooltipCustom } from '../../utils/TooltipCustom';
import { Trans, useTranslation } from 'react-i18next';
import { IMinimalCost } from '../../RemoteCommands/type';

type Props = 
    ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps> & {
    minimalCost: IMinimalCost;
    handleMinCost: (minimalCost: IMinimalCost) => void;
};

function MinCostItem(props: Props) {
    const {t} = useTranslation();
    const {
        minimalCost,
        handleMinCost
    } = props;

    const minCostChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        if(name === "includedMinutes") {
            handleMinCost({
                ...minimalCost,
                includedMinutes: {
                    isEnabled: true,
                    value: Number(value)
                }
            })
        } else if(name === "includedKilometers") {
            handleMinCost({
                ...minimalCost,
                includedKilometers: {
                    isEnabled: true,
                    value: Number(value)
                }
            })
        } else {
            handleMinCost({
                ...minimalCost,
                [name]: Number(value)
            })
        }
    }

    return (
        <Box className="simplified">
            <Box className="simplified-sub-title">{t("MinimumCost")}&nbsp; / &nbsp;{t("itIncludesXkm")}&nbsp; / &nbsp;{t("itIncludesMe")}</Box>
            <ButtonGroup 
                color="primary"
                size="small"
                className="btn-group"
                fullWidth>
                <TooltipCustom title={<Trans i18nKey="Supply/Minimalamount" />} placement="top">
                    <TextField 
                        placeholder="0.00"
                        size="small" 
                        type="number" 
                        fullWidth
                        value={minimalCost ? minimalCost.cost : ""}
                        name="cost"
                        onChange={minCostChange}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{t("currency")}</InputAdornment>
                        }}
                    />
                </TooltipCustom>
                <TooltipCustom title={<Trans i18nKey="Kilometer" />} placement="top">
                    <TextField 
                        placeholder="0"
                        size="small" 
                        type="number" 
                        fullWidth
                        value={minimalCost && minimalCost.includedKilometers ? minimalCost.includedKilometers.value : ""}
                        name="includedKilometers"
                        onChange={minCostChange}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">км</InputAdornment>
                        }}
                    />
                </TooltipCustom>
                <TooltipCustom title={<Trans i18nKey="Minute" />} placement="top">
                    <TextField 
                        placeholder="0"
                        size="small" 
                        type="number" 
                        fullWidth
                        value={minimalCost && minimalCost.includedMinutes ? minimalCost.includedMinutes.value : ""}
                        name="includedMinutes"
                        onChange={minCostChange}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{t("mines.")}</InputAdornment>
                        }}
                    />
                </TooltipCustom>
            </ButtonGroup>
        </Box>
    );
};

const mapStateToProps = (state: RootState) => ({
    
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({ 
    
});

export default connect(mapStateToProps, mapDispatchToProps)(MinCostItem);