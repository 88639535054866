import React, { Dispatch, useCallback, useEffect, useMemo, useRef } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { RootState, actions } from '../../store';
import { connect } from 'react-redux';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { Center, MapStyle } from './type';
import { CircularProgress } from '@material-ui/core';
import { EventError, GeoPoint, IActiveEmployees, Route } from '../../RemoteCommands/type';
import Clusterer from '../Clusterer/Clusterer';
import Employees from '../Employees/Employees';
import RollUpBtn from '../bricks/RollUpBtn';
import { errorCallback, SystemEvent } from '../../RemoteCommands/SystemEvent';
import { ActiveOrdersContainer } from '../ActiveOrders';
import { OrderRegistrationContainer } from '../OrderRegistration';
import MessageShackbar from '../bricks/MessageShackbar';
import { useTranslation } from 'react-i18next';
import ResetButton from '../bricks/ResetButton';
import { AddTariffContainer } from '../AddTariff';
import { TariffsContainer } from '../Tariffs';
import { EventSuccess, RoutingInfo } from '../../store/event/types';
import BUILD_PARAMS from '../../utils/build';

type Props = 
    ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps> & {
    mapStyle: MapStyle;
    center: Center;
}

let flightPath: google.maps.Polyline;
let markers: google.maps.Marker[] = [];

const Map:React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const {
        mapStyle,
        center,
        openEmployeesCard,
        rollUpCards,
        openActiveOrders,
        points,
        openOrderRegistration,
        eventError,
        eventErrorAction,
        activeEmployeesAction,
        activeEmployees,
        updateGpsData,
        openTariffs,
        openAddTariff,
        eventSuccessAction,
        eventSuccess,
        routingInfoAction,
        routingInfo
    } = props;

    (window as any).deleteMarkers = deleteMarkers;

    const map = useRef<any>(null);

    const taxiDrivers = activeEmployees.filter(item => item.role === "TaxiDrivers");

    const onLoad = React.useCallback(function callback(_map: google.maps.Map<Element>) {
        map.current = _map;
    }, []);

    // const onLoad = React.useCallback(function callback(_map) {
    //     const bounds = new window.google.maps.LatLngBounds();
    //     _map.fitBounds(bounds);
    //     map.current = _map;
    // }, []);

    function fitBoundsToVisibleMarkers() {
        const bounds = new google.maps.LatLngBounds();

        for (let i = 0; i < markers.length; i++) {
            const pos = markers[i].getPosition();
            if(markers[i].getVisible() && pos) {
                bounds.extend(pos);
            }
        }
    
        map.current.fitBounds(bounds);
    }

    const getPolyline = useCallback((map: google.maps.Map | null) => {
        if(!routingInfo) {
            if(flightPath) {
                flightPath.setMap(null);
            }
            return;
        }
        let geoPoints: Center[] = [];
        if(flightPath) {
            flightPath.setMap(null);
        }
        if(routingInfo) {
            for(let point of routingInfo.routePoints) {
                const geoPoint: Center = {
                    lat: point.latitude,
                    lng: point.longitude
                }
                geoPoints.push(geoPoint);
            }
        }
        flightPath = new google.maps.Polyline({
            path: geoPoints,
            geodesic: true,
            strokeColor: "#FF0000",
            strokeOpacity: 1.0,
            strokeWeight: 2,
        });
        
        flightPath.setMap(map);
    }, [routingInfo]);

    // Adds a marker to the map and push to the array.
    const addMarker = (center: Center, point: Route) => {
        const { lat, lng } = center;
        const mapLatLng = new google.maps.LatLng(lat, lng);
        if(map.current) {
            const marker = new google.maps.Marker({
                position: mapLatLng,
                map: map.current,
            });
            showInfoWindow(marker, point);
            markers.push(marker);
        }
        getPolyline(map.current);
        fitBoundsToVisibleMarkers();
    }

    // Sets the map on all markers in the array.
    function setMapOnAll(map: google.maps.Map | null) {
        for (let i = 0; i < markers.length; i++) {
            markers[i].setMap(map);
        }
    }
    
    // Removes the markers from the map, but keeps them in the array.
    function hideMarkers(): void {
        setMapOnAll(null);
    }
    
    // Deletes all markers in the array by removing references to them.
    function deleteMarkers(): void {
        hideMarkers();
        markers = [];
        getPolyline(null);
    }

    const showInfoWindow = (marker: google.maps.Marker, point: Route) => {
        const contentString =
        `
            <div class="infoWindow">
                <div class="infoWindow__content">
                    ${point.street} ${point.houseNumber}, ${point.city}
                </div>
                <div 
                    class="infoWindow__link"
                    onclick="window.deleteMarkers()">
                    ${t("deleteRoute")}
                </div>
            </div>
        `;

        const infowindow = new google.maps.InfoWindow({
            content: contentString,
        });

        marker.addListener("click", () => {
            infowindow.open(map.current, marker);
        });
    }

    const employeeInfoWindow = (marker: google.maps.Marker, taxiDriver: IActiveEmployees) => {
        const contentString =
        `
            <div class="infoWindow">
                <div class="infoWindow__content">
                    <p>${t("name")}: ${taxiDriver.name}</p>
                    <p>${t("roles")}: ${taxiDriver.role}</p>
                </div>
            </div>
        `;

        const infowindow = new google.maps.InfoWindow({
            content: contentString,
        });

        marker.addListener("click", () => {
            infowindow.open(map.current, marker);
        });
    }

    const getMapBounds = (array: IActiveEmployees[]) => {
        let bounds = new google.maps.LatLngBounds();

        array.forEach((item) => {
            bounds.extend(new google.maps.LatLng(
                item.geoPoint.latitude,
                item.geoPoint.longitude,
            ));
        });
        return bounds;
    };

    const resetBounds = () => {
        if(isValidTaxiDrivers(taxiDrivers).length === 0) return;
        const bounds = getMapBounds(isValidTaxiDrivers(taxiDrivers));
        map.current.fitBounds(bounds);
    }

    useEffect(() => {
        if(map.current) {
            resetBounds();
        }
    }, [map.current]);

    useEffect(() => {
        if(updateGpsData) {
            SystemEvent.EventGetActiveEmployees();
            const index = taxiDrivers.findIndex((item) => item.id === Number(updateGpsData.employeeId));
            const activeEmployee = taxiDrivers.find(item => item.id === Number(updateGpsData.employeeId));
            const geoPoint: GeoPoint = {
                latitude: Number(updateGpsData.latitude),
                longitude: Number(updateGpsData.longitude)
            }
            if(activeEmployee) {
                const newList = replaceItemAtIndex(taxiDrivers, index, {
                    ...activeEmployee,
                    geoPoint
                });
                activeEmployeesAction(newList);
            }
        }
    }, [updateGpsData]);

    useMemo(() => {
        for(let point of points) {
            if(point.geoPoint) {
                const center: Center = {
                    lat: point.geoPoint.latitude,
                    lng: point.geoPoint.longitude
                }
                addMarker(center, point);
            }
        }
        if(!routingInfo) {
            deleteMarkers();
        }
    }, [routingInfo]);

    useEffect(() => {
        SystemEvent.SubscribeEventGetActiveEmployees(
            "Map", 
            (answer) => {
                activeEmployeesAction(answer.activeEmployees);
            }, 
            (error) => {
                errorCallback(error);
            }
        );
        SystemEvent.SubscribeEventGetRoutingInfo(
            "Map", 
            (answer) => {
                deleteMarkers();
                routingInfoAction(answer.route);
            }, 
            (error) => {
                errorCallback(error);
            }
        );
        SystemEvent.EventGetActiveEmployees();
    }, []);

    const handleMessageClose = () => {
        eventErrorAction(null);
    }

    const handleSuccessClose = () => {
        eventSuccessAction(null);
    }

    return (
        <>
            <div className="map-container">
                <ResetButton 
                    resetBounds={resetBounds} />
                <LoadScript
                    googleMapsApiKey={BUILD_PARAMS.GOOGLE_MAPS_API_KEY}
                    loadingElement={
                        <div className="loading loading_fixed">
                            <CircularProgress />
                        </div>
                    }
                >
                    <GoogleMap
                        mapContainerStyle={mapStyle}
                        center={center}
                        zoom={10}
                        onLoad={onLoad}
                        options={{
                            fullscreenControl: false
                        }}>
                        <Clusterer 
                            taxiDrivers={taxiDrivers} 
                            employeeInfoWindow={(marker: google.maps.Marker, taxiDriver: IActiveEmployees) => 
                                employeeInfoWindow(marker, taxiDriver)} 
                        />
                    </GoogleMap>
                </LoadScript>
                <div className="rollUpBtnRow">
                    {rollUpCards.map(item => (
                        <span key={item.key}>
                            <RollUpBtn rollUpCard={item} />
                        </span>
                    ))}
                </div>
                {openEmployeesCard &&
                    <Employees 
                        openEmployeesCard={openEmployeesCard} />
                }
                {openActiveOrders && 
                    <ActiveOrdersContainer />
                }
                {openOrderRegistration && 
                    <OrderRegistrationContainer />
                }
                {openTariffs && 
                    <TariffsContainer />
                }
                {openAddTariff && 
                    <AddTariffContainer />
                }
                {eventError && 
                    <MessageShackbar 
                        message={eventError.errorMessage}
                        variant="error"
                        messageOpen={Boolean(eventError.errorCode)} 
                        vertical="top"
                        horizontal="center" 
                        messageShackbarClose={handleMessageClose} />
                }
                {eventSuccess && 
                    <MessageShackbar 
                        message={eventSuccess.message}
                        variant="success"
                        messageOpen={eventSuccess.success} 
                        vertical="top"
                        horizontal="center" 
                        messageShackbarClose={handleSuccessClose} />
                }
            </div>
        </>
    );
}

const mapStateToProps = (state: RootState) => ({
    openEmployeesCard: state.event.openEmployeesCard,
    rollUpCards: state.event.rollUpCards,
    openActiveOrders: state.event.openActiveOrders,
    points: state.event.points,
    openOrderRegistration: state.event.openOrderRegistration,
    eventError: state.event.eventError,
    activeEmployees: state.event.activeEmployees,
    updateGpsData: state.event.updateGpsData,
    openTariffs: state.tariffs.openTariffs,
    openAddTariff: state.tariffs.openAddTariff,
    eventSuccess: state.event.eventSuccess,
    routingInfo: state.event.routingInfo
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({ 
    eventErrorAction: (eventError: EventError | null) => 
        dispatch(actions.event.eventErrorAction(eventError)),
    activeEmployeesAction: (activeEmployees: IActiveEmployees[]) => 
        dispatch(actions.event.activeEmployeesAction(activeEmployees)),
    eventSuccessAction: (eventSuccess: EventSuccess | null) => 
        dispatch(actions.event.eventSuccessAction(eventSuccess)),
    routingInfoAction: (routingInfo: RoutingInfo | null) => 
        dispatch(actions.event.routingInfoAction(routingInfo))
});

export default connect(mapStateToProps, mapDispatchToProps)(Map);

function replaceItemAtIndex(arr: IActiveEmployees[], index: number, newItem: IActiveEmployees) {
    return [...arr.slice(0, index), newItem, ...arr.slice(index + 1)];
}

function isValidTaxiDrivers(array: IActiveEmployees[]) {
    let result: IActiveEmployees[] = [];

    for(let item of array) {
       const valid = Math.abs(item.geoPoint.latitude) < 90 && Math.abs(item.geoPoint.longitude) < 180;
       if(valid) {
           result.push(item)
       }
    }
    return result;
}