import { Dispatch } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { RootState, actions } from '../../store';
import { connect } from 'react-redux';
import { OrderRegistration } from './OrderRegistration';
import { DefaultPosition, DraggablePosition, RollUpCard } from '../../store/app/types';
import { useTranslation } from 'react-i18next';
import './OrderRegistration.scss';
import { CalculatedCosts, EventCalculateCost } from '../../RemoteCommands/type';
import { IGetAddressItem } from 'sedi-webserverproxy';
import { Routes } from '../../store/event/types';

type Props = 
    ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps>;

function OrderRegistrationContainer(props: Props) {
    const { t } = useTranslation();
    const {
        draggable,
        draggablePositions,
        rollUpCards,
        draggablePositionAction,
        rollUpCardAction,
        openOrderRegistration,
        openOrderRegistrationAction,
        routes,
        calculateCostAction,
        currentCalcCostAction,
        selectedAddressAction,
        isRegistrationAction,
        setRoutesAction,
        addressValueAction
    } = props;

    const onStop = (event: any, ui: any) => {
        const { x, y } = ui;
        const position: DefaultPosition = { x, y };
        draggablePositionAction({
            key: 'OrderRegistration',
            position
        });
    };

    const rolleUpClick = () => {
        rollUpCardAction({
            title: t("makingAnOrder"),
            key: "OrderRegistration"
        });
    }

    const orderRegistrationClose = () => {
        openOrderRegistrationAction(false);
        calculateCostAction(null);
        currentCalcCostAction(undefined);
        selectedAddressAction(null);
        isRegistrationAction(true);
        setRoutesAction([{id: "1"},{id: "2"}]);
        addressValueAction("");
    }

    const draggablePosition = draggablePositions.find(item => item.key === "OrderRegistration");
    const rollUpCardHas = rollUpCards.some(item => item.key === "OrderRegistration");

    return (
        <OrderRegistration 
            draggable={draggable} 
            draggablePosition={draggablePosition} 
            rollUpCardHas={rollUpCardHas}  
            onStop={onStop}  
            rolleUpClick={rolleUpClick} 
            openOrderRegistrationAction={openOrderRegistrationAction} 
            openOrderRegistration={openOrderRegistration} 
            routes={routes} 
            orderRegistrationClose={orderRegistrationClose} />
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        draggable: state.app.draggable,
        draggablePositions: state.app.draggablePositions,
        rollUpCards: state.event.rollUpCards,
        openOrderRegistration: state.event.openOrderRegistration,
        routes: state.event.routes
    }
};

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({ 
    draggablePositionAction: (draggablePosition: DraggablePosition) => 
        dispatch(actions.app.draggablePositionAction(draggablePosition)),
    rollUpCardAction: (rollUpCard: RollUpCard) => 
        dispatch(actions.event.rollUpCardAction(rollUpCard)),
    openOrderRegistrationAction: (openOrderRegistration: boolean) => 
        dispatch(actions.event.openOrderRegistrationAction(openOrderRegistration)),
    calculateCostAction: (calculateCost: EventCalculateCost | null) => 
        dispatch(actions.event.calculateCostAction(calculateCost)),
    currentCalcCostAction: (currentCalcCost: CalculatedCosts | undefined) => 
        dispatch(actions.event.currentCalcCostAction(currentCalcCost)),
    selectedAddressAction: (selectedAddress: IGetAddressItem | null) => 
        dispatch(actions.event.selectedAddressAction(selectedAddress)),
    isRegistrationAction: (isRegistration: boolean) => 
        dispatch(actions.event.isRegistrationAction(isRegistration)),
    setRoutesAction: (routes: Routes[]) => dispatch(actions.event.setRoutesAction(routes)),
    addressValueAction: (addressValue: string) => 
        dispatch(actions.event.addressValueAction(addressValue)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderRegistrationContainer);