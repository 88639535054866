import { Dispatch, useEffect, useState } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { actions, RootState } from '../../store';
import { connect } from 'react-redux';
import { 
    Box, Button, Grid
} from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';
import MinimalCostRow from './MinimalCostRow';
import { IMinimalCost, initialMainCost, initialRateAllowance, initialRounding, initialWaiting, MainCost, MinimalCost, RateAllowance, Rounding, Tariff, Waiting } from '../../RemoteCommands/type';
import { errorCallback, SystemEvent } from '../../RemoteCommands/SystemEvent';
import MessageShackbar from '../bricks/MessageShackbar';
import MainCostsRow from './MainCostsRow';
import WaitingRow from './WaitingRow';
import RoundingRow from './RoundingRow';
import RateAllowanceRow from './RateAllowanceRow';

type Props = 
    ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps>;

const AdvancedTariff = (props: Props) => {
    const { t } = useTranslation();
    const {
        currentTariff,
        currentTariffAction
    } = props;
    const [tabValue, setTabValue] = useState(0);

    const [success, setSuccess] = useState(false);

    useEffect(() => {
        SystemEvent.SubscribeEventUpdateTariffParameters(
            "AdvancedTariff", 
            (answer) => {
                setSuccess(true);
            }, 
            (error) => {
                errorCallback(error);
            }
        );
    }, []);

    const handleMessageClose = () => {
        setSuccess(false);
    }

    const tabValueChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabValue(newValue);
    };

    function addNewCost<Type>(name: string, array: Type[]) {
        if(!currentTariff) {
            return null;
        }
        const rowId = -(array.length + 1);
        let item = null;
        switch (name) {
            case 'minimalCosts':
                item = new MinimalCost({...new MinimalCost(), rowId}) as IMinimalCost;
                break;
            case 'mainCosts':
                item = {...initialMainCost, rowId} as MainCost;
                break;
            case 'waitings':
                item = {...initialWaiting, rowId} as Waiting;
                break;
            case 'roundings':
                item = {...initialRounding, rowId} as Rounding;
                break;
            case 'rateAllowances':
                item = {...initialRateAllowance, rowId} as RateAllowance;
                break;
            default:
                item = null;
        }
        if(item) {
            currentTariffAction({
                ...currentTariff,
                [name]: [
                    ...array,
                    item
                ]
            });
        }
    }

    if(!currentTariff) {
        return null;
    }

    return (
        <>
            <AntTabs value={tabValue} onChange={tabValueChange}>
                <AntTab label={t("Minimalamount")} />
                <AntTab label={t("Basiccost")} />
                <AntTab label={t("Expectation")} />
                <AntTab label={t("Roundingto")} />
                <AntTab label={t("Ratio")} />
            </AntTabs>
            <TabPanel value={tabValue} index={0}>
                {currentTariff.minimalCosts.map(item => (
                    <MinimalCostRow
                        key={"minimalCost-" + item.rowId}
                        item={item} 
                        minimalCosts={currentTariff.minimalCosts} />
                ))}
                <Grid 
                    container 
                    spacing={1}
                    justify="flex-end">
                    <Grid item>
                        <Button 
                            variant="outlined" 
                            color="primary"
                            startIcon={<AddIcon />}
                            disableElevation
                            onClick={() => addNewCost("minimalCosts", currentTariff.minimalCosts)}
                            size="small">
                            {t("Addline")}
                        </Button>
                    </Grid>
                </Grid>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                {currentTariff.mainCosts.map(item => (
                    <MainCostsRow 
                        key={"mainCost-" + item.rowId} 
                        item={item}
                        mainCosts={currentTariff.mainCosts} /> 
                ))}
                <Grid 
                    container 
                    spacing={1}
                    justify="flex-end">
                    <Grid item>
                        <Button 
                            variant="outlined" 
                            color="primary"
                            startIcon={<AddIcon />}
                            disableElevation
                            onClick={() => addNewCost("mainCosts", currentTariff.mainCosts)}
                            size="small">
                            {t("Addline")}
                        </Button>
                    </Grid>
                </Grid>
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
                {currentTariff.waitings.map(item => (
                    <WaitingRow 
                        key={"waiting-" + item.rowId} 
                        item={item}
                        waitings={currentTariff.waitings} /> 
                ))}
                <Grid 
                    container 
                    spacing={1}
                    justify="flex-end">
                    <Grid item>
                        <Button 
                            variant="outlined" 
                            color="primary"
                            startIcon={<AddIcon />}
                            disableElevation
                            onClick={() => addNewCost("waitings", currentTariff.waitings)}
                            size="small">
                            {t("Addline")}
                        </Button>
                    </Grid>
                </Grid>
            </TabPanel>
            <TabPanel value={tabValue} index={3}>
                {currentTariff.roundings.map(item => (
                    <RoundingRow 
                        key={"rounding-" + item.rowId} 
                        item={item}
                        roundings={currentTariff.roundings} /> 
                ))}
                <Grid 
                    container 
                    spacing={1}
                    justify="flex-end">
                    <Grid item>
                        <Button 
                            variant="outlined" 
                            color="primary"
                            startIcon={<AddIcon />}
                            disableElevation
                            onClick={() => addNewCost("roundings", currentTariff.roundings)}
                            size="small">
                            {t("Addline")}
                        </Button>
                    </Grid>
                </Grid>
            </TabPanel>
            <TabPanel value={tabValue} index={4}>
                {currentTariff.rateAllowances.map(item => (
                    <RateAllowanceRow 
                        key={"rateAllowance-" + item.rowId} 
                        item={item}
                        rateAllowances={currentTariff.rateAllowances} /> 
                ))}
                <Grid 
                    container 
                    spacing={1}
                    justify="flex-end">
                    <Grid item>
                        <Button 
                            variant="outlined" 
                            color="primary"
                            startIcon={<AddIcon />}
                            disableElevation
                            onClick={() => addNewCost("rateAllowances", currentTariff.rateAllowances)}
                            size="small">
                            {t("Addline")}
                        </Button>
                    </Grid>
                </Grid>
            </TabPanel>
            {success && 
                <MessageShackbar 
                    message={t("successfullyEdited")}
                    variant="success"
                    messageOpen={success} 
                    vertical="top"
                    horizontal="center" 
                    messageShackbarClose={handleMessageClose} />
            }
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    currentTariff: state.tariffs.currentTariff
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({ 
    currentTariffAction: (currentTariff: Tariff | undefined) => 
        dispatch(actions.tariffs.currentTariffAction(currentTariff)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedTariff);

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (
                <Box className="tabpanel-advanced">
                    {children}
                </Box>
            )}
        </div>
    );
}


interface StyledTabProps {
    label: string;
}

const AntTabs = withStyles({
    root: {
      borderBottom: "none",
      minHeight: 30,
      marginBottom: -1
    },
    indicator: {
      backgroundColor: "#fff",
      height: 0,
    },
  })(Tabs);
  
const AntTab = withStyles((theme: Theme) =>
    createStyles({
        root: {
            textTransform: 'none',
            minWidth: 72,
            fontWeight: theme.typography.fontWeightRegular,
            minHeight: 30,
            padding: "5px 10px",
            border: "1px solid transparent",
            '&:hover': {
                color: '#40a9ff',
                opacity: 1,
            },
            '&$selected': {
                color: '#1890ff',
                fontWeight: theme.typography.fontWeightMedium,
                backgroundColor: "#fff",
            },
            '&:focus': {
                color: '#40a9ff',
            }
        },
        selected: {
            borderColor: "rgba(0, 0, 0, 0.23)",
            borderBottom: "none",
            marginBottom: -3,
            marginRight: -1,
            borderRadius: 4
        },
    }),
)((props: StyledTabProps) => <Tab disableRipple {...props} />);