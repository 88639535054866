import React, { Component, Dispatch } from 'react';
import { connect } from 'react-redux';
import { actions, RootState } from '../../store';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import Autosuggest, {
    ChangeEvent, 
    SuggestionSelectedEventData,
    RenderSuggestionParams, 
    SuggestionsFetchRequestedParams, 
    Theme,
    BlurEvent
} from 'react-autosuggest';
import {  
    AutocompleteHelperOptions,
    AddressAutocomplete,
    AddressAPI,
    IGetAddressItem,
    SearchType
} from 'sedi-webserverproxy';
import BUILD_PARAMS from '../../utils/build';
import { Routes } from '../../store/event/types';


type Config = {
  lat?: number,
  lon?: number,
  radius?: number,
  regionPlusIds?: number[],
  searchTypeCity?: SearchType,
  cityName?: string;
}


type Props = AutocompleteHelperOptions & Config 
    & ReturnType<typeof mapStateToProps> 
    & ReturnType<typeof mapDispatchToProps> & {
    onSelect?: (address: IGetAddressItem) => void,
    onChangeInput?: (newValue: string) => void,
    placeholder?: string
    inputClassName?: string,
    inputClassNameLoading?: string,
    errorClassName?: string,
    theme?: Theme,
    maxDisplayCount?: number,
    requestDelay?: number,
    debug?: boolean,
    getConfig?: () => Config,
    externalAddress?: IGetAddressItem,
    cityClassName?: string,
    value?: string,
    selectedAddressAction: (selectedAddress: IGetAddressItem) => void;
    addressItems?: IGetAddressItem[];
    disabled?: boolean;
    autoFocusProps?: boolean;
    onlyCity?: boolean;
    route?: Routes;
    handleOnBlur?: (event: React.FocusEvent<any>) => void;
};

type DefaultProps = {
    requestDelay: number,
    minStringLengthForSearch: number
}


type State = {
  isLoading: boolean,
  value: string,
  errorText: string,
  suggestions: IGetAddressItem[],
  selectedSuggestion?: IGetAddressItem,
  autoFocus: boolean
}


class WidgetAutocomplete extends Component<Props, State> {

  static defaultProps: DefaultProps = {
    requestDelay: 700,
    minStringLengthForSearch: 3
  }

  private timeoutID: number = -1;
  private helperNew: AddressAutocomplete;


  constructor(props: Props) {
    super(props);
    
    // Для инициализации url
    AddressAPI.initialization(`${BUILD_PARAMS.SERVER_API_URL}:12013`);
    this.helperNew = new AddressAutocomplete(new AddressAPI(), props);

    this.state = {
      isLoading: false,
      value: this.props.value || "",
      errorText: '',
      suggestions: this.props.addresses,
      selectedSuggestion: undefined,
      autoFocus: false
    };

    this.handleClear = this.handleClear.bind(this);
    this.handleChangeValue = this.handleChangeValue.bind(this);
    this.handleSuggestionSelected = this.handleSuggestionSelected.bind(this);

    this._setConfigToHelper(props);
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const { 
        externalAddress, 
        addressesAction, 
        value, 
        selectedAddressAction, 
        lat, 
        lon,
        addressItems,
        disabled,
        cityName, 
        onlyCity
    } = this.props;
    const { suggestions } = this.state;

    if(lat !== prevProps.lat && lon !== prevProps.lon && lat && lon) {
        this.helperNew.SetCenter(lat, lon);
    }

    if(value !== prevProps.value) {
        this.setState({ value: value ? value : "" });
    }

    if(cityName !== prevProps.cityName && onlyCity && onlyCity === true) {
        this.setState({value: cityName ? cityName : ""});
    }

    if(cityName !== prevProps.cityName) {
        this.helperNew.CityName(cityName);
    }

    if (externalAddress !== prevProps.externalAddress) {
      if (externalAddress !== undefined) {
        this.helperNew.SetSelectedAddress(externalAddress);
        const addressString = this.helperNew.GetCurrentAddressString();

        this.setState({
          value: addressString,
          suggestions: [externalAddress],
          selectedSuggestion: externalAddress
        });
      }
      else {
        this.setState({ value: '' });
        // сбрасываем без задержки
        this.setValueAndWaitSuggestions('');
      }
    }

    if(suggestions !== prevState.suggestions && suggestions.length > 0) {
        addressesAction(suggestions);
        selectedAddressAction(null);
    }

    if(addressItems !== prevProps.addressItems && (addressItems && addressItems.length > 0)) {
        this.setState({
            suggestions: addressItems,
            autoFocus: true,
        });
    }

  }


  handleChangeValue(request: SuggestionsFetchRequestedParams) {
    // запрос с задержкой
    this.setValueAndWaitSuggestionsWithDelay(request.value);
  }


  handleClear() {
    this.setState({ suggestions: [] });
  }


  setValueAndWaitSuggestionsWithDelay(value: string) {
    const { requestDelay } = this.props;

    clearTimeout(this.timeoutID);

    this.timeoutID = window.setTimeout(() => {
      this.setValueAndWaitSuggestions(value);
    }, requestDelay);
  }


  private _setConfigToHelper(config?: Config) {
    if(config && (config.lat && config.lon)) {
        this.helperNew.SetCenter(config.lat, config.lon);
    }
    if(config && config.regionPlusIds ) {
        this.helperNew.RegionPlusIds(config.regionPlusIds);
    }
    if(config && config.searchTypeCity ) {
        this.helperNew.City(config.searchTypeCity);
    }
    if(config && config.cityName) {
        this.helperNew.CityName(config.cityName);
    }
  }


  async setValueAndWaitSuggestions(value: string) {
    const { getConfig, maxDisplayCount } = this.props;

    this.setState({ isLoading: true, errorText: '' });

    try {
      if (getConfig) {
        const config = getConfig();
        this._setConfigToHelper(config);
      }

      const response = await this.helperNew.ChangeInputValue(value);
      if (response.Success === false) {
        this.setState({
          errorText: response.Message || 'autocomplete undefined error',
          suggestions: []
        });
        return;
      }

      let suggestionsForDisplay = response && response.Result ? response.Result.items : [];

      if (maxDisplayCount !== undefined && maxDisplayCount > 0) {
        suggestionsForDisplay = suggestionsForDisplay.filter((s, index) => index < maxDisplayCount);
      }

      this.setState({ suggestions: suggestionsForDisplay });
    }
    catch (ex: any) {
      this.setState({ errorText: ex.message });
    }
    finally {
      this.setState({ isLoading: false });
    }
  }

  randomInteger(min: number, max: number) {
    // получить случайное число от (min-0.5) до (max+0.5)
    let rand = min - 0.5 + Math.random() * (max - min + 1);
    return Math.round(rand);
  }


  handleSuggestionSelected(event: React.FormEvent<any>, data: SuggestionSelectedEventData<IGetAddressItem>) {
    const { onSelect, selectedAddressAction, addressesAction, onChangeInput } = this.props;
    const selectedSuggestion = data.suggestion;

    this.helperNew.SetSelectedAddress(selectedSuggestion);
    const addressString = this.helperNew.GetCurrentAddressString();
    onChangeInput && onChangeInput(addressString);

    this.setState({ value: addressString, selectedSuggestion });

    // запрос домов после выбора улицы
    if (selectedSuggestion.t === SearchType.street) {
      // запрос немедленно
      this.setValueAndWaitSuggestions(addressString);
    }

    if(selectedSuggestion.t === SearchType.house || SearchType.object) {
        selectedAddressAction(selectedSuggestion);
        addressesAction([]);
    }

    this.setState({
        autoFocus: false
    });
    onSelect && onSelect(selectedSuggestion);
  }


  render() {
    const { isLoading, value, errorText, suggestions } = this.state;
    const {
      placeholder,
      inputClassName = '',
      inputClassNameLoading = '',
      theme,
      errorClassName,
      debug,
      cityClassName,
      disabled,
      autoFocusProps,
      onChangeInput,
      selectedPointAction,
      route
    } = this.props;

    const inputProps = {
        className: isLoading ? inputClassNameLoading : inputClassName,
        placeholder,
        value,
        onChange: (event: React.FormEvent<any>, params: ChangeEvent) => {
            const newValue = params.newValue;
            this.setState({ value: newValue });
            onChangeInput && onChangeInput(newValue);
        },
        autoFocus: autoFocusProps,
        disabled: disabled,
        onFocus: () => {
            selectedPointAction(route ? route.id : "");
        },
        onBlur: this.props.handleOnBlur
    };

    const suggestTheme = { ...getTheme(theme) };

    return (
        <>
            <Autosuggest 
                theme={suggestTheme}
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.handleChangeValue}
                onSuggestionsClearRequested={this.handleClear}
                onSuggestionSelected={this.handleSuggestionSelected}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={(suggestion: IGetAddressItem, params: RenderSuggestionParams) => 
                    renderSuggestion(suggestion, params, cityClassName, debug)}
                inputProps={inputProps}
                alwaysRenderSuggestions={this.state.autoFocus}
            />

            {errorText &&
                <div className={errorClassName}>
                    {errorText}
                </div>
            }
        </>
    );
  }
}


const getTheme = (userTheme?: Theme) => {
    const defaultTheme = {
        suggestionsContainer: 'advancedOption',
        suggestionsList: 'advancedOption__list',
        suggestion: 'advancedOption__item',
    }
    const mergedTheme = Object.assign({}, defaultTheme, userTheme);
    return mergedTheme;
};


const renderSuggestion = (suggestion: IGetAddressItem, params: RenderSuggestionParams, cityClassName?: string, debug?: boolean) => {
    if(suggestion.t === "o") {
        return (
            <div>
                {debug &&
                    <span className="small font-weight-bold mr-1">{suggestion.t}</span>
                }
                <span>{suggestion.v} {suggestion.s && ', ' + suggestion.s} {suggestion.h !== null || suggestion.h !== "" && ', ' + suggestion.h}</span>
                <div className={cityClassName}>
                    {suggestion.c}
                </div>
            </div>
        )
    } else {
        return (
            <div>
                {debug &&
                    <span className="small font-weight-bold mr-1">{suggestion.t}</span>
                }
                <span>{suggestion.s && suggestion.s + ', '}{suggestion.v}</span>
                <div className={cityClassName}>
                    {suggestion.c}
                </div>
            </div>
        )
    }
};


const getSuggestionValue = (suggestion: IGetAddressItem) => {
    if (suggestion.c !== undefined) {
        return suggestion.c + ', ' + suggestion.v;
    }

    return suggestion.v;
}


const mapStateToProps = (state: RootState) => ({
    addresses: state.event.addresses
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    selectedAddressAction: (selectedAddress: IGetAddressItem | null) => 
        dispatch(actions.event.selectedAddressAction(selectedAddress)),
    addressesAction: (addresses: IGetAddressItem[]) => 
        dispatch(actions.event.addressesAction(addresses)),
    selectedPointAction: (selectedPoint: string) => 
        dispatch(actions.event.selectedPointAction(selectedPoint))
});

export default connect(mapStateToProps, mapDispatchToProps)(WidgetAutocomplete);