import {  Tariff } from "../../RemoteCommands/type";

export interface TariffsState {
    openTariffs: boolean,
    openAddTariff: boolean,
    tariffs: Tariff[],
    currentTariff: Tariff | undefined,
    addTariffSuccess: boolean
}

export enum TariffsActionsTypes {
    OPEN_TARIFFS = 'OPEN_TARIFFS',
    OPEN_ADD_TARIFF = 'OPEN_ADD_TARIFF',
    TARIFFS = 'TARIFFS',
    CURRENT_TARIFF = 'CURRENT_TARIFF',
    ADD_TARIFF_SUCCESS = 'ADD_TARIFF_SUCCESS'
}