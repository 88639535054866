import React, { Dispatch } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { actions, RootState } from '../../store';
import { connect } from 'react-redux';
import { Menu, MenuItem } from '@material-ui/core';
import { IActiveOrders, Route } from '../../RemoteCommands/type';
import { useTranslation } from 'react-i18next';
import { SystemEvent } from '../../RemoteCommands/SystemEvent';
import moment from 'moment';

type Props = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {
    contextMenuClose: () => void,
    anchorEl: HTMLElement | null,
    activeOrder: IActiveOrders,
    handleClickOpen: () => void,
    removeOrderClick: () => void
}

const ContextMenu:React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const {
        contextMenuClose,
        anchorEl,
        activeOrder,
        setPointsAction,
        handleClickOpen,
        removeOrderClick
    } = props;

    const showOnMapClick = () => {
        setPointsAction(activeOrder.route);
        const dateTime = moment(new Date()).format("YYYY-MM-DDTHH:mm:ss");
        const geoPoints = activeOrder.route.map(item => item.geoPoint!);
        SystemEvent.EventGetRoutingInfo(geoPoints, dateTime);
        contextMenuClose();
    }

    const cancelOrderClick = () => {
        SystemEvent.EventCancelOrder(activeOrder.orderId);
        contextMenuClose();
    }

    return (
        <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={contextMenuClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            className="ml-2"
        >
            <MenuItem
                onClick={showOnMapClick}>
                {t("showMap")}
            </MenuItem>
            <MenuItem
                onClick={handleClickOpen}>
                {t("assignDriver")}
            </MenuItem>
            <MenuItem
                onClick={removeOrderClick}>
                {t("removeDriver")}
            </MenuItem>
            <MenuItem
                onClick={cancelOrderClick}>
                {t("сancelOrder")}
            </MenuItem>
        </Menu>
    );
};

const mapStateToProps = (state: RootState) => ({

});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    setPointsAction: (points: Route[]) => 
        dispatch(actions.event.setPointsAction(points)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContextMenu);