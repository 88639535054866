const BUILD_PARAMS = {
    PUBLIC_URL: process.env.PUBLIC_URL,
    WEBSERVER_APIKEY: process.env.REACT_APP_WEBSERVER_APIKEY,
    WEBAPI_URL: process.env.REACT_APP_WEBAPI_URL,
    WEBSERVER_URL: process.env.REACT_APP_WEBSERVER_URL,
    WEBSERVER_PORT: parseInt(process.env.REACT_APP_WEBSERVER_PORT || ''),
    GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
    SIP_PORT: parseInt(process.env.REACT_APP_SIP_PORT || ''),
    SIP_AGENT_NUMBER: process.env.REACT_APP_SIP_AGENT_NUMBER,
    SIP_URI: process.env.REACT_APP_SIP_URI,
    SERVER_API_URL: process.env.REACT_APP_SERVER_API_URL,
    DEFAULT_LANG: process.env.REACT_APP_DEFAULT_LANG,
    DEFAULT_CITY: process.env.REACT_APP_CITY,
    DEFAULT_CENTER_LAT: process.env.REACT_APP_CENTER_LAT,
    DEFAULT_CENTER_LNG: process.env.REACT_APP_CENTER_LNG
}


// проверка что все переменные заданы или имеют значение по дефолту

for(const paramName in BUILD_PARAMS){
    const typedParamName = paramName as keyof typeof BUILD_PARAMS;
    const paramValue : string | number | undefined = BUILD_PARAMS[typedParamName];
    if (paramValue === undefined
        || (typeof paramValue === 'number' && isNaN(paramValue as number))){
        throw new Error(`Invalid value '${paramValue}' of environment variable '${paramName}'`);
    }
}


export default BUILD_PARAMS;