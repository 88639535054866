import React, { useEffect, useMemo, useState } from 'react';
import { 
    Box, Button, ButtonGroup, Chip, CircularProgress, Grid, IconButton, TextField
} from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import AdvancedTariff from './AdvancedTariff';
import { useTranslation } from 'react-i18next';
import {
    initialMainCost, initialMinCost, initialRounding, 
    initialWaiting, MainCost, IMinimalCost, ParameterMeasure, PropertyShort, 
    PropertyValueSet, PropertyValuesSet, RateAllowance, Rounding, ShortGroups, Tariff, Waiting, TimeRange 
} from '../../RemoteCommands/type';
import MinCostItem from './MinCostItem';
import MainCostItem from './MainCostItem';
import WaitingItem from './WaitingItem';
import AddIcon from '@material-ui/icons/Add';
import RateAllowanceItem from './RateAllowanceItem';
import EditIcon from '@material-ui/icons/Edit';
import PropertiesModal from '../PropertiesModal/PropertiesModal';
import { errorCallback, SystemEvent } from '../../RemoteCommands/SystemEvent';
import RoundingItem from './RoundingItem';
import { replaceItemAtIndex } from '../../utils/replaceItem';
import GroupAutocomplete from '../bricks/GroupAutocomplete';

type Props = {
    tabValue: number;
    tabValueChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
    currentTariff?: Tariff;
    openAddTariffAction: (openAddTariff: boolean) => void;
    loading: boolean;
    currentPropertyValueSet: PropertyValueSet | undefined;
    addTariffSuccessAction: (addTariffSuccess: boolean) => void;
};

function TariffsContent(props: Props) {
    const {t} = useTranslation();
    const {
        tabValue,
        tabValueChange,
        currentTariff,
        openAddTariffAction,
        loading,
        currentPropertyValueSet,
        addTariffSuccessAction
    } = props;

    const [tariff, setTariff] = useState(new Tariff());
    const [currentGroup, setCurrentGroup] = useState<ShortGroups | null>(null);
    const [error, setError] = useState(false);

    const [currentPropertyValues, setCurrentPropertyValues] = useState<PropertyValuesSet[]>([]);
    const [loadingBtn, setLoadingBtn] = useState(false);

    const [open, setOpen] = useState(false);
    const [filterId, setFilterId] = useState<number | undefined>(tariff.filterId);

    const [minimalCost, setMinimalCost] = useState<IMinimalCost>(initialMinCost);
    const [mainCostKm, setMainCostKm] = useState<MainCost>({...initialMainCost, parameterMeasure: 1});
    const [mainCostMin, setMainCostMin] = useState<MainCost>({...initialMainCost, parameterMeasure: 2});
    const [waiting, setWaiting] = useState<Waiting>(initialWaiting);
    const [rounding, setRounding] = useState<Rounding>(initialRounding);

    useEffect(() => {
        SystemEvent.SubscribeEventSavePropertyValueSet(
            "TariffsContent", 
            (answer) => {
                setFilterId(answer.propertyValueSetId);
            }, 
            (error) => {
                errorCallback(error);
            }
        );
        SystemEvent.SubscribeEventSetTariff(
            "TariffsContent", 
            (answer) => {
                setTimeout(() => {
                    setLoadingBtn(false);
                    openAddTariffAction(false);
                    addTariffSuccessAction(true);
                }, 1000);
            }, 
            (error) => {
                setLoadingBtn(false);
                errorCallback(error);
            }
        );
    }, []);

    useEffect(() => {
        if(currentTariff) {
            setTariff(currentTariff);
            setFilterId(currentTariff.filterId ? currentTariff.filterId : undefined);
            const currentMinimalCost = currentTariff.minimalCosts.length > 0 ? currentTariff.minimalCosts[0] : initialMinCost;
            const currentWaiting = currentTariff.waitings.length > 0 ? currentTariff.waitings[0] : initialWaiting;
            const currentRounding = currentTariff.roundings.length > 0 ? currentTariff.roundings[0] : initialRounding;
            const curretnMainCostKm = 
                currentTariff.mainCosts.length > 0 &&  currentTariff.mainCosts.some(item => item.parameterMeasure === 1) 
                ? currentTariff.mainCosts[0] : {...initialMainCost, parameterMeasure: 1};
            const curretnMainCostMin = 
                currentTariff.mainCosts.length > 0 &&  currentTariff.mainCosts.some(item => item.parameterMeasure === 2) 
                ? currentTariff.mainCosts[0] : {...initialMainCost, parameterMeasure: 2};

            setMainCostKm(curretnMainCostKm);
            setMainCostMin(curretnMainCostMin);
            setWaiting(currentWaiting);
            setMinimalCost(currentMinimalCost);
            setRounding(currentRounding);
        }
    }, [currentTariff]);

    useMemo(() => {
        if(filterId && currentPropertyValueSet) {
            setCurrentPropertyValues(currentPropertyValueSet.values);
        }
    }, [currentPropertyValueSet]);

    useMemo(() => {
        if(filterId) {
            SystemEvent.EventGetPropertyValueSets([filterId]);
        }
    }, [filterId]);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }
    

    const handleDelete = (chipToDelete: PropertyValuesSet) => () => {
        let properties: PropertyShort[] = [];
        const newProperties = currentPropertyValues.filter((chip) => chip.propertyId !== chipToDelete.propertyId);
        for(let property of newProperties) {
            properties.push({
                propertyId: property.propertyId,
                propertyValueId: property.propertyValueId
            })
        }
        setCurrentPropertyValues(newProperties);
        SystemEvent.EventSavePropertyValueSet(properties);
    };

    const tariffChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        if(tariff) {
            setTariff({
                ...tariff,
                [name]: value
            })
        }

    }

    const addRatioClick = () => {
        const newRateAllowance: RateAllowance = {
            rowId: -(tariff.rateAllowances.length + 1),
            value: 0,
            rateType: ParameterMeasure.Rate,
            timeRange: null,
            isCashless: false,
            holidayParameter: null,
            dayOfWeekParameter: null,
            geoPointParameter: null,
            geoPointGroupParameter: null,
            geozoneParameter: null,
            cityIdParameter: null,
            priorityParameter: null
        }
        setTariff(prevState => ({
            ...prevState,
            rateAllowances: [
                ...prevState.rateAllowances,
                newRateAllowance
            ]
        }));
    }

    const rateAllowancesChange = (array: RateAllowance[]) => {
        setTariff(prevState => ({
            ...prevState,
            rateAllowances: array
        }));
    }

    const minimalCostChange = (source: IMinimalCost) => {
        setMinimalCost(source);
        const index = tariff.minimalCosts.findIndex(item => item.rowId === source.rowId);
        const newList = replaceItemAtIndex(tariff.minimalCosts, index, source);
        setTariff(prevState => ({
            ...prevState,
            minimalCosts: newList
        }));
    }

    const mainCostChange = (source: MainCost) => {
        if(
            mainCostKm.rowId === source.rowId 
            && mainCostKm.parameterMeasure === source.parameterMeasure
        ) {
            setMainCostKm(source);
        }
        if(
            mainCostMin.rowId === source.rowId 
            && mainCostMin.parameterMeasure === source.parameterMeasure
        ) {
            setMainCostMin(source);
        }
        const index = tariff.mainCosts.findIndex(item => item.rowId === source.rowId && item.parameterMeasure === source.parameterMeasure);
        const newList = replaceItemAtIndex(tariff.mainCosts, index, source);
        setTariff(prevState => ({
            ...prevState,
            mainCosts: newList
        }));
    }

    const waitingChange = (source: Waiting) => {
        setWaiting(source);
        const index = tariff.waitings.findIndex(item => item.rowId === source.rowId);
        const newList = replaceItemAtIndex(tariff.waitings, index, source);
        setTariff(prevState => ({
            ...prevState,
            waitings: newList
        }));
    }

    const roundingChange = (source: Rounding) => {
        setRounding(source);
        const index = tariff.roundings.findIndex(item => item.rowId === source.rowId);
        const newList = replaceItemAtIndex(tariff.roundings, index, source);
        setTariff(prevState => ({
            ...prevState,
            roundings: newList
        }));
    }

    const handleGroup = (group: ShortGroups | null) => {
        setCurrentGroup(group);
        if(group) {
            setError(false);
        } else {
            setError(true);
        }
    }

    const saveTariffClick = () => {
        setLoadingBtn(true);
        if(!currentGroup && !currentTariff) {
            setError(true);
            return setLoadingBtn(false);
        }
        const newTariff = new Tariff({
            tariffId: tariff.tariffId,
            basicTariffId: tariff.basicTariffId,
            description: tariff.description,
            filterId: filterId,
            groupId: currentGroup ? currentGroup.id : tariff.groupId,
            fullTariff: currentGroup ? true : tariff.fullTariff,
            mainCosts: tariff.mainCosts,
            minimalCosts: tariff.minimalCosts,
            rateAllowances: tariff.rateAllowances,
            roundings: tariff.roundings,
            waitings: tariff.waitings
        });
        SystemEvent.EventSetTariff(newTariff);
    }

    if(loading) {
        return (
            <Box className="loading" minHeight={260} pt={10}>
                <CircularProgress />
            </Box>
        )
    }
    return (
        <form className="cancelDraggable">
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <TextField
                        size="small"
                        fullWidth
                        label={t("NameTariff")}
                        name="description" 
                        value={tariff.description}
                        onChange={event => tariffChange(event)}
                        autoComplete='off'
                        inputProps={{
                            autoComplete: 'off'
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ul className="tariff-filter">
                        <label className="tariff-filter__label">{t("filter")}</label>
                        <div className="tariff-filter__row">
                            {currentPropertyValueSet && currentPropertyValueSet.values.map((property) => {
                                let icon;
                                return (
                                <li key={property.propertyId}>
                                    <Chip
                                        icon={icon}
                                        label={`${property.propertyLocalization}: ${property.value}`}
                                        onDelete={handleDelete(property)}
                                        size="small"
                                    />
                                </li>
                                );
                            })}       
                        </div>
                        <IconButton 
                            className="tariff-filter__edit"
                            onClick={handleOpen}>
                            <EditIcon fontSize="small" />
                        </IconButton>
                    </ul>
                </Grid>
                {!currentTariff &&
                     <Grid item xs={12}>
                        <GroupAutocomplete 
                            currentGroup={currentGroup}
                            handleGroup={handleGroup} 
                            error={error} />
                    </Grid>
                }
                <Grid item xs={12}>
                    <AntTabs value={tabValue} onChange={tabValueChange}>
                        <AntTab label={t("Simplified")} />
                        <AntTab label={t("Advanced")} />
                    </AntTabs>
                    <TabPanel value={tabValue} index={0}>
                        <MinCostItem 
                            minimalCost={minimalCost}
                            handleMinCost={(minimalCost: IMinimalCost) => minimalCostChange(minimalCost)} />
                        <Box className="simplified">
                            <Box className="simplified-sub-title">{t("EveryNextKmItcostsXrubles.")}&nbsp; / &nbsp;{t("everynextminuteitcostsinrubles.")}</Box>
                            <ButtonGroup 
                                color="primary"
                                size="small"
                                className="btn-group"
                                fullWidth>
                                <MainCostItem 
                                    mainCost={mainCostKm} 
                                    type="km" 
                                    handleMainCost={mainCostChange} />
                                <MainCostItem 
                                    mainCost={mainCostMin} 
                                    type="min" 
                                    handleMainCost={mainCostChange} />
                            </ButtonGroup>
                        </Box>
                        <WaitingItem 
                            waiting={waiting} 
                            handleWaiting={waitingChange} />
                        <Box className="simplified">
                        <Box className="simplified-sub-title">{t("Applythecoefficient")}&nbsp; / &nbsp; {t("intheperiodfromDDMMHHmm")} &nbsp; / &nbsp; {t("byDDMMHHmm")} </Box>
                            <Box className={`simplified-content ${tariff.rateAllowances.length > 3 ? "scroll" : ""}`}> 
                                {tariff.rateAllowances.map(item => (
                                    <RateAllowanceItem 
                                        key={item.rowId}
                                        rateAllowance={item}
                                        rateAllowances={tariff.rateAllowances} 
                                        rateAllowancesChange={rateAllowancesChange} />
                                ))}       
                            </Box>
                            <Grid 
                                container 
                                spacing={1}
                                justify="flex-end">
                                <Grid item>
                                    <Button 
                                        variant="outlined" 
                                        color="primary"
                                        startIcon={<AddIcon />}
                                        disableElevation
                                        size="small"
                                        onClick={addRatioClick}>
                                        {t("add")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                        <RoundingItem 
                            rounding={rounding} 
                            handleRounding={roundingChange} />
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <AdvancedTariff />
                    </TabPanel>
                </Grid>
            </Grid>
            <Grid 
                container 
                spacing={1}
                justify="flex-end">
                <Grid item>
                    <Button 
                        variant="contained"
                        startIcon={<CloseIcon />}
                        disableElevation
                        onClick={() => openAddTariffAction(false)}>
                        {t("cancel")}
                    </Button>
                </Grid>
                <Grid item>
                    <Button 
                        variant="contained" 
                        color="primary"
                        disabled={loadingBtn}
                        startIcon={loadingBtn ? <CircularProgress size={16} color="secondary" /> : <SaveIcon />}
                        disableElevation
                        onClick={saveTariffClick}>
                        {t("save")}
                    </Button>
                </Grid>
            </Grid>
            {open && 
                <PropertiesModal 
                    open={open} 
                    handleClose={handleClose} 
                    currentPropertyValues={currentPropertyValues} 
                    tariffProperty={true} />
            }
        </form>
    );
};

export default TariffsContent;

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (
                <Box className="tabpanel">
                    {children}
                </Box>
            )}
        </div>
    );
}


interface StyledTabProps {
    label: string;
}

const AntTabs = withStyles({
    root: {
      borderBottom: '1px solid #e8e8e8',
    },
    indicator: {
      backgroundColor: '#1890ff',
    },
  })(Tabs);
  
const AntTab = withStyles((theme: Theme) =>
    createStyles({
      root: {
        textTransform: 'none',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(4),
        '&:hover': {
          color: '#40a9ff',
          opacity: 1,
        },
        '&$selected': {
          color: '#1890ff',
          fontWeight: theme.typography.fontWeightMedium,
        },
        '&:focus': {
          color: '#40a9ff',
        },
      },
      selected: {},
    }),
)((props: StyledTabProps) => <Tab disableRipple {...props} />);