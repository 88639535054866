import BUILD_PARAMS from '../../utils/build';
import Map from './Map';
import './Map.scss'
import { Center } from './type';

const mapStyle = {
    width: '100%',
    height: '100vh'
};
  
const center: Center = {
    lat: BUILD_PARAMS.DEFAULT_CENTER_LAT ? parseFloat(BUILD_PARAMS.DEFAULT_CENTER_LAT) : 55.752256,
    lng: BUILD_PARAMS.DEFAULT_CENTER_LNG ? parseFloat(BUILD_PARAMS.DEFAULT_CENTER_LNG) : 37.619345
};

function MapContainer() {
    return (
        <Map 
            mapStyle={mapStyle}
            center={center} />
    );
}

export default MapContainer;