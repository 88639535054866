import { FunctionComponent } from 'react';
import Fab from '@material-ui/core/Fab';
import FullscreenIcon from '@material-ui/icons/Fullscreen';

type ResetBounds = {
    resetBounds?: () => void,
}

const ResetButton: FunctionComponent<ResetBounds> = (props) => {
    const { resetBounds } = props;
    return (
        <div className="reset-button">
            <Fab 
                color="primary" 
                size="small" 
                onClick={(resetBounds)}
            >
                <FullscreenIcon />
            </Fab>
        </div>
    )
}

export default ResetButton;