import { Dispatch, useEffect, useMemo, useState } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { RootState, actions } from '../../store';
import { connect } from 'react-redux';
import { Tariffs } from './Tariffs';
import { DefaultPosition, DraggablePosition, RollUpCard } from '../../store/app/types';
import { useTranslation } from 'react-i18next';
import { errorCallback, SystemEvent } from '../../RemoteCommands/SystemEvent';
import { PropertyValueSet, ShortGroups, Tariff } from '../../RemoteCommands/type';
import TariffDeleteModal from './TariffDeleteModal';
import './Tariffs.scss';

type Props = 
    ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps>;

const TariffsContainer = (props: Props) => {
    const { t } = useTranslation();
    const {
        draggable,
        draggablePositions,
        rollUpCards,
        draggablePositionAction,
        rollUpCardAction,
        openTariffs,
        openTariffsAction,
        openAddTariffAction,
        tariffsAction,
        tariffs,
        currentTariffAction,
        currentTariff,
        propertyValueSetAction,
        propertyValueSet,
        openAddTariff,
        currentPropertyValueSetAction,
        loadingAction,
        addTariffSuccess,
        addTariffSuccessAction
    } = props;

    const [currentGroup, setCurrentGroup] = useState<ShortGroups | null>(null);
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    }

    const handleGroup = (group: ShortGroups | null) => {
        setCurrentGroup(group);
    }

    useMemo(() => {
        SystemEvent.SubscribeEventGetPropertyValueSets(
            "TariffsContentContainer", 
            (answer) => {
                if(openAddTariff) {
                    const objProperty = Object.assign({}, ...answer.propertyValueSets);
                    currentPropertyValueSetAction(objProperty);
                } else {
                    propertyValueSetAction(answer.propertyValueSets);
                }
            }, 
            (error) => {
                errorCallback(error);
            }
        );
    }, [openAddTariff]);

    useEffect(() => {
        if(addTariffSuccess && currentGroup) {
            SystemEvent.EventGetTariffs(currentGroup.id);
        }
    }, [addTariffSuccess, openAddTariff]);

    useMemo(() => {
        SystemEvent.SubscribeEventGetTariffs(
            "TariffsContentContainer", 
            (answer) => {
                tariffsAction(answer.tariffs);
                const filterIds = answer.tariffs.map(item => item.filterId || -1);
                //@ts-ignore
                let uniqueFilterIds = [...new Set(filterIds)] as number[];
                if(openAddTariff === false) {
                    SystemEvent.EventGetPropertyValueSets(uniqueFilterIds);
                }
            }, 
            (error) => {
                errorCallback(error);
            }
        );
        if(currentGroup) {
            SystemEvent.EventGetTariffs(currentGroup.id);
        } else {
            tariffsAction([])
        }
    }, [currentGroup]);

    const addTariffClick = () => {
        openAddTariffAction(true);
        currentTariffAction(undefined);
        loadingAction(false);
        addTariffSuccessAction(false);
        currentPropertyValueSetAction(undefined);
    }

    const deleteTariffClick = (tariff: Tariff) => {
        currentTariffAction(tariff);
        setOpen(true);
    }
    
    const onStop = (event: any, ui: any) => {
        const { x, y } = ui;
        const position: DefaultPosition = { x, y };
        draggablePositionAction({
            key: 'Tariffs',
            position
        });
    };

    const rolleUpClick = () => {
        rollUpCardAction({
            title: t("Tariffs"),
            key: "Tariffs"
        });
    }
    const draggablePosition = draggablePositions.find(item => item.key === "Tariffs");
    const rollUpCardHas = rollUpCards.some(item => item.key === "Tariffs");

    return (
        <>
            <Tariffs 
                draggable={draggable} 
                draggablePosition={draggablePosition} 
                rollUpCardHas={rollUpCardHas}  
                onStop={onStop}  
                rolleUpClick={rolleUpClick} 
                openTariffsAction={openTariffsAction} 
                openTariffs={openTariffs} 
                openAddTariffAction={openAddTariffAction} 
                tariffs={tariffs}
                currentTariff={currentTariff} 
                addTariffClick={addTariffClick} 
                propertyValueSet={propertyValueSet} 
                loadingAction={loadingAction}
                handleGroup={handleGroup} 
                deleteTariffClick={deleteTariffClick} 
                currentGroup={currentGroup} 
                addTariffSuccessAction={addTariffSuccessAction} />
            {open && currentTariff &&
                <TariffDeleteModal 
                    open={open} 
                    handleClose={handleClose} 
                    tariff={currentTariff} 
                    tariffs={tariffs} 
                    tariffsAction={tariffsAction} />
            }
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        draggable: state.app.draggable,
        draggablePositions: state.app.draggablePositions,
        rollUpCards: state.event.rollUpCards,
        openTariffs: state.tariffs.openTariffs,
        tariffs: state.tariffs.tariffs,
        currentTariff: state.tariffs.currentTariff,
        propertyValueSet: state.event.propertyValueSet,
        openAddTariff: state.tariffs.openAddTariff,
        addTariffSuccess: state.tariffs.addTariffSuccess
    }
};

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({ 
    draggablePositionAction: (draggablePosition: DraggablePosition) => 
        dispatch(actions.app.draggablePositionAction(draggablePosition)),
    rollUpCardAction: (rollUpCard: RollUpCard) => 
        dispatch(actions.event.rollUpCardAction(rollUpCard)),
    openTariffsAction: (openTariffs: boolean) => 
        dispatch(actions.tariffs.openTariffsAction(openTariffs)),
    openAddTariffAction: (openAddTariff: boolean) => 
        dispatch(actions.tariffs.openAddTariffAction(openAddTariff)),
    tariffsAction: (tariffs: Tariff[]) => 
        dispatch(actions.tariffs.tariffsAction(tariffs)),
    currentTariffAction: (currentTariff: Tariff | undefined) => 
        dispatch(actions.tariffs.currentTariffAction(currentTariff)),
    propertyValueSetAction: (propertyValueSet: PropertyValueSet[]) => 
        dispatch(actions.event.propertyValueSetAction(propertyValueSet)),
    currentPropertyValueSetAction: (propertyValueSet: PropertyValueSet | undefined) => 
        dispatch(actions.event.currentPropertyValueSetAction(propertyValueSet)),
    loadingAction: (loading: boolean) => dispatch(actions.event.loadingAction(loading)),
    addTariffSuccessAction: (addTariffSuccess: boolean) => 
        dispatch(actions.tariffs.addTariffSuccessAction(addTariffSuccess))
});

export default connect(mapStateToProps, mapDispatchToProps)(TariffsContainer);