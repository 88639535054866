import { Dispatch, useState } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { actions, RootState } from '../../store';
import { connect } from 'react-redux';
import { 
    Box, ButtonGroup, Grid, IconButton, 
    InputAdornment, TextField, Tooltip 
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import { Trans, useTranslation } from 'react-i18next';
import { TooltipCustom } from '../../utils/TooltipCustom';
import { Tariff, TariffParameter, TimeRange, Waiting } from '../../RemoteCommands/type';
import { replaceItemAtIndex } from '../../utils/replaceItem';
import PropertyDeleteModal from './PropertyDeleteModal';
import { SystemEvent } from '../../RemoteCommands/SystemEvent';
import PeriodDate from './PeriodDate';

type Props = 
    ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps> & {
    item: Waiting;
    waitings: Waiting[];
};

function WaitingRow(props: Props) {
    const {t} = useTranslation();
    const {
        item,
        currentTariff,
        currentTariffAction,
        waitings
    } = props;

    const index = waitings.findIndex((listItem) => listItem.rowId === item.rowId);
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    }

    const handleTimeRange = (timeRange: TimeRange) => {
        const newList = replaceItemAtIndex(waitings, index, {
            ...item,
            timeRange
        });
        if(currentTariff) {
            currentTariffAction({
                ...currentTariff,
                waitings: newList
            })
        }
    }

    const waitingChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        let newList: Waiting[] = waitings;
        switch (name) {
            case 'freeMinutes':
                newList = replaceItemAtIndex(waitings, index, {
                    ...item,
                    freeMinutes: {
                        isEnabled: true,
                        value: Number(value),
                    }
                });
                break;
            case 'priorityParameter':
                newList = replaceItemAtIndex(waitings, index, {
                    ...item,
                    priorityParameter: {
                        isEnabled: true,
                        value: Number(value),
                    }
                });
                break;
            default:
                newList = replaceItemAtIndex(waitings, index, {
                    ...item,
                    [name]: Number(value),
                });
        }
        if(currentTariff) {
            currentTariffAction({
                ...currentTariff,
                waitings: newList
            })
        }
    }

    const saveMainCost = () => {
        if(!currentTariff) return;
        const tariffParameter: TariffParameter = {
            rowId: item.rowId,
            basicTariffId: currentTariff.basicTariffId,
            forExecutorTariff: false,
            waiting: item,
        }
        SystemEvent.EventUpdateTariffParameters(tariffParameter);
    }

    return (
        <>
            <Box className="advanced-tariff">
                <Box className="advanced-tariff__btn">
                    <Grid 
                        container>
                        <Grid item>
                            <Tooltip title={<Trans i18nKey="save"/>} placement="top">
                                <IconButton 
                                    size="small"
                                    onClick={saveMainCost}>
                                    <SaveIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip title={<Trans i18nKey="delete"/>} placement="top">
                                <IconButton 
                                    size="small"
                                    onClick={() => setOpen(true)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Box>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <ButtonGroup 
                            color="primary"
                            size="small"
                            className="btn-group"
                            fullWidth>
                            <TooltipCustom title="Стоимость" placement="top">
                                <TextField 
                                    placeholder="0.00"
                                    size="small" 
                                    type="number" 
                                    value={item.cost}
                                    name="cost"
                                    onChange={waitingChange}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">{t("currency")}/{t("mines.")}</InputAdornment>
                                    }}
                                />
                            </TooltipCustom>
                            <TooltipCustom title={<Trans i18nKey="Isfree"/>} placement="top">
                                <TextField 
                                    placeholder="0"
                                    size="small" 
                                    type="number"
                                    value={item.freeMinutes ? item.freeMinutes.value : ""}
                                    name="freeMinutes"
                                    onChange={waitingChange}
                                />
                            </TooltipCustom>
                        </ButtonGroup>      
                    </Grid>
                    <Grid item xs={12}>
                        <PeriodDate 
                            timeRange={item.timeRange} 
                            handleTimeRange={handleTimeRange} />
                    </Grid>
                    <Grid item xs={3}>
                        <TooltipCustom title={<Trans i18nKey="Priority"/>} placement="top">
                            <TextField 
                                variant="outlined"
                                placeholder="0"
                                size="small" 
                                type="number"
                                fullWidth 
                                value={item.priorityParameter ? item.priorityParameter.value : ""}
                                name="priorityParameter"
                                onChange={waitingChange}
                            />
                        </TooltipCustom>           
                    </Grid>
                </Grid>
            </Box>
            {open && currentTariff &&
                <PropertyDeleteModal 
                    open={open} 
                    handleClose={handleClose}
                    basicTariffId={currentTariff.basicTariffId} 
                    rowId={item.rowId} 
                    array={waitings}
                    name="waitings"
                    currentTariff={currentTariff}
                    currentTariffAction={currentTariffAction} />
            }
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    currentTariff: state.tariffs.currentTariff
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({ 
    currentTariffAction: (currentTariff: Tariff | undefined) => 
        dispatch(actions.tariffs.currentTariffAction(currentTariff)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WaitingRow);