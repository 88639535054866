import { Dispatch, useState } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { actions, RootState } from '../../store';
import { connect } from 'react-redux';
import { ButtonGroup, FormControl, MenuItem, Select } from '@material-ui/core';
import { DateTimePicker, MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import format from "date-fns/format";
import deLocale from "date-fns/locale/de";
import ruLocale from "date-fns/locale/ru";
import enLocale from "date-fns/locale/en-US";
import { Trans, useTranslation } from 'react-i18next';
import { TooltipCustom } from '../../utils/TooltipCustom';
import { initialTimeRane, Tariff, TimeRange, TimeRangeType } from '../../RemoteCommands/type';
import moment from 'moment';

type Props = 
    ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps> & {
        timeRange: TimeRange | null;
        handleTimeRange: (timeRange: TimeRange) => void;
    };

function PeriodDate(props: Props) {
    const {t} = useTranslation();
    const { 
        lang, timeRange,
        handleTimeRange
    } = props;

    const [dateInterval, setDateInterval] = useState<TimeRangeType>(timeRange ? timeRange.rangeType  : TimeRangeType.time);

    const dateIntervalChange = (name: string, value: Date | null) => {
        const dateString = moment(value).format("YYYY-MM-DDTHH:mm:ss");
        if(timeRange) {
            handleTimeRange({
                ...timeRange,
                [name]: dateString
            });
        } else {
            handleTimeRange({
                ...initialTimeRane,
                [name]: dateString,
                rangeType: dateInterval
            });
        }
    }

    const rangeTypeChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        const value = event.target.value as TimeRangeType;
        setDateInterval(value);
        if(timeRange) {
            handleTimeRange({
                ...timeRange,
                rangeType: value
            });
        }
    }

    return (
        <MuiPickersUtilsProvider 
            //@ts-ignore
            utils={lang ? localeUtilsMap[lang] : DateFnsUtils} 
            //@ts-ignore
            locale={lang ? localeMap[lang]: enLocale}>
            <ButtonGroup 
                color="primary"
                size="small"
                className="btn-group"
                fullWidth>
                <TooltipCustom title={<Trans i18nKey="Intervaltype" />} placement="top">
                    <FormControl 
                        variant="outlined"
                        size="small"
                        fullWidth>
                        <Select
                            labelId="day-of-the-week"
                            name="rangeType"
                            value={dateInterval}
                            onChange={rangeTypeChange}
                            placeholder={t("Intervaltype")}>
                            <MenuItem value={TimeRangeType.time}>{t("Anyday")}</MenuItem>
                            <MenuItem value={TimeRangeType.dayOfWeek}>{t("Dayoftheweek")}</MenuItem>
                            <MenuItem value={TimeRangeType.dateTime}>{t("Dayoftheyear")}</MenuItem>
                        </Select>
                    </FormControl>     
                </TooltipCustom>
                {dateInterval === TimeRangeType.dayOfWeek &&
                    <TooltipCustom title={<Trans i18nKey="Dayoftheweek" />} placement="top">
                        <FormControl 
                            variant="outlined"
                            size="small"
                            fullWidth>
                            <Select
                                labelId="day-of-the-week"
                                value="monday">
                                <MenuItem value="monday">{t("Monday")}</MenuItem>
                                <MenuItem value="tuesday">{t("Tuesday")}</MenuItem>
                                <MenuItem value="wednesday">{t("Wednesday")}</MenuItem>
                                <MenuItem value="thursday">{t("Thursday")}</MenuItem>
                                <MenuItem value="friday">{t("Friday")}</MenuItem>
                                <MenuItem value="saturday">{t("Saturday")}</MenuItem>
                                <MenuItem value="sunday">{t("Sunday")}</MenuItem>
                            </Select>
                        </FormControl>     
                    </TooltipCustom>
                }
                {dateInterval !== TimeRangeType.dateTime &&  
                    <TimePicker
                        inputVariant="outlined"
                        size="small"
                        title={t("Timefrom")}
                        ampm={false}
                        placeholder="00:00"
                        className="time"
                        value={timeRange ? timeRange.fromTime  : null}
                        onChange={(date) => dateIntervalChange("fromTime", date)}
                    />
                }
                {dateInterval === TimeRangeType.dayOfWeek &&
                    <TooltipCustom title={<Trans i18nKey="Dayoftheweek" />} placement="top">
                        <FormControl 
                            variant="outlined"
                            size="small"
                            fullWidth>
                            <Select
                                labelId="day-of-the-week"
                                value="monday">
                                <MenuItem value="monday">{t("Monday")}</MenuItem>
                                <MenuItem value="tuesday">{t("Tuesday")}</MenuItem>
                                <MenuItem value="wednesday">{t("Wednesday")}</MenuItem>
                                <MenuItem value="thursday">{t("Thursday")}</MenuItem>
                                <MenuItem value="friday">{t("Friday")}</MenuItem>
                                <MenuItem value="saturday">{t("Saturday")}</MenuItem>
                                <MenuItem value="sunday">{t("Sunday")}</MenuItem>
                            </Select>
                        </FormControl>     
                    </TooltipCustom>
                }
                {dateInterval !== TimeRangeType.dateTime &&  
                    <TimePicker
                        inputVariant="outlined"
                        title={t("Timeto")}
                        size="small"
                        ampm={false}
                        placeholder="00:00"
                        className="time"
                        value={timeRange ? timeRange.toTime  : null}
                        onChange={(date) => dateIntervalChange("toTime", date)}
                    />
                }
                {dateInterval === TimeRangeType.dateTime && 
                    <DateTimePicker
                        inputVariant="outlined"
                        size="small"
                        ampm={false}
                        title={t("Dateandtimefrom")}
                        format="dd MMMM HH:mm"
                        fullWidth
                        placeholder="01.09 00:00"
                        value={timeRange ? timeRange.fromTime  : null}
                        onChange={(date) => dateIntervalChange("fromTime", date)}
                    />
                }
                {dateInterval === TimeRangeType.dateTime && 
                    <DateTimePicker
                        inputVariant="outlined"
                        size="small"
                        ampm={false}
                        title={t("Dateandtimebefore")}
                        format="dd MMMM HH:mm"
                        fullWidth
                        placeholder="01.10 00:00"
                        value={timeRange ? timeRange.toTime  : null}
                        onChange={(date) => dateIntervalChange("toTime", date)}
                    />
                }
            </ButtonGroup>
        </MuiPickersUtilsProvider>
    );
};

const mapStateToProps = (state: RootState) => ({
    lang: state.app.lang,
    currentTariff: state.tariffs.currentTariff
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({ 
    currentTariffAction: (currentTariff: Tariff | undefined) => 
        dispatch(actions.tariffs.currentTariffAction(currentTariff)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PeriodDate);

const localeMap = {
    en: enLocale,
    de: deLocale,
    ru: ruLocale,
};
  
class RuLocalizedUtils extends DateFnsUtils {
    getCalendarHeaderText(date: Date) {
      return format(date, "LLLL", { locale: this.locale });
    }
  
    getDatePickerHeaderText(date: Date) {
      return format(date, "dd MMMM", { locale: this.locale });
    }
}
class DeLocalizedUtils extends DateFnsUtils {
    getDatePickerHeaderText(date: Date) {
      return format(date, "d MMM yyyy", { locale: this.locale });
    }
}
  
const localeUtilsMap = {
    en: DateFnsUtils,
    de: DeLocalizedUtils,
    ru: RuLocalizedUtils,
};