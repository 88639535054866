import { IGetAddressItem } from "sedi-webserverproxy";
import { CalculatedCosts, EventCalculateCost, EventError, GeoPoint, IActiveEmployees, IActiveOrders, ICarInfo, PropertySet, PropertyValueSet, Route, ShortGroups, Tariff, UpdateGpsData } from "../../RemoteCommands/type";

export interface EventState {
    errorMessage: string,
    addresses: IGetAddressItem[],
    selectedAddress: IGetAddressItem | null,
    addressValue: string,
    eventError: EventError | null,
    eventSuccess: EventSuccess | null,
    geoPointNull: boolean,
    rollUpCards: RollUpCard[];
    openEmployeesCard: boolean;
    employeesCount: number;
    openActiveOrders: boolean;
    activeOrders: IActiveOrders[];
    openOrder: boolean,
    points: Route[],
    openOrderRegistration: boolean,
    activeEmployees: IActiveEmployees[],
    updateGpsData: UpdateGpsData | null,
    routes: Routes[],
    selectedPoint: string,
    additionalServices: string[],
    calculateCost: EventCalculateCost | null,
    currentCalcCost: CalculatedCosts | undefined,
    isRegistration: boolean,
    ratios: IRatios[],
    propertySet: PropertySet | undefined,
    propertyValueSet: PropertyValueSet[],
    currentPropertyValueSet: PropertyValueSet | undefined,
    loading: boolean;
    shortGroups: ShortGroups[],
    employeeRoles: EmployeeRole[];
    routingInfo: RoutingInfo | null;
    cars: ICarInfo[];
    currentCar: ICarInfo | undefined;
}

export type RoutingInfo = {
    routePoints: GeoPoint[];
    totalDuration: number;
    totalDistance: number;
}
export type EventSuccess = {
    message: string,
    success: boolean
}
export type EmployeeRole = {
    role: string;
    roleLocalization: string;
}
export interface IRatios {
    id: string;
}
export interface Routes extends Route {
    id: string;
}

export type RollUpCard = {
    title: string,
    key: string,
}

export enum EventActionsTypes {
    ERROR_MESSAGE = 'ERROR_MESSAGE',
    GET_ADDRESSES = 'GET_ADDRESSES',
    SELECTED_ADDRESS = 'SELECTED_ADDRESS',
    ADDRESS_VALUE = 'ADDRESS_VALUE',
    EVENT_ERROR = 'EVENT_ERROR',
    GEO_POINT_NULL = 'GEO_POINT_NULL',
    ROLL_UP_CARD = 'ROLL_UP_CARD',
    ROLL_UP_CARDS = 'ROLL_UP_CARDS',
    OPEN_EMPLOYEES_CARD = 'OPEN_EMPLOYEES_CARD',
    EMPLOYEES_COUNT = 'EMPLOYEES_COUNT',
    OPEN_ACTIVE_ORDERS = 'OPEN_ACTIVE_ORDERS',
    ACTIVE_ORDERS = 'ACTIVE_ORDERS',
    OPEN_ORDER = 'OPEN_ORDER',
    SET_ROUTE = 'SET_ROUTE',
    OPEN_ORDER_REGISTRATION = 'OPEN_ORDER_REGISTRATION',
    GET_ACTIVE_EMPLOYEES = 'GET_ACTIVE_EMPLOYEES',
    UPDATE__GPS_DATA = 'UPDATE__GPS_DATA',
    SET_POINTS = 'SET_POINTS',
    SET_SELECTED_POINT = 'SET_SELECTED_POINT',
    SET_ROUTES = 'SET_ROUTES',
    ADDITIONAL_SERVICES = 'ADDITIONAL_SERVICES',
    CALCULATE_COST = 'CALCULATE_COST',
    CURRENT_CALC_COST = 'CURRENT_CALC_COST',
    IS_REGISTRATION = 'IS_REGISTRATION',
    OPEN_TARIFFS = 'OPEN_TARIFFS',
    RATIOS = 'RATIOS',
    OPEN_ADD_TARIFF = 'OPEN_ADD_TARIFF',
    TARIFFS = 'TARIFFS',
    CURRENT_TARIFF = 'CURRENT_TARIFF',
    PROPERTY_SET = 'PROPERTY_SET',
    PROPERTY_VALUE_SET = 'PROPERTY_VALUE_SET',
    CURRENT_PROPERTY_VALUE_SET = 'CURRENT_PROPERTY_VALUE_SET',
    LOADING = 'LOADING',
    SHORT_GROUPS = 'SHORT_GROUPS',
    EMPLOYEE_ROLE = 'EMPLOYEE_ROLE',
    EVENT_SUCCESS = 'EVENT_SUCCESS',
    ROUTING_INFO = 'ROUTING_INFO',
    CARS = 'CARS',
    CURRENT_CAR = 'CURRENT_CAR'
}

export type DateInterval = {
    dateFrom: string | null;
    dateTo: string | null;
}