import { Dispatch } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { RootState } from '../../store';
import { connect } from 'react-redux';
import { 
    Box, ButtonGroup,
    InputAdornment, TextField 
} from '@material-ui/core';
import { TooltipCustom } from '../../utils/TooltipCustom';
import { Trans, useTranslation } from 'react-i18next';
import { Rounding } from '../../RemoteCommands/type';

type Props = 
    ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps> & {
    rounding?: Rounding;
    handleRounding: (rounding: Rounding) => void;
};

function RoundingItem(props: Props) {
    const {t} = useTranslation();
    const {
        rounding,
        handleRounding
    } = props;

    const roundingChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const value = event.target.value;
        handleRounding({
            ...rounding!,
            value: Number(value)
        })
    }

    return (
        <Box className="simplified">
            <Box className="simplified-sub-title">{t("RoundtoXX,XX")}</Box>
            <ButtonGroup 
                color="primary"
                size="small"
                className="btn-group"
                fullWidth>
                <TooltipCustom title={<Trans i18nKey="RoundtoXX,XX" />} placement="top">
                    <TextField 
                        placeholder="00,00"
                        size="small" 
                        type="number" 
                        fullWidth
                        value={rounding ? rounding.value : ""}
                        onChange={roundingChange}
                        inputProps={{
                            "step": ".01"
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{t("currency")}</InputAdornment>
                        }}
                    />
                </TooltipCustom>        
            </ButtonGroup>
        </Box>
    );
};

const mapStateToProps = (state: RootState) => ({

});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({ 

});

export default connect(mapStateToProps, mapDispatchToProps)(RoundingItem);