import React from 'react';
import { RootState } from '../../store';
import { connect } from 'react-redux';
import { Menu, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

type Props = ReturnType<typeof mapStateToProps> & {
    contextMenuClose: () => void,
    anchorEl: HTMLElement | null,
    sipAccount: string | null,
    employeeClickOpen: () => void,
    employeeCallClickOpen: () => void
}

const ContextMenuCall:React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const {
        contextMenuClose,
        anchorEl,
        employeeClickOpen,
        employeeCallClickOpen
    } = props;

    return (
        <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={contextMenuClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            className="ml-2"
        >
            <MenuItem
                onClick={employeeClickOpen}>
                {t("openTheEmployeeCard")}
            </MenuItem>
            <MenuItem
                onClick={() => contextMenuClose()}>
                {t("writeLetter")}
            </MenuItem>
            <MenuItem
                onClick={employeeCallClickOpen}>
                {t("call")}
            </MenuItem>
        </Menu>
    );
};

const mapStateToProps = (state: RootState) => ({
});

export default connect(mapStateToProps)(ContextMenuCall);