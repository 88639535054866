import React from 'react';
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from "@material-ui/pickers";
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import LocalTaxiIcon from '@material-ui/icons/LocalTaxi';
import { CalculateCost, EventCalculateCost, ShortGroups } from '../../RemoteCommands/type';
import { RouteItemContainer } from '../RouteItem';
import { Routes } from '../../store/event/types';
import format from "date-fns/format";
import deLocale from "date-fns/locale/de";
import ruLocale from "date-fns/locale/ru";
import enLocale from "date-fns/locale/en-US";
import DateFnsUtils from "@date-io/date-fns";
import InputPhoneFormated from '../bricks/inputPhoneFormated';
import { 
    Badge,
    Button, FormControl, FormControlLabel, 
    Grid, Radio, RadioGroup, TextField, CircularProgress, Box 
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { TariffsCarousel } from '../TariffsCarousel';
import AdditionalServices from './AdditionalServices';
import { Autocomplete } from '@material-ui/lab';
import moment from 'moment';
import { SystemEvent } from '../../RemoteCommands/SystemEvent';
import { AccessList } from '../../utils/AccessList';

type Props = {
    handleServicesClick: () => void;
    handleCarClick: () => void;
    formik: any;
    calculateClick: () => void;
    routes: Routes[];
    lang: string | null;
    formikHandleChange: (name: string, value: string | number | undefined) => void;
    selectedDate: Date | null;
    handleDateChange: (date: any) => void;
    orderTime: string;
    orderTimeChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    calculateCost: EventCalculateCost | null;
    additionalServices: string[];
    loading: boolean;
    isRegistration: boolean;
    shortGroups: ShortGroups[];
    groupHandleValue: (value: string) => void;
};

export function OrderContent(props: Props) {
    const { t } = useTranslation();
    const {
        handleServicesClick,
        formik,
        calculateClick,
        routes,
        lang,
        formikHandleChange,
        selectedDate,
        handleDateChange,
        orderTime,
        orderTimeChange,
        calculateCost,
        additionalServices,
        loading,
        isRegistration,
        shortGroups,
        groupHandleValue
    } = props;

    const values = formik.values;

    const handleGroupChange = (changeValue: ShortGroups | null) => {
        let route: Routes[] = [];
        for(let point of routes) {
            if(point.geoPoint) {
                route.push(point);
            }
        }
        const calculateCost: CalculateCost = {
            orderTime: selectedDate ? moment(selectedDate).format("YYYY-MM-DDTHH:mm:ss") : undefined,
            isCashless: true,
            specialServices: additionalServices.join(';'),
            route: route,
            groupId: changeValue ? changeValue.id : undefined
        }
        SystemEvent.EventCalculateCost(calculateCost);
        if(changeValue) {
            return formikHandleChange("groupId", changeValue.id);
        } 
        return formikHandleChange("groupId", undefined);
    }

    const availableGroups = AccessList.GetRegisterOrderGroupIds();
    const groups = shortGroups.filter(item => availableGroups ? availableGroups.indexOf(item.id) > -1 : shortGroups);

    // const menuItemView = () => {
    //     if(calculateCost) {
    //         const { calculatedCosts } = calculateCost;
    //         const result = calculatedCosts.map(item => (
    //             <MenuItem key={item.costCalculationId} value={item.costCalculationId}>
    //                 {item.tariffDescription}
    //             </MenuItem>
    //         ))
    //         return result
    //     }
    //     return null
    // }

    const buttonView = () => {
        const disabled = Boolean(!routes[0].geoPoint);
        if(!isRegistration) {
            return (
                <Button 
                    variant="contained" 
                    color="primary"
                    startIcon={loading ? <CircularProgress size={16} color="secondary" /> : <MonetizationOnIcon />}
                    onClick={calculateClick}
                    disabled={disabled}>
                    {t("calculate")}
                </Button>
            )
        }
        return (
            <Button 
                type="submit"
                variant="contained" 
                color="primary"
                disabled={loading || disabled}
                startIcon={loading ? <CircularProgress size={16} color="secondary" /> : <LocalTaxiIcon />}
                onClick={formik.handleSubmit}>
                {t("placeAnOrder")}
            </Button>
        )
    }

    return (
        <form 
            className="order-content cancelDraggable"
            onSubmit={formik.handleSubmit}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <div className="sub-title first">{t("fromWhereAndWhereToGo")}</div>
                </Grid>
                {routes.map((route, i) => (
                    <RouteItemContainer 
                        key={route.id} 
                        route={route} />
                ))}
            </Grid>
            <Grid container spacing={1} alignItems="center">
                <Grid item xs={12}>
                    <div className="sub-title">{t("orderTime")}</div>
                </Grid>
                <Grid item>
                    <FormControl component="fieldset">
                        <RadioGroup 
                            row aria-label="orderTime" 
                            name="orderTime" 
                            defaultValue="top"
                            value={orderTime} 
                            onChange={orderTimeChange}>
                            <FormControlLabel 
                                value="urgently" 
                                control={<Radio color="primary" />} 
                                label={t("urgently")} 
                            />
                            <FormControlLabel 
                                value="chooseTime" 
                                control={<Radio color="primary" />} 
                                label={t("chooseTime")} 
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                {orderTime === "chooseTime" && 
                    <Grid item xs={5}>
                        <MuiPickersUtilsProvider 
                            //@ts-ignore
                            utils={lang ? localeUtilsMap[lang] : DateFnsUtils} 
                            //@ts-ignore
                            locale={lang ? localeMap[lang]: enLocale}>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <KeyboardDatePicker
                                        fullWidth
                                        size="small"
                                        //@ts-ignore
                                        format={lang ? localeFormatMap[lang] : "en"}
                                        value={selectedDate}
                                        onChange={data => handleDateChange(data)}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <KeyboardTimePicker
                                        fullWidth
                                        size="small"
                                        id="time-picker"
                                        clearable
                                        ampm={false}
                                        value={selectedDate}
                                        onChange={data => handleDateChange(data)}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change time',
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </Grid>
                }
                {groups.length > 1 &&
                    <Grid item xs={2}>
                        <Autocomplete
                            fullWidth
                            options={groups}
                            getOptionLabel={(option: ShortGroups) => option.name}
                            onChange={(event: React.ChangeEvent<{}>, changeValue: ShortGroups | null) => handleGroupChange(changeValue)}
                            onInputChange={(event: React.ChangeEvent<{}>, value: string) => groupHandleValue(value)}
                            renderInput={(params) => 
                                <TextField 
                                    {...params} 
                                    size="small" 
                                    placeholder={t("group")}
                                    error={formik.touched.groupId && Boolean(formik.errors.groupId)}
                                    helperText={formik.touched.groupId && formik.errors.groupId}/>
                            }
                        />
                    </Grid>
                }
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <div className="sub-title">{t("additionalInformation")}</div>
                </Grid>
                <Badge 
                    badgeContent={additionalServices.length} 
                    color="secondary" 
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}>
                    <div></div>
                </Badge>
                <Grid item xs={12}>
                    <AdditionalServices />
                </Grid>
                <Grid item xs={6}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <TextField
                                size="small"
                                fullWidth
                                label={t("customerName")}
                                name="customerName" 
                                value={values.customerName}
                                onChange={formik.handleChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <InputPhoneFormated 
                                onPhoneChange={(phone) => formikHandleChange("customerPhone", phone.value)}
                                variant="standard"
                                fullWidth
                                label={t("phone")}
                                size="small"
                                name="customerPhone" 
                                value={values.customerPhone}
                                error={formik.touched.customerPhone && Boolean(formik.errors.customerPhone)}
                                helperText={formik.touched.customerPhone && formik.errors.customerPhone}
                                formik={formik}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        multiline
                        size="small"
                        fullWidth
                        label={t("comment")}
                        name="comment" 
                        value={values.comment}
                        onChange={formik.handleChange}
                    />
                </Grid>
            </Grid>
            {calculateCost && 
                <Grid 
                    container 
                    spacing={2}>
                    <Grid item xs={12}>
                        <TariffsCarousel 
                            calculatedCosts={calculateCost.calculatedCosts} />
                    </Grid>
                </Grid>
            }
            <Grid 
                container 
                spacing={2}
                justify="flex-end">
                <Grid item>
                    <Box pt={1}>
                        {buttonView()}
                    </Box>
                </Grid>
            </Grid>
        </form>
    );
};

const localeMap = {
    en: enLocale,
    de: deLocale,
    ru: ruLocale,
};
  
class RuLocalizedUtils extends DateFnsUtils {
    getCalendarHeaderText(date: Date) {
      return format(date, "LLLL", { locale: this.locale });
    }
  
    getDatePickerHeaderText(date: Date) {
      return format(date, "dd MMMM", { locale: this.locale });
    }
}
class DeLocalizedUtils extends DateFnsUtils {
    getDatePickerHeaderText(date: Date) {
        return format(date, "dd MMM yyyy", { locale: this.locale });
    }
}
  
const localeUtilsMap = {
    en: DateFnsUtils,
    de: DeLocalizedUtils,
    ru: RuLocalizedUtils,
};
  
const localeFormatMap = {
    en: "MM dd, yyyy",
    de: "dd.MM.yyyy",
    ru: "dd.MM.yyyy",
};