import { CallerAccountInfo } from "../../sip/sipTypes";


export interface SipState {
    isInitialized: boolean;
    isInitializing: boolean;

    callerAccountInfo?: CallerAccountInfo;
    phoneNumber: string;
    hasActiveCall: boolean;
    isEstablished: boolean;
    isIncoming: boolean;
    isMuted: boolean;

    username: string;
    lastError: string;
    callsHistory: CallInfo[];
    sipDisconnectedError: boolean;   
    sipLogin: string;
}

export class CallInfo {
    constructor(time: Date, number: string, isIncoming: boolean, wasAnswered: boolean) {
        this.time = time;
        this.number = number;
        this.isIncoming = isIncoming;
        this.wasAnswered = wasAnswered;
    }

    public time: Date;
    public number: string;
    public isIncoming: boolean;
    public wasAnswered: boolean;
}