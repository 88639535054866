import { Dispatch, useState } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { actions, RootState } from '../../store';
import { connect } from 'react-redux';
import { 
    Box, ButtonGroup, Checkbox, FormControl, FormControlLabel, Grid, IconButton, 
    InputAdornment, MenuItem, 
    Select, TextField, Tooltip 
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import { Trans, useTranslation } from 'react-i18next';
import { TooltipCustom } from '../../utils/TooltipCustom';
import { MainCost, Tariff, TariffParameter, TimeRange } from '../../RemoteCommands/type';
import { replaceItemAtIndex } from '../../utils/replaceItem';
import PropertyDeleteModal from './PropertyDeleteModal';
import { SystemEvent } from '../../RemoteCommands/SystemEvent';
import PeriodDate from './PeriodDate';

type Props = 
    ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps> & {
    item: MainCost;
    mainCosts: MainCost[];
};

function MainCostsRow(props: Props) {
    const {t} = useTranslation();
    const {
        item,
        currentTariff,
        currentTariffAction,
        mainCosts
    } = props;
    const index = mainCosts.findIndex((listItem) => listItem.rowId === item.rowId);
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    }

    const handleTimeRange = (timeRange: TimeRange) => {
        const newList = replaceItemAtIndex(mainCosts, index, {
            ...item,
            timeRange
        });
        if(currentTariff) {
            currentTariffAction({
                ...currentTariff,
                mainCosts: newList
            })
        }
    }

    const mainCostChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        let newList: MainCost[] = mainCosts;
        switch (name) {
            case 'distanceRangeFrom':
                newList = replaceItemAtIndex(mainCosts, index, {
                    ...item,
                    distanceRange: {
                        ...item.distanceRange,
                        from: Number(value),
                        isEnabled: true
                    }
                });
                break;
            case 'distanceRangeTo':
                newList = replaceItemAtIndex(mainCosts, index, {
                    ...item,
                    distanceRange: {
                        ...item.distanceRange,
                        to: Number(value),
                        isEnabled: true
                    }
                });
                break;
            case 'hoursRangeFrom':
                newList = replaceItemAtIndex(mainCosts, index, {
                    ...item,
                    hoursRange: {
                        ...item.hoursRange,
                        from: Number(value),
                    }
                });
                break;
            case 'hoursRangeTo':
                newList = replaceItemAtIndex(mainCosts, index, {
                    ...item,
                    hoursRange: {
                        ...item.hoursRange,
                        to: Number(value),
                        isEnabled: true
                    }
                });
                break;
            case 'speedRangeFrom':
                newList = replaceItemAtIndex(mainCosts, index, {
                    ...item,
                    speedRange: {
                        ...item.speedRange,
                        from: Number(value),
                        isEnabled: true
                    }
                });
                break;
            case 'speedRangeTo':
                newList = replaceItemAtIndex(mainCosts, index, {
                    ...item,
                    speedRange: {
                        ...item.speedRange,
                        to: Number(value),
                        isEnabled: true
                    }
                });
                break;
            case 'priorityParameter':
                newList = replaceItemAtIndex(mainCosts, index, {
                    ...item,
                    priorityParameter: {
                        isEnabled: true,
                        value: Number(value),
                    }
                });
                break;
            default:
                newList = replaceItemAtIndex(mainCosts, index, {
                    ...item,
                    [name]: Number(value),
                });
        }
        if(currentTariff) {
            currentTariffAction({
                ...currentTariff,
                mainCosts: newList
            })
        }
    }

    const handleSelectChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        const name = event.target.name as keyof typeof item;
        const value = event.target.value as number;
        const newList = replaceItemAtIndex(mainCosts, index, {
            ...item,
            [name]: Number(value),
        });
        if(currentTariff) {
            currentTariffAction({
                ...currentTariff,
                mainCosts: newList
            })
        }
    };

    const handleCheckedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const checked = event.target.checked;
        const newList = replaceItemAtIndex(mainCosts, index, {
            ...item,
            [name]: checked
        });
        if(currentTariff) {
            currentTariffAction({
                ...currentTariff,
                mainCosts: newList
            })
        }
      };

    const saveMainCost = () => {
        if(!currentTariff) return;
        const tariffParameter: TariffParameter = {
            rowId: item.rowId,
            basicTariffId: currentTariff.basicTariffId,
            forExecutorTariff: false,
            mainCost: item,
        }
        SystemEvent.EventUpdateTariffParameters(tariffParameter);
    }

    return (
        <>
            <Box
                className="advanced-tariff">
                <Box className="advanced-tariff__btn">
                    <Grid 
                        container>
                        <Grid item>
                            <Tooltip 
                                title={<Trans i18nKey="save"/>} 
                                placement="top"
                                onClick={saveMainCost}>
                                <IconButton size="small">
                                    <SaveIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip 
                                title={<Trans i18nKey="delete"/>} 
                                placement="top"
                                onClick={() => setOpen(true)}
                                >
                                <IconButton size="small">
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Box>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <ButtonGroup 
                            color="primary"
                            size="small"
                            className="btn-group"
                            fullWidth>
                            <TooltipCustom title={<Trans i18nKey="Supply/Minimalamount"/>} placement="top">
                                <TextField 
                                    placeholder="0.00"
                                    size="small" 
                                    type="number" 
                                    value={item.cost}
                                    name="cost"
                                    onChange={mainCostChange}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">{t("currency")}</InputAdornment>
                                    }}
                                />
                            </TooltipCustom>
                            <TextField 
                                placeholder="0"
                                size="small" 
                                type="number" 
                                value={item.amount}
                                name="amount"
                                onChange={mainCostChange}
                            />
                            <FormControl 
                                variant="outlined"
                                size="small"
                                fullWidth>
                                <Select
                                    value={item.parameterMeasure}
                                    name="parameterMeasure"
                                    onChange={handleSelectChange}>
                                    <MenuItem value={1}>{t("km")}</MenuItem>
                                    <MenuItem value={2}>{t("mines.")}</MenuItem>
                                    <MenuItem value={4}>{t("Total")}</MenuItem>
                                </Select>
                            </FormControl> 
                        </ButtonGroup>      
                    </Grid>
                    <Grid item xs={12}>
                        <PeriodDate 
                            timeRange={item.timeRange} 
                            handleTimeRange={handleTimeRange} />
                    </Grid>
                    <Grid item xs={6}>
                        <ButtonGroup 
                            color="primary"
                            size="small"
                            className="btn-group"
                            fullWidth>
                            <TooltipCustom title={<Trans i18nKey="Intervalfrom"/>} placement="top">
                                <TextField 
                                    placeholder="0"
                                    size="small" 
                                    type="number" 
                                    value={item.speedRange ? item.speedRange.from : ""}
                                    name="speedRangeFrom"
                                    onChange={mainCostChange}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">{t("km")}/{t("hour")}</InputAdornment>
                                    }}
                                />
                            </TooltipCustom>
                            <TooltipCustom title={<Trans i18nKey="Intervalto"/>} placement="top">
                                <TextField 
                                    placeholder="0"
                                    size="small" 
                                    type="number" 
                                    value={item.speedRange ? item.speedRange.to : ""}
                                    name="speedRangeTo"
                                    onChange={mainCostChange}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">{t("km")}/{t("hour")}</InputAdornment>
                                    }}
                                />
                            </TooltipCustom>
                        </ButtonGroup>
                    </Grid>
                    <Grid item xs={6}>
                        <ButtonGroup 
                            color="primary"
                            size="small"
                            className="btn-group"
                            fullWidth>
                            <TooltipCustom title={<Trans i18nKey="Intervalfrom"/>} placement="top">
                                <TextField 
                                    placeholder="0"
                                    size="small" 
                                    type="number" 
                                    value={item.distanceRange ? item.distanceRange.from : ""}
                                    name="distanceRangeFrom"
                                    onChange={mainCostChange}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">{t("km")}</InputAdornment>
                                    }}
                                />
                            </TooltipCustom>
                            <TooltipCustom title={<Trans i18nKey="Intervalto"/>} placement="top">
                                <TextField 
                                    placeholder="0"
                                    size="small" 
                                    type="number" 
                                    value={item.distanceRange ? item.distanceRange.to : ""}
                                    name="distanceRangeTo"
                                    onChange={mainCostChange}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">{t("km")}</InputAdornment>
                                    }}
                                />
                            </TooltipCustom>
                        </ButtonGroup>
                    </Grid>
                    <Grid item xs={6}>
                        <ButtonGroup 
                            color="primary"
                            size="small"
                            className="btn-group"
                            fullWidth>
                            <TooltipCustom title={<Trans i18nKey="Traveltimefrom"/>} placement="top">
                                <TextField 
                                    placeholder="0"
                                    size="small" 
                                    type="number" 
                                    value={item.hoursRange ? item.hoursRange.from : ""}
                                    name="hoursRangeFrom"
                                    onChange={mainCostChange}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">{t("hour")}</InputAdornment>
                                    }}
                                />
                            </TooltipCustom>
                            <TooltipCustom title={<Trans i18nKey="Traveltimeto"/>} placement="top">
                                <TextField 
                                    placeholder="0"
                                    size="small" 
                                    type="number" 
                                    value={item.hoursRange ? item.hoursRange.to : ""}
                                    name="hoursRangeTo"
                                    onChange={mainCostChange}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">{t("hour")}</InputAdornment>
                                    }}
                                />
                            </TooltipCustom>
                        </ButtonGroup>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={item.clientInCar}
                                    name="clientInCar"
                                    onChange={handleCheckedChange}
                                    color="primary"
                                />
                            }
                            label={t("Clientinthecar")}
                        />               
                    </Grid>
                    <Grid item xs={2}>
                        <TooltipCustom title={<Trans i18nKey="Priority"/>} placement="top">
                            <TextField 
                                variant="outlined"
                                placeholder="0"
                                size="small" 
                                type="number"
                                value={item.priorityParameter ? item.priorityParameter.value : ""}
                                name="priorityParameter"
                                onChange={mainCostChange}
                            />
                        </TooltipCustom>           
                    </Grid>
                </Grid>
            </Box>
            {open && currentTariff &&
                <PropertyDeleteModal 
                    open={open} 
                    handleClose={handleClose}
                    basicTariffId={currentTariff.basicTariffId} 
                    rowId={item.rowId} 
                    array={mainCosts}
                    name="mainCosts"
                    currentTariff={currentTariff}
                    currentTariffAction={currentTariffAction} />
            }
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    currentTariff: state.tariffs.currentTariff
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({ 
    currentTariffAction: (currentTariff: Tariff | undefined) => 
        dispatch(actions.tariffs.currentTariffAction(currentTariff)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainCostsRow);