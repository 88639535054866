import { ActionCreator, Action } from 'redux';
import { 
    EmployeeRole,
    EventActionsTypes,  
    EventSuccess,  
    IRatios,  
    RollUpCard,
    Routes,
    RoutingInfo, 
} from './types';
import { IGetAddressItem } from 'sedi-webserverproxy';
import { CalculatedCosts, EventCalculateCost, EventError, IActiveEmployees, IActiveOrders, ICarInfo, PropertySet, PropertyValueSet, Route, ShortGroups, UpdateGpsData } from '../../RemoteCommands/type';

export interface IErrorMessage extends Action<EventActionsTypes.ERROR_MESSAGE> {
    errorMessage: string;
}

export interface IGetAddresses extends Action<EventActionsTypes.GET_ADDRESSES> {
    addresses: IGetAddressItem[];
}

export interface ISelectedAddress extends Action<EventActionsTypes.SELECTED_ADDRESS> {
    selectedAddress: IGetAddressItem | null;
}

export interface IAddressValue extends Action<EventActionsTypes.ADDRESS_VALUE> {
    addressValue: string;
}

export interface IEventError extends Action<EventActionsTypes.EVENT_ERROR> {
    eventError: EventError | null;
}

export interface IGeoPointNull extends Action<EventActionsTypes.GEO_POINT_NULL> {
    geoPointNull: boolean;
}

export interface IRollUpCard extends Action<EventActionsTypes.ROLL_UP_CARD> {
    rollUpCard: RollUpCard;
}
export interface IRollUpCards extends Action<EventActionsTypes.ROLL_UP_CARDS> {
    rollUpCards: RollUpCard[];
}


export interface IOpenEmployeesCard extends Action<EventActionsTypes.OPEN_EMPLOYEES_CARD> {
    openEmployeesCard: boolean;
}

export interface IEmployeesCount extends Action<EventActionsTypes.EMPLOYEES_COUNT> {
    employeesCount: number;
}

export interface IOpenActiveOrders extends Action<EventActionsTypes.OPEN_ACTIVE_ORDERS> {
    openActiveOrders: boolean;
}

export interface IActiveOrdersAction extends Action<EventActionsTypes.ACTIVE_ORDERS> {
    activeOrders: IActiveOrders[];
}

export interface IOpenOrder extends Action<EventActionsTypes.OPEN_ORDER> {
    openOrder: boolean;
}

export interface IOrderRegistration extends Action<EventActionsTypes.OPEN_ORDER_REGISTRATION> {
    openOrderRegistration: boolean;
}

export interface IActiveEmployeesAction extends Action<EventActionsTypes.GET_ACTIVE_EMPLOYEES> {
    activeEmployees: IActiveEmployees[];
}

export interface IUpdateGpsDataAction extends Action<EventActionsTypes.UPDATE__GPS_DATA> {
    updateGpsData: UpdateGpsData | null;
}
export interface IRoutes extends Action<EventActionsTypes.SET_ROUTES> { routes: Routes[] }
export interface IRoute extends Action<EventActionsTypes.SET_ROUTE> { route: Routes, index: number }
export interface IPoints extends Action<EventActionsTypes.SET_POINTS> { points: Route[] }
export interface ISelectedPoint extends Action<EventActionsTypes.SET_SELECTED_POINT> { selectedPoint: string }
export interface IAdditionalServices extends Action<EventActionsTypes.ADDITIONAL_SERVICES> { additionalServices: string[] }
export interface ICalculateCost extends Action<EventActionsTypes.CALCULATE_COST> { calculateCost: EventCalculateCost | null }
export interface ICurrentCalcCost extends Action<EventActionsTypes.CURRENT_CALC_COST> { currentCalcCost: CalculatedCosts | undefined }
export interface IIsRegistration extends Action<EventActionsTypes.IS_REGISTRATION> { isRegistration: boolean }
export interface IRatiosAction extends Action<EventActionsTypes.RATIOS> { ratios: IRatios[] }
export interface IPropertySet extends Action<EventActionsTypes.PROPERTY_SET> { propertySet: PropertySet | undefined }
export interface IPropertyValueSet extends Action<EventActionsTypes.PROPERTY_VALUE_SET> { propertyValueSet: PropertyValueSet[] }
export interface ICurrentPropertyValueSet extends Action<EventActionsTypes.CURRENT_PROPERTY_VALUE_SET> { currentPropertyValueSet: PropertyValueSet | undefined }
export interface ILoading extends Action<EventActionsTypes.LOADING> { loading: boolean }
export interface IShortGroups extends Action<EventActionsTypes.SHORT_GROUPS> { shortGroups: ShortGroups[] }
export interface IEmployeeRoles extends Action<EventActionsTypes.EMPLOYEE_ROLE> { employeeRoles: EmployeeRole[] }
export interface IEventSuccess extends Action<EventActionsTypes.EVENT_SUCCESS> { eventSuccess: EventSuccess | null }
export interface IRoutingInfo extends Action<EventActionsTypes.ROUTING_INFO> { routingInfo: RoutingInfo | null }
export interface ICars extends Action<EventActionsTypes.CARS> { cars: ICarInfo[] }
export interface ICurrentCar extends Action<EventActionsTypes.CURRENT_CAR> { currentCar: ICarInfo | undefined }

export type EventActions =
    | IErrorMessage
    | IGetAddresses
    | ISelectedAddress
    | IAddressValue
    | IEventError
    | IGeoPointNull
    | IRollUpCard
    | IRollUpCards
    | IOpenEmployeesCard
    | IEmployeesCount
    | IOpenActiveOrders
    | IActiveOrdersAction
    | IOpenOrder
    | IRoute
    | IOrderRegistration
    | IActiveEmployeesAction
    | IUpdateGpsDataAction
    | IPoints
    | ISelectedPoint
    | IRoutes
    | IAdditionalServices
    | ICalculateCost
    | IIsRegistration
    | ICurrentCalcCost
    | IRatiosAction
    | IPropertySet
    | IPropertyValueSet
    | ICurrentPropertyValueSet
    | ILoading
    | IShortGroups
    | IEmployeeRoles
    | IEventSuccess
    | IRoutingInfo
    | ICars
    | ICurrentCar;

export const currentCarAction: ActionCreator<ICurrentCar> = (currentCar: ICarInfo | undefined) => {
    return {
        type: EventActionsTypes.CURRENT_CAR,
        currentCar
    }
}
export const carsAction: ActionCreator<ICars> = (cars: ICarInfo[]) => {
    return {
        type: EventActionsTypes.CARS,
        cars
    }
}
export const routingInfoAction: ActionCreator<IRoutingInfo> = (routingInfo: RoutingInfo | null) => {
    return {
        type: EventActionsTypes.ROUTING_INFO,
        routingInfo
    }
}
export const employeeRolesAction: ActionCreator<IEmployeeRoles> = (employeeRoles: EmployeeRole[]) => {
    return {
        type: EventActionsTypes.EMPLOYEE_ROLE,
        employeeRoles
    }
}
export const shortGroupsAction: ActionCreator<IShortGroups> = (shortGroups: ShortGroups[]) => {
    return {
        type: EventActionsTypes.SHORT_GROUPS,
        shortGroups
    }
}
export const loadingAction: ActionCreator<ILoading> = (loading: boolean) => {
    return {
        type: EventActionsTypes.LOADING,
        loading
    }
}
export const currentPropertyValueSetAction: ActionCreator<ICurrentPropertyValueSet> = (currentPropertyValueSet: PropertyValueSet | undefined) => {
    return {
        type: EventActionsTypes.CURRENT_PROPERTY_VALUE_SET,
        currentPropertyValueSet
    }
}
export const propertyValueSetAction: ActionCreator<IPropertyValueSet> = (propertyValueSet: PropertyValueSet[]) => {
    return {
        type: EventActionsTypes.PROPERTY_VALUE_SET,
        propertyValueSet
    }
}
export const propertySetAction: ActionCreator<IPropertySet> = (propertySet: PropertySet | undefined) => {
    return {
        type: EventActionsTypes.PROPERTY_SET,
        propertySet
    }
}
export const ratiosAction: ActionCreator<IRatiosAction> = (ratios: IRatios[]) => {
    return {
        type: EventActionsTypes.RATIOS,
        ratios
    }
}
export const isRegistrationAction: ActionCreator<IIsRegistration> = (isRegistration: boolean) => {
    return {
        type: EventActionsTypes.IS_REGISTRATION,
        isRegistration
    }
}
export const calculateCostAction: ActionCreator<ICalculateCost> = (calculateCost: EventCalculateCost | null) => {
    return {
        type: EventActionsTypes.CALCULATE_COST,
        calculateCost
    }
}
export const currentCalcCostAction: ActionCreator<ICurrentCalcCost> = (currentCalcCost: CalculatedCosts | undefined) => {
    return {
        type: EventActionsTypes.CURRENT_CALC_COST,
        currentCalcCost
    }
}
export const additionalServicesAction: ActionCreator<IAdditionalServices> = (additionalServices: string[]) => {
    return {
        type: EventActionsTypes.ADDITIONAL_SERVICES,
        additionalServices
    }
}
export const selectedPointAction: ActionCreator<ISelectedPoint> = (selectedPoint: string) => {
    return {
        type: EventActionsTypes.SET_SELECTED_POINT,
        selectedPoint
    }
}
export const setRoutesAction: ActionCreator<IRoutes> = (routes: Routes[]) => {
    return {
        type: EventActionsTypes.SET_ROUTES,
        routes
    }
}
export const setRouteAction: ActionCreator<IRoute> = (route: Routes, index: number) => {
    return {
        type: EventActionsTypes.SET_ROUTE,
        route,
        index
    }
}
export const updateGpsDataAction: ActionCreator<IUpdateGpsDataAction> = (updateGpsData: UpdateGpsData | null) => {
    return {
        type: EventActionsTypes.UPDATE__GPS_DATA,
        updateGpsData
    }
}
export const activeEmployeesAction: ActionCreator<IActiveEmployeesAction> = (activeEmployees: IActiveEmployees[]) => {
    return {
        type: EventActionsTypes.GET_ACTIVE_EMPLOYEES,
        activeEmployees
    }
}
export const openOrderRegistrationAction: ActionCreator<IOrderRegistration> = (openOrderRegistration: boolean) => {
    return {
        type: EventActionsTypes.OPEN_ORDER_REGISTRATION,
        openOrderRegistration
    }
}
export const setPointsAction: ActionCreator<IPoints> = (points: Route[]) => {
    return {
        type: EventActionsTypes.SET_POINTS,
        points
    }
}

export const openOrderAction: ActionCreator<IOpenOrder> = (openOrder: boolean) => {
    return {
        type: EventActionsTypes.OPEN_ORDER,
        openOrder
    }
}

export const activeOrdersAction: ActionCreator<IActiveOrdersAction> = (activeOrders: IActiveOrders[]) => {
    return {
        type: EventActionsTypes.ACTIVE_ORDERS,
        activeOrders
    }
}

export const openActiveOrdersAction: ActionCreator<IOpenActiveOrders> = (openActiveOrders: boolean) => {
    return {
        type: EventActionsTypes.OPEN_ACTIVE_ORDERS,
        openActiveOrders
    }
}

export const employeesCountAction: ActionCreator<IEmployeesCount> = (employeesCount: number) => {
    return {
        type: EventActionsTypes.EMPLOYEES_COUNT,
        employeesCount
    }
}

export const openEmployeesCardAction: ActionCreator<IOpenEmployeesCard> = (openEmployeesCard: boolean) => {
    return {
        type: EventActionsTypes.OPEN_EMPLOYEES_CARD,
        openEmployeesCard
    }
}

export const rollUpCardsAction: ActionCreator<IRollUpCards> = (rollUpCards: RollUpCard[]) => {
    return {
        type: EventActionsTypes.ROLL_UP_CARDS,
        rollUpCards
    }
}

export const rollUpCardAction: ActionCreator<IRollUpCard> = (rollUpCard: RollUpCard) => {
    return {
        type: EventActionsTypes.ROLL_UP_CARD,
        rollUpCard
    }
}

export const geoPointNullAction: ActionCreator<IGeoPointNull> = (geoPointNull: boolean) => {
    return {
        type: EventActionsTypes.GEO_POINT_NULL,
        geoPointNull
    }
}

export const eventErrorAction: ActionCreator<IEventError> = (eventError: EventError | null) => {
    return {
        type: EventActionsTypes.EVENT_ERROR,
        eventError
    }
}

export const eventSuccessAction: ActionCreator<IEventSuccess> = (eventSuccess: EventSuccess | null) => {
    return {
        type: EventActionsTypes.EVENT_SUCCESS,
        eventSuccess
    }
}

export const addressValueAction: ActionCreator<IAddressValue> = (addressValue: string) => {
    return {
        type: EventActionsTypes.ADDRESS_VALUE,
        addressValue
    }
}

export const selectedAddressAction: ActionCreator<ISelectedAddress> = (selectedAddress: IGetAddressItem | null) => {
    return {
        type: EventActionsTypes.SELECTED_ADDRESS,
        selectedAddress
    }
}

export const addressesAction: ActionCreator<IGetAddresses> = (addresses: IGetAddressItem[]) => {
    return {
        type: EventActionsTypes.GET_ADDRESSES,
        addresses
    }
}

export const errorMessageAction: ActionCreator<IErrorMessage> = (errorMessage: string) => {
    return {
        type: EventActionsTypes.ERROR_MESSAGE,
        errorMessage
    }
}