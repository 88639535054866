import { SipState} from './types';


export function isInitialized(state: SipState) {
  return state.isInitialized;
}


export function isInitializing(state: SipState) {
  return state.isInitializing;
}


export function GetCallerAccountInfo(state: SipState) {
  return state.callerAccountInfo;
}


export function GetCallerAccountID(state: SipState) {
  return state.callerAccountInfo ? state.callerAccountInfo.accountId : -1;
}


export function GetCallerPhoneNumber(state: SipState) {
  return state.phoneNumber;
}

export function sipNumber(state: SipState) {
    if (!state.sipLogin)
        return '';
    var result = state.sipLogin.match(':(.*)@');
    return result == null ? '' : result.pop();
}