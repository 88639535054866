import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import { IActiveOrders } from '../../RemoteCommands/type';
import { OrderInfoModalContainer } from '../OrderInfoModal';
import { Trans, useTranslation } from 'react-i18next';

type Props = {
    activeOrders: IActiveOrders[];
    openOrderInfoClick: (orderID: number) => void;
    contextMenuOpen: (event: React.MouseEvent<HTMLElement, MouseEvent>, activeOrder: IActiveOrders) => void;
};

export function ActiveOrdersTable(props: Props) {
    const { t } = useTranslation();
    const { 
        activeOrders,
        openOrderInfoClick,
        contextMenuOpen
    } = props;

    return (
        <TableContainer 
            component={Paper}
            className="activeOrdersTable">
            <Table stickyHeader size="small">
                <TableHead>
                    <TableRow>
                        <TableCell width={110}>
                            <span>{t("orderID")}</span>
                        </TableCell>
                        <TableCell>
                            <Tooltip title={<Trans i18nKey="customerName" />}>
                                <span>{t("customerName")}</span>
                            </Tooltip>
                        </TableCell>
                        <TableCell>{t("status")}</TableCell>
                        <TableCell>{t("address")}</TableCell>
                        <TableCell>{t("route")}</TableCell>
                        <TableCell>{t("owner")}</TableCell>
                        <TableCell>{t("beginning")}</TableCell>
                        <TableCell>{t("cost")}</TableCell>
                        <TableCell>{t("executor")}</TableCell>
                        <TableCell>
                            <Tooltip title={<Trans i18nKey="employeeName" />}>
                                <span>{t("employeeName")}</span>
                            </Tooltip>
                        </TableCell>
                        <TableCell>{t("machineBrand")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody className="cancelDraggable">
                    {activeOrders.map((item) => (
                        <TableRow 
                            key={`active-order-${item.orderId}`}
                            onClick={() => openOrderInfoClick(item.orderId)}>
                            <TableCell 
                                onContextMenu={(event) => contextMenuOpen(event, item)}>
                                {item.orderId}
                            </TableCell>
                            <TableCell 
                                component="th" scope="row"
                                onContextMenu={(event) => contextMenuOpen(event, item)}>
                                <Tooltip title={item.customerName}>
                                    <span>{item.customerName}</span>
                                </Tooltip>
                            </TableCell>
                            <TableCell
                                onContextMenu={(event) => contextMenuOpen(event, item)}>
                                <Tooltip title={item.orderStatusText}>
                                    <span>{item.orderStatusText}</span>
                                </Tooltip>
                            </TableCell>
                            <TableCell
                                onContextMenu={(event) => contextMenuOpen(event, item)}>
                                {item.route[0] && 
                                    <Tooltip title={`${item.route[0].street || ""} ${item.route[0].houseNumber || ""} ${item.route[0].city || ""}`}>
                                        <span>{`${item.route[0].street || ""} ${item.route[0].houseNumber || ""} ${item.route[0].city || ""}`}</span>
                                    </Tooltip>
                                }
                            </TableCell>
                            <TableCell
                                onContextMenu={(event) => contextMenuOpen(event, item)}>
                                {item.route[1] &&
                                    <Tooltip title={`${item.route[1].street || ""} ${item.route[1].houseNumber || ""} ${item.route[1].city || ""}`}>
                                        <span>{`${item.route[1].street || ""} ${item.route[1].houseNumber || ""} ${item.route[1].city || ""}`}</span>
                                    </Tooltip>
                                }
                            </TableCell>
                            <TableCell
                                onContextMenu={(event) => contextMenuOpen(event, item)}>
                                <Tooltip title={item.ownerGroupName}>
                                    <span>{item.ownerGroupName}</span>
                                </Tooltip>
                            </TableCell>
                            <TableCell
                                onContextMenu={(event) => contextMenuOpen(event, item)}>
                                <Tooltip title={item.orderTime}>
                                    <span>{item.orderTime}</span>
                                </Tooltip>
                            </TableCell>
                            <TableCell
                                onContextMenu={(event) => contextMenuOpen(event, item)}>
                                <Tooltip title={item.cost}>
                                    <span>{item.cost}</span>
                                </Tooltip>
                            </TableCell>
                            <TableCell
                                onContextMenu={(event) => contextMenuOpen(event, item)}>
                                <Tooltip title={item.executorGroupName}>
                                    <span>{item.executorGroupName}</span>
                                </Tooltip>
                            </TableCell>
                            <TableCell
                                onContextMenu={(event) => contextMenuOpen(event, item)}>
                                <Tooltip title={item.executorName}>
                                    <span>{item.executorName}</span>
                                </Tooltip>
                            </TableCell>
                            <TableCell
                                onContextMenu={(event) => contextMenuOpen(event, item)}>
                                <Tooltip title={item.carInfo || ""}>
                                    <span>{item.carInfo}</span>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <OrderInfoModalContainer />
        </TableContainer>
    );
};