import { useEffect, useState } from 'react';
import { errorCallback, SystemEvent } from '../../RemoteCommands/SystemEvent';
import { EmployeeFilter, IActiveOrders, IShortEmployeesInfo } from '../../RemoteCommands/type';
import { EmployeeRole } from '../../store/event/types';
import { AssignDriver } from './AssignDriver';
import './AssignDriver.scss';

type Props = {
    activeOrder: IActiveOrders;
    open: boolean;
    handleClose: () => void;
};

function AssignDriverContainer(props: Props) {
    const { 
        activeOrder,
        open,
        handleClose
    } = props;

    const [shortAllEmployeesInfo, setShortAllEmployeesInfo] = useState<IShortEmployeesInfo[]>([]);
    const [shortEmployeesInfo, setShortEmployeesInfo] = useState<IShortEmployeesInfo[]>([]);
    const [currentRoles, setCurrentRoles] = useState<EmployeeRole[]>([]);
    const [currentEmploee, setCurrentEmploee] = useState<IShortEmployeesInfo | null>(null);
    const [search, setSearch] = useState("");

    const onCurrentRoleChange = (roles: EmployeeRole[]) => {
        setCurrentRoles(roles);
    }

    const handleEmploeeChange = (changeValue: IShortEmployeesInfo | null) => {
        setCurrentEmploee(changeValue);
    }

    const handleSeachChange = (value: string) => {
        setSearch(value);
    }

    useEffect(() => {
        SystemEvent.SubscribeEventGetEmployeesShortInfo(
            "EventGetEmployeesShortInfo", 
            (answer) => {
                setShortAllEmployeesInfo(answer.shortEmployeesInfo);
                setShortEmployeesInfo(answer.shortEmployeesInfo);
            }, 
            (error) => {
                errorCallback(error);
            }
        );

        SystemEvent.SubscribeEventAssignExecutorToOrder(
            "EventAssignExecutorToOrder", 
            (answer) => {
                handleClose();
                handleEmploeeChange(null);
                SystemEvent.EventGetActiveOrdersForDispatcher();
            }, 
            (error) => {
                errorCallback(error);
            }
        );
    }, []);

    const shortEmployeesFilter = () => {
        const employeeFilter: EmployeeFilter = {
            textFilter: search,
            roles: currentRoles.length > 0 ? currentRoles.map(item => item.role) : null
        }

        const employesEmpty = shortAllEmployeesInfo.some((employee) => {
            const fullName = employee.firstName + " " + employee.secondName; 
            return fullName.toLowerCase().search(search.toLowerCase()) != -1;
        });

        if(shortAllEmployeesInfo.length >= 200 || !employesEmpty || search.length === 0) {
            SystemEvent.EventGetEmployeesShortInfo(employeeFilter);
        } else {
            let searchHandle: IShortEmployeesInfo[] = [];
            searchHandle = shortAllEmployeesInfo.filter(employee => {
                const fullName = employee.firstName + " " + employee.secondName; 
                return fullName.toLowerCase().indexOf(search.toLowerCase()) === 0 || fullName.toLowerCase().indexOf(' ' + search.toLowerCase()) > 0
            });
            setShortEmployeesInfo(searchHandle);
        }
    }

    useEffect(() => {
        shortEmployeesFilter();
    }, [search, currentRoles]);

    const assignDriverClick = () => {
        if(currentEmploee) {
            SystemEvent.EventAssignExecutorToOrder(activeOrder.orderId, currentEmploee.employeeId, false);
        }
    }

    return (
        <AssignDriver 
            activeOrder={activeOrder} 
            open={open} 
            handleClose={handleClose} 
            currentRoles={currentRoles} 
            onCurrentRoleChange={(roles: EmployeeRole[]) => onCurrentRoleChange(roles)} 
            currentEmploee={currentEmploee} 
            handleEmploeeChange={(changeValue: IShortEmployeesInfo) => handleEmploeeChange(changeValue)} 
            handleSeachChange={(value: string) => handleSeachChange(value)}
            shortEmployeesInfo={shortEmployeesInfo} 
            assignDriverClick={assignDriverClick} />
    );
};

export default AssignDriverContainer;