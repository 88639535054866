import React from 'react';
// import ButtonGroup from './ButtonGroup';

type Props = {
  
};

export function ActionsStack(props: Props) {
    return (
        <div className="notification fixedButton">
            {/* <ButtonGroup /> */}
        </div>
    );
};