import React from 'react';
import { IncomingCall } from './IncomingCall';
import { connect } from 'react-redux';
import { RootState } from '../../../store';
import Card from '@material-ui/core/Card';
import { ActionsStackContainer } from '../../ActionsStack';


type Props = ReturnType<typeof mapStateToProps>;


class PortalToast extends React.Component<Props>{

    render() {
        const { hasActiveCall, isIncomingCall } = this.props;

        return (
            <Card className="table-bordered notificationRow">
                <ActionsStackContainer />
                {hasActiveCall &&
                    <IncomingCall isIncomingCall={isIncomingCall} />
                }
            </Card>
        );
    }
}


const mapStateToProps = (state: RootState) => ({
    hasActiveCall: state.sip.hasActiveCall,
    isIncomingCall: state.sip.isIncoming,
});


export default connect(
    mapStateToProps
)(PortalToast);