import * as React from 'react';
import { 
    Button, Dialog, DialogActions, DialogContent, DialogTitle 
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { errorCallback, SystemEvent } from '../../RemoteCommands/SystemEvent';
import { ICarInfo, PropertyValueSet } from '../../RemoteCommands/type';
import { removeItemAtIndex } from '../../utils/replaceItem';

type Props = {
    open: boolean;
    handleClose: () => void;
    currentCar: ICarInfo;
    driverId: number;
    cars: ICarInfo[];
    carsAction: (cars: ICarInfo[]) => void;
    currentPropertyValueSetAction: (propertyValueSet: PropertyValueSet | undefined) => void;
    currentCarAction: (car: ICarInfo | undefined) => void;
};

function CarDeleteModal(props: Props) {
    const { t } = useTranslation();
    const { 
        open, handleClose, currentCar, driverId, cars, 
        carsAction, currentPropertyValueSetAction, currentCarAction 
    } = props;

    React.useEffect(() => {
        SystemEvent.SubscribeEventDeleteCar(
            "CarDeleteModal", 
            (answer) => {
                const index = cars.findIndex((listItem) => listItem === currentCar);
                const newList = removeItemAtIndex(cars, index);
                carsAction(newList);
                currentPropertyValueSetAction(undefined);
                currentCarAction(undefined);
                SystemEvent.EventGetEmployeeDetailedInfo(driverId);
            }, 
            (error) => {
                errorCallback(error);
            }
        );
    }, []);


    const deleteClick = () => {
        SystemEvent.EventDeleteCar(currentCar.carId, driverId);
        handleClose();
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}>
            <DialogTitle>
               {t("untieTheCar")} {currentCar.carNumber}
            </DialogTitle>
            <DialogContent>
                {t("doYouReallyUntieCar")} <strong>{currentCar.carNumber}</strong> ?
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={handleClose}>{t("cancel")}</Button>
                <Button color="primary" onClick={deleteClick} autoFocus>Ok</Button>
            </DialogActions>
        </Dialog>
    );
};

export default CarDeleteModal;