import React from 'react';
import { Button, Grid, Paper, TextField } from '@material-ui/core';
import { UseInput } from './type';
import { useTranslation } from 'react-i18next';

type Props = {
    login: UseInput;
    password: UseInput;
    phoneNumber: UseInput;
    PIN: UseInput;
    sendToKey: () => void;
    userIdentificationPin: () => void;
    needVerify: boolean;
    disabledSandToKey: boolean;
}

const Authorization:React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const { 
        phoneNumber,
        PIN,
        sendToKey,
        userIdentificationPin,
        needVerify,
        login,
        password,
        disabledSandToKey
     } = props;

    return (
        <Grid 
            container 
            spacing={2} 
            justify="center" 
            alignItems="center" 
            style={{height: '100vh'}}
        >
            <Grid item xs={4}>
                <Paper 
                    className="authorization" 
                    elevation={2}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <div className="title">{t("authorization")}</div>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField 
                                {...login.bind}
                                type="text"
                                label={t("login")} 
                                variant="outlined" 
                                size="small"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField 
                                {...password.bind}
                                type="password"
                                label={t("password")}
                                variant="outlined" 
                                size="small"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <div className="numberWrap">
                                <TextField 
                                    {...phoneNumber.bind}
                                    type="text"
                                    label={t("phoneNumber")}
                                    variant="outlined" 
                                    size="small"
                                    fullWidth
                                />
                                <Button 
                                    variant="contained" 
                                    color="primary"
                                    disabled={disabledSandToKey}
                                    onClick={sendToKey}>
                                    {t("sendTheKey")}
                                </Button>
                            </div>
                        </Grid>
                        {needVerify && 
                            <Grid item xs={12}>
                                <TextField 
                                    {...PIN.bind}
                                    type="text"
                                    label="PIN" 
                                    variant="outlined" 
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <div className="btnWrap">
                                <Button 
                                    variant="contained" 
                                    color="primary"
                                    size="large"
                                    onClick={userIdentificationPin}>
                                    {t("loginSand")}
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}

export default Authorization;