import { Dispatch } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { RootState } from '../../store';
import { connect } from 'react-redux';
import { 
    InputAdornment, TextField 
} from '@material-ui/core';
import { TooltipCustom } from '../../utils/TooltipCustom';
import { Trans, useTranslation } from 'react-i18next';
import { MainCost } from '../../RemoteCommands/type';

type Props = 
    ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps> & {
    mainCost?: MainCost;
    type: "km" | "min";
    handleMainCost: (mainCost: MainCost) => void;
};

function MainCostItem(props: Props) {
    const {t} = useTranslation();
    const {
        mainCost,
        type,
        handleMainCost
    } = props;

    const mainCostChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        handleMainCost({
            ...mainCost!,
            [name]: Number(value)
        })
    }

    return (
        <TooltipCustom title={type === "km" ? <Trans i18nKey="EveryNextKmItcostsXrubles." /> : <Trans i18nKey="everynextminuteitcostsinrubles." />} placement="top">
            <TextField 
                placeholder={"0.00"}
                size="small" 
                type="number" 
                fullWidth
                variant="outlined"
                name="cost"
                value={mainCost ? mainCost.cost : ""}
                onChange={mainCostChange}
                InputProps={{
                    endAdornment: <InputAdornment position="end">{t("currency")}</InputAdornment>
                }}
            />
        </TooltipCustom>
    );
};

const mapStateToProps = (state: RootState) => ({
    
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({ 
    
});

export default connect(mapStateToProps, mapDispatchToProps)(MainCostItem);