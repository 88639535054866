import { 
    CircularProgress, Dialog, DialogActions, 
    DialogContent, DialogTitle, IconButton,
    Grid, TextField, Button
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import { IOrderInfo } from '../../RemoteCommands/type';
import { useTranslation } from 'react-i18next';
import { HistoryOrderContainer } from '../HistoryOrder';
import { useEffect, useState } from 'react';

type Props = {
    openOrder: boolean;
    handleClose: () => void;
    loading: boolean;
    orderInfo: IOrderInfo | null;
};

export function OrderInfoModal(props: Props) {
    const { t } = useTranslation();
    const {
        openOrder,
        handleClose,
        loading,
        orderInfo
    } = props;

    const [updateHistory, setUpdateHistory] = useState(false);

    const routingValue = () => {
        if(!orderInfo) return;
        let arraySting: string[] = [];
        for(let i = 0; i < orderInfo.route.length; i++) {
            arraySting.push(`${orderInfo.route[i].city} ${orderInfo.route[i].street} ${orderInfo.route[i].houseNumber}`);
        }
        return arraySting.join(" -> ");
    }

    const orderInfoView = () => {
        if(loading || !orderInfo) {
            return (
                <div className="loading">
                    <CircularProgress />
                </div>
            )
        }
        return (
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <TextField
                            type="text"
                            size="small"
                            fullWidth
                            disabled={true}
                            label={t("customerName")}
                            name="customerName" 
                            value={orderInfo.customerName}
                            onChange={(event) => console.log(event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            type="text"
                            size="small"
                            fullWidth
                            disabled={true}
                            label={t("address")}
                            name="customerName" 
                            value={routingValue()}
                            onChange={(event) => console.log(event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            type="text"
                            size="small"
                            fullWidth
                            disabled={true}
                            label={t("orderProperties")}
                            name="orderPropertiesText" 
                            value={orderInfo.orderPropertiesText}
                            onChange={(event) => console.log(event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            type="text"
                            size="small"
                            fullWidth
                            disabled={true}
                            label={t("owner")}
                            name="ownerGroupName" 
                            value={orderInfo.ownerGroupName}
                            onChange={(event) => console.log(event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            type="text"
                            size="small"
                            fullWidth
                            disabled={true}
                            label={t("executor")}
                            name="executorGroupName" 
                            value={orderInfo.executorGroupName}
                            onChange={(event) => console.log(event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            type="text"
                            size="small"
                            fullWidth
                            disabled={true}
                            label={t("responsibleCustomer")}
                            name="customerId" 
                            value={`${orderInfo.customerName} (ID:${orderInfo.customerId})`}
                            onChange={(event) => console.log(event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            type="text"
                            size="small"
                            fullWidth
                            disabled={true}
                            label={t("cost")}
                            name="cost" 
                            value={orderInfo.cost}
                            onChange={(event) => console.log(event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            type="text"
                            size="small"
                            fullWidth
                            disabled={true}
                            label={t("basicRate")}
                            name="basicTariffDescription" 
                            value={orderInfo.basicTariffDescription}
                            onChange={(event) => console.log(event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            type="text"
                            size="small"
                            fullWidth
                            disabled={true}
                            label={t("orderType")}
                            name="orderTypeText" 
                            value={orderInfo.orderTypeText}
                            onChange={(event) => console.log(event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            type="text"
                            size="small"
                            fullWidth
                            disabled={true}
                            label={t("orderTime")}
                            name="orderTime" 
                            value={orderInfo.orderTime}
                            onChange={(event) => console.log(event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            type="text"
                            size="small"
                            fullWidth
                            disabled={true}
                            label={t("additionalInformation")}
                            name="note" 
                            value={orderInfo.note}
                            onChange={(event) => console.log(event.target.value)}
                        />
                    </Grid>
                    {orderInfo.carInfo.length !== 0 && 
                        <Grid item xs={12}>
                            <TextField
                                type="text"
                                size="small"
                                fullWidth
                                disabled={true}
                                label={t("driver")}
                                name="executorName" 
                                value={`${orderInfo.executorName} ${orderInfo.carInfo}`}
                                onChange={(event) => console.log(event.target.value)}
                            />
                        </Grid>
                    }
                </Grid>
                {orderInfo && 
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <HistoryOrderContainer 
                                orderInfo={orderInfo} 
                                updateHistory={updateHistory} />
                        </Grid>
                    </Grid>
                }
            </DialogContent>
        )
    }

    return (
        <Dialog
            open={openOrder}
            onClose={handleClose}
            fullWidth
            maxWidth="md"
            className="employee-modal orderInfoModal">
            <DialogTitle>
                <IconButton 
                    style={{ position: 'absolute', top: '5px', right: '5px' }}
                    onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
                {t("orderInformation")}
            </DialogTitle>
            {orderInfoView()}
            <DialogActions>
                <Button 
                    onClick={() => setUpdateHistory(!updateHistory)} 
                    color="primary">
                    {t("update")}
                </Button>
                <Button onClick={handleClose} color="primary" autoFocus>
                    {t("close")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};