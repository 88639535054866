import React, { Dispatch, useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { actions, RootState } from '../../store';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { 
    Button, CircularProgress, Dialog, DialogActions, 
    DialogContent, Grid, InputLabel, Select, MenuItem,
    DialogTitle, IconButton, FormControl, Chip, Box
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import { useTranslation } from 'react-i18next';
import { errorCallback, SystemEvent } from '../../RemoteCommands/SystemEvent';
import { CarInfoParams, ICarInfo, PropertyShort, PropertyValueSet, PropertyValuesSet } from '../../RemoteCommands/type';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import PropertiesModal from '../PropertiesModal/PropertiesModal';
import EditIcon from '@material-ui/icons/Edit';
import CarDeleteModal from './CarDeleteModal';
import { EventSuccess } from '../../store/event/types';
import './DriverProfile.scss';

type Props = ReturnType<typeof mapStateToProps> 
    & ReturnType<typeof mapDispatchToProps> & {
    open: boolean;
    handleClose: () => void;
    carIds?: number[];
    employeeId: number;
}

const DriverProfile:React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const {
        open,
        handleClose,
        carIds,
        propertyValueSetAction,
        currentPropertyValueSetAction,
        currentPropertyValueSet,
        employeeId,
        cars,
        currentCar,
        currentCarAction,
        carsAction,
        eventSuccessAction
    } = props;

    const initialCarInfo = {
        carId: -1,
        carNumber: "",
        driverIds: [employeeId],
        emptyWeight: 0,
        maxWeight: 0,
        propertyValueSetId: -1,
        carCategory: null,
        registrationNumber: null,
        vinNumber: null,
    }

    const [loading, setLoading] = useState(false);
    const [propertyOpen, setPropertyOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [addCar, setAddCar] = useState(false);
    const [carNumber, setCarNumber] = useState("");
    const [properties, setProperties] = useState<PropertyShort[]>([]);
    const eventKey = useRef<any>(null);

    const handlePropertyOpen = () => {
        setAddCar(false);
        setPropertyOpen(true);
    }

    const handlePropertyClose = () => {
        setPropertyOpen(false);
    }

    const deleteModalOpen = () => {
        setDeleteOpen(true);
    }

    const deleteModalClose = () => {
        setDeleteOpen(false);
    }

    const linkCarClick = () => {
        setAddCar(true);
        setPropertyOpen(true);
    }

    useEffect(() => {
        setLoading(true);
        SystemEvent.SubscribeEventAddCar(
            "DriverProfile", 
            (answer) => {
                SystemEvent.EventGetEmployeeDetailedInfo(employeeId);
                const newCarIds: number[] = carIds ? [...carIds, answer.carId] : [answer.carId];
                const payload: CarInfoParams = {
                    carIds: newCarIds
                }
                SystemEvent.EventGetCarInfo(payload);
                handlePropertyClose();
                eventSuccessAction({
                    message: t("addedCarSuccessfully"),
                    success: true
                });
            }, 
            (error) => {
                errorCallback(error);
            }
        );
        SystemEvent.SubscribeEventSetCarInfo(
            "DriverProfile", 
            (answer) => {   
                handlePropertyClose();
            }, 
            (error) => {
                errorCallback(error);
            }
        );
        SystemEvent.SubscribeEventGetPropertyValueSets(
            "DriverProfile", 
            (answer) => {
                if(open) {
                    const objProperty = Object.assign({}, ...answer.propertyValueSets);
                    currentPropertyValueSetAction(objProperty);
                } else {
                    propertyValueSetAction(answer.propertyValueSets);
                }
            }, 
            (error) => {
                errorCallback(error);
            }
        );

        SystemEvent.SubscribeEventGetCarInfo(
            "DriverProfile", 
            (answer) => {
                carsAction(answer.cars);
                currentCarAction(answer.cars[0]);
                if(answer.cars.length === 0) {
                    linkCarClick();
                }
                setTimeout(() => {
                    setLoading(false);
                }, 300);
            }, 
            (error) => {
                setLoading(false);
                errorCallback(error);
            }
        );
        const payload: CarInfoParams = {
            carIds
        }
        SystemEvent.EventGetCarInfo(payload);
    }, []);

    useMemo(() => {
        SystemEvent.SubscribeEventSavePropertyValueSet(
            "DriverProfile", 
            (answer) => {
                if(answer.eventKey === eventKey.current) {
                    if(currentCar) {
                        const сarInfo: ICarInfo = {
                            ...currentCar,
                            propertyValueSetId: answer.propertyValueSetId
                        }
                        SystemEvent.EventSetCarInfo(сarInfo);
                        SystemEvent.EventGetPropertyValueSets([answer.propertyValueSetId]);
                        driverProfileColse()
                    }
                }
            }, 
            (error) => {
                errorCallback(error);
            }
        );
        if(currentCar) {
            setCarNumber(currentCar.carNumber);
            SystemEvent.EventGetPropertyValueSets([currentCar.propertyValueSetId]);
        }
    }, [currentCar]);

    const handleCarChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const value = event.target.value as string;
        const car = cars.find(item => item.carNumber === value);
        currentCarAction(car);
    }

    const handleDelete = (chipToDelete: PropertyValuesSet, values: PropertyValuesSet[]) => () => {
        let propertyShort: PropertyShort[] = [];
        const newProperties = values.filter((chip) => chip.propertyId !== chipToDelete.propertyId);
        for(let property of newProperties) {
            propertyShort.push({
                propertyId: property.propertyId,
                propertyValueId: property.propertyValueId
            })
        }
        currentPropertyValueSetAction({
            ...currentPropertyValueSet!,
            values: newProperties
        });
        setProperties(propertyShort);
    };

    const driverProfileColse = () => {
        handleClose();
        currentCarAction(undefined);
        currentPropertyValueSetAction(undefined);
        carsAction([]);
    }

    const saveDriverProfile = () => {
        if(properties.length > 0) {
            eventKey.current = SystemEvent.EventSavePropertyValueSet(properties);
        } else {
            driverProfileColse();
        }
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={driverProfileColse}
                fullWidth
                maxWidth="sm"
                className="driver-profile">
                <DialogTitle>
                    <IconButton 
                        style={{ position: 'absolute', top: '5px', right: '5px' }}
                        onClick={driverProfileColse}>
                        <CloseIcon />
                    </IconButton>
                    {t("driverProfile")}
                </DialogTitle>
                <DialogContent>
                    {loading ?
                        <Box className="loading loading_vertical-center" minHeight={200}>
                            <CircularProgress />
                        </Box>
                        :
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id="number-car-label">{t("numberOfTheCar")}</InputLabel>
                                    <Select
                                        labelId="number-car-label"
                                        value={carNumber}
                                        onChange={handleCarChange}>
                                        {cars.map(car => (
                                            <MenuItem 
                                                key={car.carId} 
                                                value={car.carNumber}>{car.carNumber}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <div className="tariff-filter">
                                    <label className="tariff-filter__label">{t("machineProperties")}</label>
                                    <div className="tariff-filter__row">
                                        {currentPropertyValueSet && currentPropertyValueSet.values.map((property) => {
                                            let icon;
                                            return (
                                            <li key={property.propertyId}>
                                                <Chip
                                                    icon={icon}
                                                    label={`${property.propertyLocalization}: ${property.value}`}
                                                    onDelete={handleDelete(property, currentPropertyValueSet.values)}
                                                    size="small"
                                                />
                                            </li>
                                            );
                                        })}       
                                    </div>
                                    {currentPropertyValueSet && 
                                        <IconButton 
                                            className="tariff-filter__edit"
                                            onClick={handlePropertyOpen}>
                                            <EditIcon fontSize="small" />
                                        </IconButton>
                                    }
                                </div>
                            </Grid>
                        </Grid>
                    }
                </DialogContent>
                <DialogActions>
                    <Button 
                        variant="outlined" 
                        color="primary"
                        disableElevation
                        startIcon={<LinkIcon />}
                        onClick={linkCarClick}>
                        {t("bindThecar")}
                    </Button>
                    <Button 
                        variant="outlined" 
                        color="primary"
                        disableElevation
                        disabled={currentCar ? false : true}
                        startIcon={<LinkOffIcon />}
                        onClick={deleteModalOpen}>
                        {t("untieTheCar")}
                    </Button>
                    <Button 
                        className="mr-2" 
                        variant="contained" 
                        color="secondary"
                        disableElevation
                        onClick={driverProfileColse}>
                        {t("cancel")}
                    </Button>
                    <Button
                        variant="contained" 
                        color="primary"
                        disableElevation
                        startIcon={false && <CircularProgress size={16} color="secondary" />}
                        onClick={saveDriverProfile}>
                        {t("save")}
                    </Button>
                </DialogActions>
            </Dialog>
            {propertyOpen && 
                <PropertiesModal 
                    open={open} 
                    handleClose={handlePropertyClose} 
                    currentPropertyValues={!addCar ? currentPropertyValueSet ? currentPropertyValueSet.values : [] : []} 
                    defaultCar={!addCar ? currentCar : initialCarInfo} 
                    addCar={addCar} />
            }
            {deleteOpen && currentCar &&  
                <CarDeleteModal 
                    open={deleteOpen} 
                    handleClose={deleteModalClose} 
                    currentCar={currentCar} 
                    driverId={employeeId} 
                    cars={cars}
                    carsAction={carsAction} 
                    currentPropertyValueSetAction={currentPropertyValueSetAction} 
                    currentCarAction={currentCarAction} />
            }
        </>
    );
}

const mapStateToProps = (state: RootState) => {
    return {
        selectedAddress: state.event.selectedAddress,
        employeeRoles: state.event.employeeRoles,
        shortGroups: state.event.shortGroups,
        currentPropertyValueSet: state.event.currentPropertyValueSet,
        cars: state.event.cars,
        currentCar: state.event.currentCar
    }
};

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    addressValueAction: (addressValue: string) => 
        dispatch(actions.event.addressValueAction(addressValue)),
    propertyValueSetAction: (propertyValueSet: PropertyValueSet[]) => 
        dispatch(actions.event.propertyValueSetAction(propertyValueSet)),
    currentPropertyValueSetAction: (propertyValueSet: PropertyValueSet | undefined) => 
        dispatch(actions.event.currentPropertyValueSetAction(propertyValueSet)),
    carsAction: (cars: ICarInfo[]) => 
        dispatch(actions.event.carsAction(cars)),
    currentCarAction: (car: ICarInfo | undefined) => 
        dispatch(actions.event.currentCarAction(car)),
    eventSuccessAction: (eventSuccess: EventSuccess | null) => 
        dispatch(actions.event.eventSuccessAction(eventSuccess)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DriverProfile);