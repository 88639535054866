import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { IActiveOrders, IShortEmployeesInfo } from '../../RemoteCommands/type';
import { Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import RoleMultipleAutocomplete from '../bricks/RoleMultipleAutocomplete';
import { EmployeeRole } from '../../store/event/types';

type Props = {
    activeOrder: IActiveOrders;
    open: boolean;
    handleClose: () => void;
    currentRoles: EmployeeRole[];
    onCurrentRoleChange: (roles: EmployeeRole[]) => void;
    currentEmploee: IShortEmployeesInfo | null;
    handleEmploeeChange: (currentEmploee: IShortEmployeesInfo) => void;
    handleSeachChange: (value: string) => void;
    shortEmployeesInfo: IShortEmployeesInfo[];
    assignDriverClick: () => void;
};

export function AssignDriver(props: Props) {
    const { t } = useTranslation();
    const { 
        activeOrder,
        open,
        handleClose,
        currentRoles,
        onCurrentRoleChange,
        currentEmploee,
        handleSeachChange,
        handleEmploeeChange,
        shortEmployeesInfo,
        assignDriverClick
    } = props;
    
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            className="assignDriver"
        >
            <DialogTitle>
                {t("selectOrder")} #{activeOrder.orderId}
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <RoleMultipleAutocomplete 
                            currentRoles={currentRoles} 
                            handleRole={onCurrentRoleChange} 
                            variant="outlined" 
                            label={t("filterByRole")} 
                            edit={true} />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            options={shortEmployeesInfo}
                            getOptionLabel={(option) => `${option.firstName} ${option.secondName}`}
                            onChange={(event: React.ChangeEvent<{}>, currentEmploee: any) => handleEmploeeChange(currentEmploee)}
                            onInputChange={(event: React.ChangeEvent<{}>, changeValue: string) => handleSeachChange(changeValue)}
                            value={currentEmploee}
                            renderInput={(params) => 
                                <TextField 
                                    {...params} 
                                    label={t("search")} 
                                    variant="outlined"
                                    size="small"
                                    fullWidth />
                            }
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button 
                    color="primary"
                    onClick={() => assignDriverClick()} 
                >
                    ОК
                </Button>
                <Button onClick={handleClose} color="primary" autoFocus>
                    {t("cancel")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};