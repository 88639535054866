export function convertDatesInObjectToIso(source: any) : any{
    if (source === undefined || source === null){
        throw new Error('Invalid patameters');
    }

    let result: any = {};

    Object.entries(source)
        .forEach(([objectKey, objectValue]) => {
            let convertedValue;

            if (objectValue instanceof Date){
                convertedValue = convertDateToParamAsIsoDate(objectValue as Date);
            }
            else {
                convertedValue = objectValue;
            }

            result[objectKey] = convertedValue;
        });

    return result;
}

export function convertDateToParamAsIsoDate(dateTime: Date): string {
    return dateTime.toISOString().replace(/\..*/gi, '');

    // const pad = '0';
    // const year = padLeft(dateTime.getFullYear().toString(), pad, 4);
    // // ! нумерация месяцев начинается с нуля
    // const month = padLeft((dateTime.getMonth() + 1).toString(), pad, 2);
    // const date = padLeft(dateTime.getDate().toString(), pad, 2);
    // const hours = padLeft(dateTime.getHours().toString(), pad, 2);
    // const minutes = padLeft(dateTime.getMinutes().toString(), pad, 2);
    // const seconds = padLeft(dateTime.getSeconds().toString(), pad, 2);
    // //const ms = padLeft(dateTime.getMilliseconds().toString(), pad, 3);

    // //return `${year}-${month}-${date}T${hours}:${minutes}:${seconds}.${ms}`;
    // return `${year}-${month}-${date}T${hours}:${minutes}:${seconds}`;
}

// function padLeft(value: string, pad: string, maxLength: number) {
//     if (value.length >= maxLength || maxLength <= 0 || pad.length <= 0) {
//         return value;
//     }

//     while (value.length < maxLength) {
//         value = pad + value;
//     }

//     return value;
// }