import { Dispatch, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect, BrowserRouter } from 'react-router-dom';
import { RouteComponentProps, withRouter } from 'react-router';
import { actions, RootState } from '../../store';
import { AuthorizationContainer } from '../Authorization';
import { MapContainer } from '../Map';
import { MenuContainer } from '../Menu';
import { IconButton, Paper, Popover, Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import SipPhone from '../SipPhone/SipPhone';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction, Action } from 'redux';
import { LocalizeContextProps, withLocalize } from 'react-localize-redux';
import { localStorageGetItem } from '../../utils/storage';
import { renderToStaticMarkup } from "react-dom/server";
import { errorCallback, SystemEvent } from '../../RemoteCommands/SystemEvent';
import { GetSettings, UpdateGpsData } from '../../RemoteCommands/type';
import WidgetPanel from '../WidgetPanel/WidgetPanel';
import PortalToast from '../portal/components/PortalToast';
import { SettingsContainer } from '../Settings';
import { AccessList } from '../../utils/AccessList';
import './App.scss';

type Props = RouteComponentProps<{}> & ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps> & LocalizeContextProps;

const App:React.FC<Props> = (props) => {
    const {
        sipDisconnectedError,
        initializeSip,
        initialize,
        addTranslationForLanguage,
        loadTranslations,
        translationsLoaded,
        translationsLoading,
        translations,
        username,
        sipLoginAction,
        hasActiveCall,
        updateGpsDataAction,
        identificationInfo,
        langAction
    } = props;

    const isAuthorized = identificationInfo && identificationInfo.userKey ? true : false;

    const [phonePopover, setPhonePopover] = useState<HTMLButtonElement | null>(null);
    const [getSettings, setGetSettings] = useState<GetSettings | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setPhonePopover(event.currentTarget)
    };

    const handleClose = () => {
        setPhonePopover(null)
    };

    const addTranslations = (lang:string) => {
        let texts = translations.filter(tr => tr.language === lang);
        let srt: { [id: string]: string; } = {};
        for (let i = 0; i < texts.length; i++)
            srt[texts[i].key] = texts[i].text;
        addTranslationForLanguage(srt, lang);
    }

    useEffect(() => {
        let lang = localStorageGetItem("lang");
        if (lang === null)
            lang = "ru";
        initialize(({
            languages: [
                { name: "En", code: "en" },
                { name: "Ру", code: "ru" }
            ],
            translation: [],
            options: {
                renderToStaticMarkup,
                renderInnerHtml: true,
                defaultLanguage: lang
            }
        }) as any);
    }, []);

    useEffect(() => {
        if (!translationsLoaded && !translationsLoading) {
            loadTranslations();
        }
    }, [translationsLoaded, translationsLoading])

    useEffect(() => {
        addTranslations("ru");
        addTranslations("en");
    }, [translationsLoaded]);

    const getSettingsInitializ = (isAuthorized: boolean) => {
        SystemEvent.SubscribeEventGetSettings(
            "EventUserIdentification", 
            (answer) => {
                setGetSettings(answer);
                sipLoginAction(answer.sipLogin)
            }, 
            (error) => {
                errorCallback(error);
            }
        );
        if(isAuthorized) {
            SystemEvent.EventGetSettings();
        }
    }

    useEffect(() => {
        getSettingsInitializ(isAuthorized);
        langAction(localStorageGetItem("i18nextLng") || "en");
        if(identificationInfo) {
            AccessList.initialization(identificationInfo.availableApplicationFunctions);
        }
    }, [isAuthorized, langAction]);

    useEffect(() => {
        if(getSettings) {
            initializeSip(getSettings.sipLogin, getSettings.sipPassword, username);
        }
    }, [getSettings]);

    useEffect(() => {
        // SystemEvent.SubscribeEventUpdateGpsData3(
        //     "app", 
        //     (answer) => {
        //         updateGpsDataAction(answer.eventParameters);
        //     }, 
        //     (error) => {
        //         errorCallback(error);
        //     }
        // );

        SystemEvent.SubscribeEventExecutorLocationChanged(
            "app", 
            (answer) => {
                console.log("EventExecutorLocationChanged:", answer);
                updateGpsDataAction(answer.eventParameters);
            }, 
            (error) => {
                errorCallback(error);
            }
        );

        SystemEvent.SubscribeEventOrderStatusChanged(
            "app", 
            (answer) => {
                console.log("EventOrderStatusChanged:", answer);
                // const index = activeOrders.findIndex((listItem) => listItem.orderId === Number(answer.orderId));
                // const activeOrder = activeOrders.find(item => item.orderId === Number(answer.orderId));
                // console.log("activeOrder", activeOrder, index, answer.orderId)
                // if(activeOrder) {
                //     const newList = replaceItemAtIndex(activeOrders, index, {
                //         ...activeOrder,
                //         orderStatusKey: answer.orderStatus,
                //         orderStatusText: answer.orderStatus
                //     });
                //     console.log("newList", newList);
                //     activeOrdersAction(newList);
                // }
                SystemEvent.EventGetActiveOrdersForDispatcher();
            }, 
            (error) => {
                errorCallback(error);
            }
        )
    });

    if(!isAuthorized) {
        return (
            <div className="wrapper">
                <AuthorizationContainer />
            </div>
        )
    }

    return (
        <div className="wrapper">
            <BrowserRouter>
                <MenuContainer />
                <Switch>
                    <Route 
                        path="/" 
                        render={() => <Redirect to="/map" />} 
                        exact />
                    <Route 
                        path="/map" 
                        component={MapContainer} 
                        exact />
                    <Route 
                        path="/settings" 
                        component={SettingsContainer}  />
                </Switch>
            </BrowserRouter>
            {sipDisconnectedError ?
                <Tooltip title="Звонок не доступен" placement="top">
                    <button 
                        className={`call-phone is-animating ${phonePopover !== null ? 'call-close' : ''}`} 
                        style={sipDisconnectedError ? {backgroundColor: "red"} : {}}
                    />
                </Tooltip>
            :
                <button 
                    className={`call-phone is-animating ${phonePopover !== null ? 'call-close' : ''}`} 
                    onClick={handleClick}
                />
            }
            <Popover
                open={Boolean(phonePopover)}
                anchorEl={phonePopover}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Paper className="call-block">
                    <IconButton aria-label="delete" style={{ position: 'absolute', top: '5px', right: '5px' }}
                        onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                    <SipPhone></SipPhone>
                </Paper>
            </Popover>
            {/* чтобы компоненты внутри toast имели доступ к store и Router */}
            <div className="widget-panel-task" style={{position: 'relative'}}>
                <WidgetPanel 
                    positionPanel="right" 
                    settingsKey="phoneToggle"
                    hasActiveCall={hasActiveCall}>
                    {/* <ToastContainer /> */}
                    <PortalToast />
                    {/* <div style={{width: '325px'}}></div> */}
                </WidgetPanel>
            </div>
        </div>
    );
}

const mapStateToProps = (state: RootState) => ({
    sipDisconnectedError: state.sip.sipDisconnectedError,
    translationsLoaded: state.translations.translationsLoaded,
    translationsLoading: state.translations.translationsLoading,
    translations: state.translations.translations,
    username: state.sip.username,
    hasActiveCall: state.sip.hasActiveCall,
    identificationInfo: state.user.identificationInfo
});

const mapDispatchToProps = (
    dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>
  ) => ({
    initializeSip: (login:string, pass:string, username:string) => 
        dispatch(actions.sip.initializeSipActionCreator(login, pass, username)),
    loadTranslations: () => 
        dispatch(actions.translations.fetchTranslationsActionCreator()),
    sipLoginAction: (sipLogin: string) => 
        dispatch(actions.sip.sipLoginAction(sipLogin)),
    updateGpsDataAction: (updateGpsData: UpdateGpsData | null) => 
        dispatch(actions.event.updateGpsDataAction(updateGpsData)),
    langAction: (lang: string) => 
        dispatch(actions.app.langAction(lang)),
});

export default withLocalize(withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(App)));

// function replaceItemAtIndex(arr: IActiveOrders[], index: number, newValue: IActiveOrders) {
//     return [...arr.slice(0, index), newValue, ...arr.slice(index + 1)];
// }
  