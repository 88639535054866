import React from 'react';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

type Props = {
    open: boolean;
    handleClose: () => void;
};

export function RequirementsCarsModal(props: Props) {
    const { t } = useTranslation();
    const {
        open,
        handleClose
    } = props;

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth="xs"
        >
            <DialogTitle onClose={handleClose}>
                Требования к авто
            </DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControl
                            fullWidth
                            size="small">
                            <InputLabel id="demo-simple-select-label">Выберите тип авто</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={10}
                            >
                                <MenuItem value={10}>Легковое такси</MenuItem>
                                <MenuItem value={20}>VIP такси</MenuItem>
                                <MenuItem value={30}>Грузовое такси</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl
                            fullWidth
                            size="small">
                            <InputLabel id="demo-simple-select-label">Количество мест</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={""}
                                type="number"
                            >
                                <MenuItem value={10}>4</MenuItem>
                                <MenuItem value={20}>5</MenuItem>
                                <MenuItem value={30}>6</MenuItem>
                                <MenuItem value={30}>7</MenuItem>
                                <MenuItem value={30}>8</MenuItem>
                                <MenuItem value={30}>9</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl
                            fullWidth
                            size="small">
                            <InputLabel id="demo-simple-select-label">Марка машины</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={""}
                                type="number"
                            >
                                <MenuItem value={10}>4</MenuItem>
                                <MenuItem value={20}>5</MenuItem>
                                <MenuItem value={30}>6</MenuItem>
                                <MenuItem value={30}>7</MenuItem>
                                <MenuItem value={30}>8</MenuItem>
                                <MenuItem value={30}>9</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl
                            fullWidth
                            size="small">
                            <InputLabel id="demo-simple-select-label">Иномарка</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={""}
                                type="number"
                            >
                                <MenuItem value={10}>4</MenuItem>
                                <MenuItem value={20}>5</MenuItem>
                                <MenuItem value={30}>6</MenuItem>
                                <MenuItem value={30}>7</MenuItem>
                                <MenuItem value={30}>8</MenuItem>
                                <MenuItem value={30}>9</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl
                            fullWidth
                            size="small">
                            <InputLabel id="demo-simple-select-label">Тип кузова</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={""}
                                type="number"
                            >
                                <MenuItem value={10}>4</MenuItem>
                                <MenuItem value={20}>5</MenuItem>
                                <MenuItem value={30}>6</MenuItem>
                                <MenuItem value={30}>7</MenuItem>
                                <MenuItem value={30}>8</MenuItem>
                                <MenuItem value={30}>9</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl
                            fullWidth
                            size="small">
                            <InputLabel id="demo-simple-select-label">Цвет</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={""}
                                type="number"
                            >
                                <MenuItem value={10}>4</MenuItem>
                                <MenuItem value={20}>5</MenuItem>
                                <MenuItem value={30}>6</MenuItem>
                                <MenuItem value={30}>7</MenuItem>
                                <MenuItem value={30}>8</MenuItem>
                                <MenuItem value={30}>9</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {t("cancel")}
                </Button>
                <Button onClick={handleClose} color="primary" autoFocus>
                    Ок
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const styles = (theme: Theme) =>
    createStyles({
        root: {
        margin: 0,
        padding: theme.spacing(2),
        },
        closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
        },
});

export interface DialogTitleProps extends WithStyles<typeof styles> {
  children: React.ReactNode;
  onClose: () => void;
}


const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <CloseIcon />
            </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
  });
  