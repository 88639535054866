
import { Dispatch, useEffect } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { RootState, actions } from '../../store';
import { connect } from 'react-redux';import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { errorCallback, SystemEvent } from '../../RemoteCommands/SystemEvent';
import { ShortGroups } from '../../RemoteCommands/type';

type Props = 
    ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps> & {
    currentGroup: ShortGroups | null;
    availableGroups?: number[];
    handleGroup: (currentGroup: ShortGroups | null) => void;
    error?: boolean;
    variant?: "standard" | "filled" | "outlined";
    edit?: boolean;
};

function GroupAutocomplete(props: Props) {
    const { t } = useTranslation();
    const { 
        handleGroup, error, shortGroups, shortGroupsAction, 
        openTariffs, variant, currentGroup, edit, availableGroups 
    } = props;

    useEffect(() => {
        SystemEvent.SubscribeEventGetGroups(
            "GroupAutocomplete", 
            (answer) => {
                shortGroupsAction(answer.shortGroups);
            }, 
            (error) => {
                errorCallback(error);
            }
        );
        SystemEvent.EventGetGroups("");
    }, []);

    const groupInputChange = (value: string) => {
        const groupsEmpty = shortGroups.some((group) => {
            return group.name.toLowerCase().search(value.toLowerCase()) != -1;
        });
        if(shortGroups.length >= 200 || !groupsEmpty || value.length === 0) {
            SystemEvent.EventGetGroups(value)
        }
    }

    const groupOnChange = (changeValue: ShortGroups | null) => {
        handleGroup(changeValue);
    }

    const groups = shortGroups.filter(item => availableGroups ? availableGroups.indexOf(item.id) > -1 : shortGroups);
    
    return (
        <Autocomplete
            fullWidth
            options={groups}
            getOptionLabel={(option: ShortGroups) => option.name}
            value={currentGroup}
            onChange={(event: React.ChangeEvent<{}>, changeValue: ShortGroups | null) => groupOnChange(changeValue)}
            onInputChange={(event: React.ChangeEvent<{}>, value: string) => groupInputChange(value)}
            openOnFocus={openTariffs}
            disabled={edit}
            renderInput={(params) => 
                <TextField 
                    {...params} 
                    variant={variant}
                    size="small" 
                    label={t("group")}
                    error={error}
                    helperText={error ? t("isRequired") : undefined} 
                    autoFocus={openTariffs} />
            }
        />
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        shortGroups: state.event.shortGroups,
        openTariffs: state.tariffs.openTariffs
    }
};

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({ 
    shortGroupsAction: (shortGroups: ShortGroups) => 
        dispatch(actions.event.shortGroupsAction(shortGroups)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupAutocomplete);