import moment from "moment"
import { AavailableApplication } from "../utils/AccessList"

export interface EventError {
    errorMessage: string;
    errorCode: string;
}

export interface IEventUserIdentification {
    isNewUser: boolean;
    userKey: string;
    userName: string;
    needVerify: boolean;
    availableApplicationFunctions: AavailableApplication[];
}
export type EventGetActiveEmployees = {
    activeEmployees: IActiveEmployees[]
}
export interface IActiveEmployees {
    id: number;
    name: string;
    role: string;
    workMode: WorkMode;
    geoPoint: GeoPoint;
    azimuth: number;
}

export type GeoPoint = {
    latitude: number;
    longitude: number;
}

// export enum WorkMode {
//     Online = 0,
//     Offline = 1,
//     Web = 2,
//     Mixed = 3,
//     Botline = 4, //Это режим работы через бот агента - телеграм
//     Pushline = 5, //Онлайн водители с которыми временно нет связи по сокету, данные отправляются через PUSH
// }

export type GetSettings = {
    sipLogin: string;
    sipPassword: string;
}

export type Period = {
    Year: number;
    Month: number; 
    YearTo?: number;
    MonthTo?: number;
}
export type EmployeeFilter = {
    textFilter?: string;
    roles?: string[] | null;
    workMode?: WorkMode;
}

export enum EmployeeRole {
    SalesManager = "SalesManager",
    SalesDirector = "SalesDirector",
    DataOperator = "DataOperator"
}

export enum WorkMode {
    All = "",
    Online = "Online",
    Offline = "Offline",
    Web = "Web",
    Mixed = "Mixed",
    Botline = "Botline",
    Pushline = "Pushline",
}

export type GetEmployeesShortInfo = {
    shortEmployeesInfo: IShortEmployeesInfo[]
}

export interface IShortEmployeesInfo {
    employeeId: number;
    firstName: string;
    roles: EmployeesRoles[];
    secondName: string;
    sipAccount: string | null;
    workMode: number;
}

export type EmployeesRoles = {
    groupId: number;
    role: number;
    roleAllowed: boolean;
}

export type GetEmployeeDetailedInfo = {
    detailedEmployeeInfo: IDetailedEmployeeInfo;
}

export interface IDetailedEmployeeInfo {
    employeeId: number;
    login: string;
    firstName: string;
    secondName: string;
    gender: boolean;
    email: string;
    notes: any;
    phones: Phones[];
    roles: EmployeeInfoRole[];
    address: EmployeeInfoAddress | null;
    photoImageId?: number;
    workMode?: number;
    birthDate?: any;
    documentIds?: number[];
    driverInfo?: DriverInfo;
}

export type DriverInfo = {
    carIds: number[];
}

export type Phones = {
    number: string;
    phoneType: string;
}

export type EmployeeInfoAddress = {
    addressString?: string | null;
    apartmentNumber?: string;
    areaDistrictName?: string;
    areaName?: string;
    cityId?: number;
    cityName?: string;
    countryName?: string;
    entranceNumber?: number;
    geoPoint?: EmployeeInfoGeoPoint;
    houseNumber?: string;
    id?: number;
    localityName?: string;
    nearestPoint?: string;
    objectName?: string;
    postalCode?: string;
    precision?: number;
    streetName?: string;
}

export type EmployeeInfoGeoPoint = {
    latitude: number;
    longitude: number;
}

export type EmployeeInfoRole = {
    groupId: number;
    groupName: string;
    role: string;
    localizedRole: string;
    roleAllowed: boolean;
}

export interface IDetailedEmployeeInfo {
    employeeId: number;
    firstName: string;
    secondName: string;
    gender: boolean;
    email: string;
    notes: any;
    phones: Phones[];
    roles: EmployeeInfoRole[];
    address: EmployeeInfoAddress | null;
    photoImageId?: number;
    workMode?: number;
    birthDate?: any;
    documentIds?: number[];
    driverInfo?: DriverInfo;
}

export type GetActiveOrdersForDispatcher = {
    activeOrders: IActiveOrders[]
}
export interface IActiveOrders {
    orderId: number;
    customerName: string;
    route: Route[],
    orderStatusKey: string;
    orderStatusText: string;
    cost: number;
    executorName: string;
    carInfo: string | null;
    orderTime: string;
    ownerGroupId: number;
    ownerGroupName: string;
    executorGroupId: number;
    executorGroupName: string;
}

export interface IOrderInfo {
    basicTariffDescription: string;
    carInfo: string;
    cost: number;
    customerId: number;
    customerName: string;
    executorGroupId: number | null;
    executorGroupName: string | null;
    executorName: string;
    note: string;
    orderId: number;
    orderPropertiesText: string;
    orderStatus: string;
    orderStatusText: string;
    orderTime: string;
    orderType: string;
    orderTypeText: string;
    ownerGroupId: number;
    ownerGroupName: string;
    paymentManner: string;
    paymentMannerText: string;
    route: Route[];
}

export interface Route {
    city?: string;
    street?: string;
    object?: string;
    houseNumber?: string;
    geoPoint?: GeoPoint;
}

export type GetHistoryEvents = {
    historyEvents: HistoryEvents[];
}

export type HistoryEvents = {
    color: string;
    eventText: string;
}

export type UpdateGpsData = {
    accuracy: string
    activeSatellites: string
    azimuth: string
    changeLocationSource: string
    employeeId: string
    gpsDate: string
    gpsStatus: string
    latitude: string
    longitude: string
    speed: string
}

export type RegisterOrder = {
    costCalculationId?: number;
    isCashlessOrder?: boolean;
    referenceCardId?: number;
    customerPhone: string;
    customerName?: string;
    customerId?: number;
    groupId?: number;
    companyId?: number;
    comment?: string;
    auctionNominalPercent?: string;
    ownerChargePart?: string;
    ownerChargeSum?: string;
}
export type CalculateCost = {
    orderTime?: string;
    specialServices?: string;
    isCashless?: boolean;
    keyWord?: string;
    route?: Route[];
    groupId?: number;
}

export type EventCalculateCost = {
    calculatedCosts: CalculatedCosts[];
    routeTotalDistance: number;
    routeTotalDuration: number;
    routeTrack: string;
}

export type CalculatedCosts = {
    cost: number;
    costCalculationId: number;
    currency: string;
    tariffDescription: string;
}

export interface ShortGroups {
    id: number;
    name: string;
}
export type SpecialServices = {
    cost: number;
    displayName: string;
    sequenceNumber: number;
    serviceName: string;
}

export type EventGetTariffs = {
    tariffs: Tariff[];
}

export interface ITariff {
    tariffId: number;
    basicTariffId: number;
    description: string;
    filterId?: number;
    groupId: number;
    fullTariff: boolean;
    mainCosts: MainCost[];
    minimalCosts: IMinimalCost[] | null;
    rateAllowances: RateAllowance[];
    roundings: Rounding[];
    waitings: Waiting[];
}

export class Tariff implements ITariff {
    tariffId: number;
    basicTariffId: number;
    description: string;
    filterId?: number;
    groupId: number;
    fullTariff: boolean;
    mainCosts: MainCost[];
    minimalCosts: IMinimalCost[];
    rateAllowances: RateAllowance[];
    roundings: Rounding[];
    waitings: Waiting[];


    constructor(source?: ITariff) {
        this.tariffId = source ? source.tariffId : -1;
        this.basicTariffId = source ? source.basicTariffId : -1;
        this.description = source ? source.description : '';
        this.groupId = source ? source.groupId : 0;
        this.fullTariff = source ? source.fullTariff : false;
        this.mainCosts = source && source.mainCosts ? source.mainCosts : [];
        this.minimalCosts = source && source.minimalCosts ? source.minimalCosts : [];
        this.rateAllowances = source && source.rateAllowances ? source.rateAllowances : [];
        this.roundings = source && source.roundings ? source.roundings : [];
        this.waitings = source && source.waitings ? source.waitings : [];
        this.filterId = source ? source.filterId : undefined;
    }
}

export interface TariffParameter {
    rowId: number;
    basicTariffId: number;
    forExecutorTariff?: boolean;
    mainCost?: MainCost;
    minimalCost?: IMinimalCost;
    rateAllowance?: RateAllowance;
    rounding?: Rounding;
    waiting?: Waiting;
}

export interface ITariffParameterId {
    rowId: number;
    eventKey?: string; // Ключ необходимый для получения rowId после сохранения тарифа
}

export type TimeRange = {
    fromTime: string;
    toTime: string;
    rangeType: TimeRangeType;
    isEnabled: boolean;
}

export enum TimeRangeType {
    time = "Time",
    dateTime = "DateTime",
    dayOfWeek = "DayOfWeek"
}
export interface IMinimalCost extends ITariffParameterId  {
    cost: number;
    includedKilometers: IncludedValueParameter;
    includedMinutes: IncludedValueParameter;
    includedHours: any;
    timeRange: TimeRange | null;
    distanceRange: any;
    holidayParameter: HolidayParameter | null;
    dayOfWeekParameter: DayOfWeekParameter | null;
    geoPointParameter: any;
    geoPointGroupParameter: any;
    geozoneParameter: any;
    cityIdParameter: any;
    priorityParameter: any;
}

export const initialTimeRane: TimeRange = {
    fromTime: moment(new Date()).format("YYYY-MM-DDT00:00"),
    toTime: moment(new Date()).format("YYYY-MM-DDT00:00"),
    rangeType: TimeRangeType.time, 
    isEnabled: true
}
export class MinimalCost implements IMinimalCost {
    rowId: number;
    cost: number;
    includedKilometers: IncludedValueParameter;
    includedMinutes: IncludedValueParameter;
    includedHours: any;
    timeRange: TimeRange | null;
    distanceRange: any;
    holidayParameter: any;
    dayOfWeekParameter: any;
    geoPointParameter: any;
    geoPointGroupParameter: any;
    geozoneParameter: any;
    cityIdParameter: any;
    priorityParameter: any;

    private includedValueParameter: IncludedValueParameter = {
        isEnabled: true,
        value: 0,
    }

    constructor(source?: IMinimalCost) {
        this.rowId = source ? source.rowId : 0;
        this.cost = source ? source.cost : 0;
        this.includedKilometers = source ? source.includedKilometers : this.includedValueParameter;
        this.includedMinutes = source ? source.includedMinutes : this.includedValueParameter;
        this.includedHours = source ? source.includedHours : null;
        this.timeRange = source ? source.timeRange : null;
        this.distanceRange = source ? source.distanceRange : null;
        this.holidayParameter = source ? source.holidayParameter : null;
        this.dayOfWeekParameter = source ? source.dayOfWeekParameter : null;
        this.geoPointParameter = source ? source.geoPointParameter : null;
        this.geoPointGroupParameter = source ? source.geoPointGroupParameter : null;
        this.geozoneParameter = source ? source.geozoneParameter : null;
        this.cityIdParameter = source ? source.cityIdParameter : null;
        this.priorityParameter = source ? source.priorityParameter : null;
    }
}

export const initialMinCost: IMinimalCost = {
    rowId: 0,
    cost: 0,
    includedKilometers: {isEnabled: true, value: 0},
    includedMinutes: {isEnabled: true,value: 0},
    includedHours: null,
    timeRange: null,
    distanceRange: null,
    holidayParameter: null,
    dayOfWeekParameter: null,
    geoPointParameter: null,
    geoPointGroupParameter: null,
    geozoneParameter: null,
    cityIdParameter: null,
    priorityParameter: null,
}

export const initialWaiting: Waiting = {
    rowId: 0,
    cost: 0,
    freeMinutes: {isEnabled: true,value: 0},
    timeRange: null,
    holidayParameter: null,
    dayOfWeekParameter: null,
    geoPointParameter: null,
    geoPointGroupParameter: null,
    geozoneParameter: null,
    cityIdParameter: null,
    priorityParameter: null
}

export const initialRounding: Rounding = {
    rowId: 0,
    value: 0,
    roundType: 0,
    interval: null,
    priorityParameter: null
}

export const initialMainCost: MainCost = {
    rowId: 0,
    cost: 0,
    amount: 0,
    parameterMeasure: 4,
    clientInCar: false,
    speedRange: null,
    timeRange: null,
    holidayParameter: null,
    dayOfWeekParameter: null,
    geoPointParameter: null,
    geoPointGroupParameter: null,
    geozoneParameter: null,
    cityIdParameter: null,
    distanceRange: null,
    hoursRange: null,
    priorityParameter: null
}
export interface MainCost extends ITariffParameterId {
    cost: number;
    amount: number;
    parameterMeasure: ParameterMeasure;
    clientInCar: boolean;
    speedRange: any;
    timeRange: TimeRange | null;
    holidayParameter: any;
    dayOfWeekParameter: any;
    geoPointParameter: any;
    geoPointGroupParameter: any;
    geozoneParameter: any;
    cityIdParameter: any;
    distanceRange: any;
    hoursRange: any;
    priorityParameter: any;
}

export type IncludedValueParameter = {
    value: number; 
    isEnabled: boolean;
}

export enum ParameterMeasure {
    MoneyUnit = 0,
    Km = 1,
    Min = 2,
    Rate = 3,
    OneTimeCost = 4,
    Hour = 5,
    ReturnTravelDiscout = 6,
    Day = 7,
    SupplementToCost = 8
}

export interface Waiting extends ITariffParameterId {
    cost: number;
    freeMinutes: IncludedValueParameter;
    timeRange: TimeRange | null;
    holidayParameter: any;
    dayOfWeekParameter: any;
    geoPointParameter: any;
    geoPointGroupParameter: any;
    geozoneParameter: any;
    cityIdParameter: any;
    priorityParameter: any;
}

export interface Rounding extends ITariffParameterId {
    value: number;
    roundType: any;
    interval: any;
    priorityParameter: any;
}

export interface RateAllowance extends ITariffParameterId {
    value: number;
    rateType: any;
    timeRange: TimeRange | null;
    isCashless: boolean;
    holidayParameter: HolidayParameter | null;
    dayOfWeekParameter: DayOfWeekParameter | null;
    geoPointParameter: any;
    geoPointGroupParameter: any;
    geozoneParameter: any;
    cityIdParameter: any;
    priorityParameter: any;
}

export const initialRateAllowance: RateAllowance = {
    rowId: 0,
    value: 0,
    rateType: 3,
    timeRange: null,
    isCashless: false,
    holidayParameter: null,
    dayOfWeekParameter: null,
    geoPointParameter: null,
    geoPointGroupParameter: null,
    geozoneParameter: null,
    cityIdParameter: null,
    priorityParameter: null
}

export type DayOfWeekParameter = {
    dayOfWeek: number
    isEnabled: boolean
}

export type HolidayParameter = {
    day: number
    month: number
    isEnabled: boolean
}

export type PropertySet = {
    properties: Property[];
    propertySetId: number;
    propertySetName: string;
}

export type Property = {
    localizedName: string;
    propertyId: number;
    propertyName: string;
    propertyRequired: boolean;
    selectedValueId: number;
    values: PropertyValue[];
}

export type PropertyShort = {
    propertyId: number;
    propertyValueId: number | null;  
    isException?: boolean;
}

export type PropertyValue = {
    value: string;
    valueId: number;
}

export type PropertyValueSet = {
    propertyValueSetId: number;
    values: PropertyValuesSet[];
}

export type PropertyValuesSet = {
    isException: boolean;
    propertyId: number;
    propertyLocalization: string;
    propertyName: string;
    propertyValueId: number;
    value: string;
}

export type UpdateTariffParameterResult = {
    parameterRowId: number;
    conflictingRowId: number;
}

export type CarInfoParams = {
    carIds?: number[]; 
    сarNumbers?: string[];
}

export interface ICarInfo {
    carId: number;
    carNumber: string;
    driverIds: number[];
    emptyWeight: number;
    maxWeight: number;
    propertyValueSetId: number;
    carCategory: string | null;
    registrationNumber: string | null;
    vinNumber: string | null;
}

export type PropertySetNames = {
    id: number;
    name: string;
}
