import { ActionCreator, Action } from 'redux';
import { DraggablePosition, ResizeSize, RollUpCard } from './types';

export enum AppActionTypes {
    CHANGE_PHONE_CONTROL_STATE = 'CHANGE_PHONE_CONTROL_STATE',
    CHANGE_MENU_STATE = 'CHANGE_MENU_STATE',
    DRAGGABLE = 'DRAGGABLE',
    ROLL_UP_BRAND = "ROLL_UP_BRAND",
    DRAGGABLE_POSITION = "DRAGGABLE_POSITION",
    ROLL_UP_CARD = 'ROLL_UP_CARD',
    ROLL_UP_CARDS = 'ROLL_UP_CARDS',
    RESIZE_SIZE = 'RESIZE_SIZE',
    GET_LANG = 'GET_LANG'
}


export interface IChangePhoneControlStateAction extends Action<AppActionTypes.CHANGE_PHONE_CONTROL_STATE> {}
export interface IChangeMenuStateAction extends Action<AppActionTypes.CHANGE_MENU_STATE> {}
export interface IDraggable extends Action<AppActionTypes.DRAGGABLE> {draggable: boolean}
export interface IDraggablePosition extends Action<AppActionTypes.DRAGGABLE_POSITION> {draggablePosition: DraggablePosition}
export interface IResizeSize extends Action<AppActionTypes.RESIZE_SIZE> {
    resizeSize: ResizeSize
}
export interface IRollUpCard extends Action<AppActionTypes.ROLL_UP_CARD> {
    rollUpCard: RollUpCard;
}
export interface IRollUpCards extends Action<AppActionTypes.ROLL_UP_CARDS> {
    rollUpCards: RollUpCard[];
}

export interface IGetLang extends Action<AppActionTypes.GET_LANG> { lang: string }

export type AppActions =
    | IChangePhoneControlStateAction
    | IChangeMenuStateAction
    | IDraggable
    | IDraggablePosition
    | IRollUpCard
    | IRollUpCards
    | IResizeSize
    | IGetLang;

export function langAction(lang: string) {
    const action: IGetLang = {
        type: AppActionTypes.GET_LANG,
        lang
    }
    return action;
}

export function resizeSizeAction(resizeSize: ResizeSize) {
    const action: IResizeSize = {
        type: AppActionTypes.RESIZE_SIZE,
        resizeSize
    }
    return action;
}

export function draggablePositionAction(draggablePosition: DraggablePosition) {
    const action: IDraggablePosition = {
        type: AppActionTypes.DRAGGABLE_POSITION,
        draggablePosition
    }
    return action;
}

export function draggableAction(draggable: boolean) {
    const action: IDraggable = {
        type: AppActionTypes.DRAGGABLE,
        draggable
    }
    return action;
}

export function changePhoneControlStateAction() {
    const action: IChangePhoneControlStateAction = {
        type: AppActionTypes.CHANGE_PHONE_CONTROL_STATE
    }
    return action;
}

export function changeMenuStateAction() {
    const action: IChangeMenuStateAction = {
        type: AppActionTypes.CHANGE_MENU_STATE
    }
    return action;
}

export const rollUpCardsAction: ActionCreator<IRollUpCards> = (rollUpCards: RollUpCard[]) => {
    return {
        type: AppActionTypes.ROLL_UP_CARDS,
        rollUpCards
    }
}

export const rollUpCardAction: ActionCreator<IRollUpCard> = (rollUpCard: RollUpCard) => {
    return {
        type: AppActionTypes.ROLL_UP_CARD,
        rollUpCard
    }
}