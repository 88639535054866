import { Dispatch, useState } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { actions, RootState } from '../../store';
import { connect } from 'react-redux';
import { RouteItem } from './RouteItem';
import { v4 as uuidv4 } from 'uuid';
import { Routes } from '../../store/event/types';
import { useMemo } from 'react';
import { removeItemAtIndex, replaceItemAtIndex } from '../../utils/routes';
import BUILD_PARAMS from '../../utils/build';
import { CalculateCost } from '../../RemoteCommands/type';
import { SystemEvent } from '../../RemoteCommands/SystemEvent';
import { localStorageGetItem, localStorageSetItem } from '../../utils/storage';

type Props = 
    ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps> & {
    route: Routes;
};

const RouteItemContainer = (props: Props) => {
    const { 
        routes,
        route,
        selectedAddress,
        selectedPoint,
        setRouteAction,
        setRoutesAction,
        isRegistrationAction
    } = props;
    const initialCity = localStorageGetItem("city") || BUILD_PARAMS.DEFAULT_CITY;
    const [cityName, setCityName] = useState<string | undefined>(initialCity);

    useMemo(() => {
        if(selectedAddress && route.id === selectedPoint) {
            const index = routes.indexOf(route);
            const newList = replaceItemAtIndex(routes, index, {
                ...route,
                city: selectedAddress.c,
                street: selectedAddress.s,
                object: selectedAddress.t === "o" ? selectedAddress.v : undefined,
                houseNumber: selectedAddress.t === "h" ? selectedAddress.v : undefined,
                geoPoint: {
                    latitude: selectedAddress.g.lat,
                    longitude: selectedAddress.g.lon
                }
            });
            if(newList.filter(item => item.geoPoint !== undefined).length >= 2) {
                isRegistrationAction(false);
            } else {
                if(newList[0].geoPoint) {
                    const calculateCost: CalculateCost = {
                        route: [newList[0]]
                    }
                    SystemEvent.EventCalculateCost(calculateCost);
                }
            }
            setRoutesAction(newList);
        }
    }, [selectedAddress]);

    const handleCityChange = (value: string) => {
        localStorageSetItem("city", value);
        setCityName(value);
    }

    const addItem = (id: string) => {
        const index = routes.findIndex((listItem) => listItem.id === id);
        const newRoute: Routes = {
            id: uuidv4()
        }
        setRouteAction(newRoute, index);
    }

    const deleteItem = (id: string) => {
        const index = routes.findIndex((listItem) => listItem.id === id);
        const newList = removeItemAtIndex(routes, index);
        setRoutesAction(newList);
    }

    return (
        <RouteItem 
            cityName={cityName}
            handleCityChange={handleCityChange} 
            addItem={(id: string) => addItem(id)} 
            deleteItem={(id: string) => deleteItem(id)} 
            routes={routes} 
            route={route} />
    );
};

const mapStateToProps = (state: RootState) => ({
    routes: state.event.routes,
    selectedAddress: state.event.selectedAddress,
    selectedPoint: state.event.selectedPoint
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({ 
    setRoutesAction: (routes: Routes[]) => dispatch(actions.event.setRoutesAction(routes)),
    setRouteAction: (route: Routes, index: number) => 
        dispatch(actions.event.setRouteAction(route, index)),
    isRegistrationAction: (isRegistration: boolean) => 
        dispatch(actions.event.isRegistrationAction(isRegistration))
});

export default connect(mapStateToProps, mapDispatchToProps)(RouteItemContainer);