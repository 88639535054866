import React from 'react';
import { ActionsStack } from './ActionsStack';
import './ActionsStack.scss';

type Props = {
  
};

function ActionsStackContainer(props: Props) {
    return (
        <ActionsStack />
    );
};

export default ActionsStackContainer