import React from 'react';
import { Snackbar } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

type Props = {
    message: string,
    variant: "success" | "error" | "warning",
    messageOpen: boolean,
    vertical: "top" | "bottom", 
    horizontal: "left" | "center" | "right",
    messageShackbarClose: () => void;
}

interface variantIcon {
    [key: string]: React.ComponentType<SvgIconProps>;
}

const variantIcon: variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
};

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const MessageShackbar: React.FC<Props> = (props) =>  {
    const { message, variant, messageOpen, vertical, horizontal, messageShackbarClose } = props; 
    return (
        <Snackbar 
            className="messageShackbar"
            open={messageOpen} 
            anchorOrigin={{ vertical: vertical, horizontal: horizontal }}
            autoHideDuration={(variant === "error" || variant === "warning") ? null : 3000} 
            onClose={messageShackbarClose}>
            <Alert 
                onClose={messageShackbarClose} 
                severity={variant}>
                {message}
            </Alert>
        </Snackbar>
    );
}

export default MessageShackbar;