import React from 'react';
import Fab from '@material-ui/core/Fab';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import './WidgetPanel.scss'

type Props = {
    toggleClick: () => void;
    panelToggle: boolean;
    positionPanel: 'left' | 'right';
    settingsKey: string;
}

const  WidgetToggleButton = (props: Props) => {

    const { toggleClick, panelToggle, positionPanel, settingsKey } = props;

    return (
        <Fab color="primary" size="medium" aria-label="add" 
            className={`widget-panel__btn ${settingsKey === 'statusToggle' ? 'statusColorBtn' : ''} ${settingsKey === 'phoneToggle' ? 'bottom' : ''}`} style={ position(positionPanel) }
            onClick={toggleClick}>
            {positionArrow(positionPanel, panelToggle)}
        </Fab>
    )
}

function position(positionPanel: string) {
    const left = {transform: 'translateX(100%)', right: '0'};
    const right = {transform: 'translateX(-100%)', left: '0'};

    if(positionPanel === 'left') {
        return left
    } else if(positionPanel === 'right') {
        return right
    }
};

function positionArrow(positionPanel: string, panelToggle: boolean) {
    if(positionPanel === 'right') {
        return (
            panelToggle 
                ? <KeyboardArrowRightIcon fontSize="large" /> 
                : <KeyboardArrowLeftIcon fontSize="large" />
        )
    } else if(positionPanel === 'left') {
        return (
            !panelToggle 
                ? <KeyboardArrowRightIcon fontSize="large" /> 
                : <KeyboardArrowLeftIcon fontSize="large" />
        )
    } else {
        return <></>;
    }
}

export default WidgetToggleButton;