import { useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { DraggablePosition } from '../../store/app/types';
import { Card, CardHeader, CardContent, IconButton, Tooltip, Grid, Button, Link, Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import RemoveIcon from '@material-ui/icons/Remove';
import { Trans, useTranslation } from 'react-i18next';
import AddIcon from '@material-ui/icons/Add';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { PropertyValueSet, ShortGroups, Tariff } from '../../RemoteCommands/type';
import { SystemEvent } from '../../RemoteCommands/SystemEvent';
import GroupAutocomplete from '../bricks/GroupAutocomplete';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { AccessList } from '../../utils/AccessList';

type Props = {
    draggable: boolean;
    draggablePosition: DraggablePosition | undefined;
    rollUpCardHas: boolean;
    onStop: (event: any, ui: any) => void;
    openTariffs: boolean;
    rolleUpClick: () => void;
    openTariffsAction: (openTariffs: boolean) => void;
    openAddTariffAction: (openAddTariff: boolean) => void;
    tariffs: Tariff[];
    currentTariff: Tariff | undefined;
    addTariffClick: () => void;
    propertyValueSet: PropertyValueSet[];
    loadingAction: (loading: boolean) => void;
    handleGroup: (group: ShortGroups | null) => void;
    deleteTariffClick: (tariff: Tariff) => void;
    currentGroup: ShortGroups | null;
    addTariffSuccessAction: (addTariffSuccess: boolean) => void;
};

export function Tariffs(props: Props) {
    const { t } = useTranslation();
    const {
        draggable,
        draggablePosition,
        rollUpCardHas,
        onStop,
        openTariffs,
        rolleUpClick,
        openTariffsAction,
        openAddTariffAction,
        tariffs,
        addTariffClick,
        propertyValueSet,
        loadingAction,
        handleGroup,
        deleteTariffClick,
        currentGroup,
        addTariffSuccessAction
    } = props;

    const [height, setHeight] = useState(0);
    const ref = useRef<any>(null);

    useEffect(() => {
        if(ref && ref.current) {
            setHeight(ref.current.clientHeight);
        }
    }, []);

    const openAddTariffClick = (tariff: Tariff, event: React.SyntheticEvent) => {
        event.preventDefault();
        SystemEvent.EventGetTariff(tariff.basicTariffId, tariff.groupId);
        openAddTariffAction(true);
        loadingAction(true);
        addTariffSuccessAction(false);
    }

    return (
        <Draggable
            handle=".tariffs"
            defaultPosition={draggablePosition ? draggablePosition.position : undefined}
            disabled={!draggable}
            onStop={onStop}
            cancel=".cancelDraggable">
            <Card
                className={`
                    draggableCard 
                    tariffs
                    ${openTariffs ? 'openSelectedCard' : 'closeSelectedCard'}
                    ${rollUpCardHas ? 'rollUp' : 'rollUpNone'}
                `}
                style={{cursor: draggable ? 'move' : 'auto'}}>
                <CardHeader
                    action={
                        <div className="draggableCard__wrap">
                            <Button 
                                variant="contained" 
                                color="primary"
                                startIcon={<AddIcon />}
                                onClick={addTariffClick}
                                disableElevation>
                                {t("add")}
                            </Button>
                            <Tooltip
                                title={<Trans i18nKey="rollUp" />}
                                placement="top">
                                <IconButton
                                    onClick={rolleUpClick}>
                                    <RemoveIcon />
                                </IconButton>
                            </Tooltip>
                            <IconButton
                                onClick={() => openTariffsAction(false)}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    }
                    title={
                        <Grid container spacing={2} alignItems="center">
                            <Grid item>
                                <span className="draggableCard__title">{t("Tariffs")}</span>
                            </Grid>
                            <Grid item xs={4}>
                                <GroupAutocomplete 
                                    currentGroup={currentGroup}
                                    availableGroups={AccessList.GetTariffsGroupIds()}
                                    handleGroup={handleGroup} />
                            </Grid>
                        </Grid>
                    }
                />
                <CardContent
                    style={height > 800 ? {maxHeight: 799, overflowY: "auto"} : {}}
                    ref={ref}>
                    <TableContainer 
                        component={Paper}
                        className="tariff-table cancelDraggable">
                        <Table stickyHeader size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">ID</TableCell>
                                    <TableCell align="left" width={200}>{t("Tariffs")}</TableCell>
                                    <TableCell align="left">{t("filter")}</TableCell>
                                    <TableCell align="left"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tariffs.map((tariff) => {
                                    const currentProperty = propertyValueSet.find(item => item.propertyValueSetId === tariff.filterId);
                                    const propertyValues = 
                                        currentProperty ? currentProperty.values.map(item => `${item.propertyLocalization}: ${item.value}`).join("; ") : "";
                                    return (
                                        <TableRow key={tariff.tariffId}>
                                            <TableCell align="left">{tariff.tariffId}</TableCell>
                                            <TableCell component="th" scope="row">
                                                <Link href="#" onClick={(event: React.SyntheticEvent) => openAddTariffClick(tariff, event)}>
                                                    {tariff.description}
                                                </Link>
                                            </TableCell>
                                            <TableCell align="left">{propertyValues}</TableCell>
                                            <TableCell align="right">
                                                <Box display="flex">
                                                    <IconButton 
                                                        size="small"
                                                        onClick={(event: React.SyntheticEvent) => openAddTariffClick(tariff, event)}>
                                                        <EditIcon fontSize="small" />
                                                    </IconButton>
                                                    <IconButton 
                                                        size="small"
                                                        onClick={() => deleteTariffClick(tariff)}>
                                                        <DeleteIcon fontSize="small" />
                                                    </IconButton>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    )
                                }).reverse()}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>
        </Draggable>
    );
};