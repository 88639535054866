import { AppActions, AppActionTypes } from "./actions";
import { AppState, DraggablePosition, ResizeSize } from './types';
import { Reducer } from "redux";
import { localStorageGetItem } from "../../utils/storage";

const defaultState: AppState = {
    showPhoneControl:false,
    showFullMenu: true,
    draggable: true,
    draggablePositions: [],
    rollUpCards: [],
    resizeSize: [],
    lang: localStorageGetItem("i18nextLng")
};

const defaultStateGetStorage: AppState =  JSON.parse(localStorage.getItem('appReducer') || '{}');
const defaultStateNew = Object.assign({}, defaultState, defaultStateGetStorage);

const appReducerPrivate: Reducer<AppState, AppActions> = (
    state = defaultState,
    action) => {
    switch (action.type) {
        case AppActionTypes.GET_LANG:
            {
                return {
                    ...state,
                    lang: action.lang,
                };
            }
        case AppActionTypes.RESIZE_SIZE:
            {
                let resizeSize: ResizeSize[] = state.resizeSize;
                if(state.resizeSize.some(item => item.key === action.resizeSize.key)) {
                    const idx = state.resizeSize.findIndex((item) => item.key === action.resizeSize.key);
                    const oldItem = state.resizeSize[idx];
                    const newItem = {...oldItem, size: action.resizeSize.size};
                    const newResizeSize = [
                        ...state.resizeSize.slice(0, idx),
                        newItem,
                        ...state.resizeSize.slice(idx + 1)
                    ];
                    resizeSize = newResizeSize;
                } else {
                    resizeSize.push(action.resizeSize);
                }
                return {
                    ...state,
                    resizeSize: resizeSize
                };
            }
        case AppActionTypes.DRAGGABLE_POSITION:
            {
                let draggablePositions: DraggablePosition[] = state.draggablePositions;
                if(state.draggablePositions.some(item => item.key === action.draggablePosition.key)) {
                    const idx = state.draggablePositions.findIndex((item) => item.key === action.draggablePosition.key);
                    const oldItem = state.draggablePositions[idx];
                    const newItem = {...oldItem, position: action.draggablePosition.position};
                    const newDraggablePositions = [
                        ...state.draggablePositions.slice(0, idx),
                        newItem,
                        ...state.draggablePositions.slice(idx + 1)
                    ];
                    draggablePositions = newDraggablePositions;
                } else {
                    draggablePositions.push(action.draggablePosition);
                }
                return {
                    ...state,
                    draggablePositions: draggablePositions
                };
            }
        case AppActionTypes.CHANGE_PHONE_CONTROL_STATE:
            {
                return {
                    ...state,
                    showPhoneControl: !state.showPhoneControl,
                };
            }
        case AppActionTypes.CHANGE_MENU_STATE:
            {
                return {
                    ...state,
                    showFullMenu: !state.showFullMenu,
                };
            }
        case AppActionTypes.ROLL_UP_CARDS:
            return {
                ...state,
                rollUpCards: action.rollUpCards
            };
        case AppActionTypes.ROLL_UP_CARD:
            const rollUpCards = state.rollUpCards.concat(action.rollUpCard);
            return {
                ...state,
                rollUpCards
            };
        default:
            return state;
    }
};

export const appReducer: Reducer<AppState, AppActions> = (
    state = defaultStateNew, action) => {
        const newState = appReducerPrivate(state, action);
        localStorage.setItem('appReducer', JSON.stringify(newState));
        return newState;
    }

export default appReducer;