import React, { Dispatch, useState } from 'react';
import { connect } from 'react-redux';
import { actions, RootState } from '../../store';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { Tooltip } from '@material-ui/core';
import WidgetAutocomplete from '../WidgetAutocomplete/WidgetAutocomplete';
import ClearIcon from '@material-ui/icons/Clear';
import { AddressAPI, AddressAutocomplete, AddressInfo, IAddressParameters, IGetAddressItem } from 'sedi-webserverproxy';
import DoneIcon from '@material-ui/icons/Done';
import { Routes } from '../../store/event/types';
import { replaceItemAtIndex } from '../../utils/routes';
import { CalculatedCosts, EventCalculateCost } from '../../RemoteCommands/type';
import { Trans, useTranslation } from 'react-i18next';

type Props = ReturnType<typeof mapStateToProps> 
    & ReturnType<typeof mapDispatchToProps> & {
    addressInfo: AddressInfo | null;
    placeholder?: string;
    disabled?: boolean;
    inputClassName?: string;
    cityName?: string;
    route?: Routes;
    initialValue?: string;
}

const GetAddressAutocomplete:React.FC<Props> = (props) => {
    const { t } = useTranslation();
    const { 
        addressInfo,
        addressValue,
        addressValueAction,
        placeholder,
        disabled,
        inputClassName,
        selectedAddress,
        cityName,
        routes,
        route,
        setRoutesAction,
        addressesAction,
        selectedAddressAction,
        calculateCostAction,
        currentCalcCostAction,
        isRegistrationAction,
        initialValue
    } = props;

    const helperNew = new AddressAutocomplete(new AddressAPI());

    const [addressItems, setAddressItems] = useState<IGetAddressItem[]>([]);
    const [value, setValue] = useState(initialValue);

    const donereRefineSearch = async (searchText: string) => {
        let parameters: IAddressParameters;
        if(selectedAddress) {
            parameters = {
                searchText: searchText.replace(/[,]/g, ''),
                searchType: null,
                city: selectedAddress.c,
                street: selectedAddress.s,
                lat: selectedAddress.g.lat,
                lon: selectedAddress.g.lon,
                countryName: selectedAddress.co,
                searchMethod: "CheckAddress"
            }
        } else {
            parameters = {
                searchText: searchText.replace(/[,]/g, ''),
                searchType: null,
                searchMethod: "CheckAddress",
                city: cityName
            }
        }
        const response = await helperNew.GetAddresses(parameters);
        if (response.Success === false) {
            return alert(response.Message);
        }
        return response.Result!.items;
    }

    const handleOnBlur = async (event: React.FocusEvent<any>) => {
        const searchText = event.target.value;
        if((route && !route.geoPoint) && searchText.length > 0) {
            const result = await donereRefineSearch(searchText) as IGetAddressItem[];
            if(result.length > 0 && routes.some(item => item.id === route.id)) {
                selectedAddressAction(result[0]);
                setValue(result[0].v);
                addressValueAction(result[0].v);
                addressesAction([]);
            } else {
                alert(t("addressNotConfirmed"));
            }
        }
    }

    const addressChange = (newValue: string) => {
        setValue(newValue);
        addressValueAction(newValue);
        if(route) {
            const index = routes.findIndex((listItem) => listItem.id === route.id);
            const newList = replaceItemAtIndex(routes, index, {
                ...route,
                city: undefined,
                street: undefined,
                houseNumber: undefined,
                geoPoint: undefined,
                object: undefined
            });
            if(newValue.length === 0 && newList.filter(item => item.geoPoint !== undefined).length === 0) {
                calculateCostAction(null);
                currentCalcCostAction(undefined);
                isRegistrationAction(true);
            }
            setRoutesAction(newList);
        }
        addressesAction([]);
        selectedAddressAction(null);
    }

    const checkAddressHandle = async (searchText: string) => {
        const result = await donereRefineSearch(searchText) as IGetAddressItem[];
        setAddressItems(result);
    }

    return (
        <div className="getAddressAutocomplete-row cancelDraggable">
            <WidgetAutocomplete 
                value={value}
                // regionPlusIds={[78, 98, 178]}
                inputClassName={`getAddressAutocomplete ${inputClassName}`}
                inputClassNameLoading="getAddressAutocomplete"
                onChangeInput={(newValue: string) => addressChange(newValue)} 
                cityClassName="getAddressAutocomplete-city" 
                lat={addressInfo ? addressInfo.geoPoint.latitude : 0}
                lon={addressInfo ? addressInfo.geoPoint.longitude : 0} 
                errorClassName="getAddressAutocomplete-error" 
                placeholder={placeholder ? placeholder : ""}
                disabled={disabled} 
                addressItems={addressItems} 
                cityName={cityName} 
                onlyCity={false} 
                route={route} 
                handleOnBlur={handleOnBlur}/>
            {!disabled &&
                <Tooltip title={<Trans i18nKey="refineYourSearch" />} placement="top">
                    <DoneIcon 
                        className="done-icon"
                        onClick={() => checkAddressHandle(addressValue)} 
                        />
                </Tooltip>
            }
            {!disabled && 
                <Tooltip title={<Trans i18nKey="clearField" />} placement="top">
                    <ClearIcon 
                        className="getAddressAutocomplete-icon"
                        onClick={() => addressChange("")} />
                </Tooltip>
            }
        </div>
    );
}

const mapStateToProps = (state: RootState) => ({
    addressValue: state.event.addressValue,
    selectedAddress: state.event.selectedAddress,
    routes: state.event.routes
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    addressValueAction: (addressValue: string) => 
        dispatch(actions.event.addressValueAction(addressValue)),  
    addressesAction: (addresses: IGetAddressItem[]) => 
        dispatch(actions.event.addressesAction(addresses)),
    setRoutesAction: (routes: Routes[]) => 
        dispatch(actions.event.setRoutesAction(routes)),
    selectedAddressAction: (selectedAddress: IGetAddressItem | null) => 
        dispatch(actions.event.selectedAddressAction(selectedAddress)),
    calculateCostAction: (calculateCost: EventCalculateCost | null) => 
        dispatch(actions.event.calculateCostAction(calculateCost)),
    currentCalcCostAction: (currentCalcCost: CalculatedCosts | undefined) => 
        dispatch(actions.event.currentCalcCostAction(currentCalcCost)),
    isRegistrationAction: (isRegistration: boolean) => 
        dispatch(actions.event.isRegistrationAction(isRegistration))
});


export default connect(
    mapStateToProps,mapDispatchToProps
)(GetAddressAutocomplete);