import { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './components/App/App';
import { Provider } from 'react-redux';
import store from './store';
import { LocalizeProvider } from 'react-localize-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import "./i18n/i18n";

ReactDOM.render(
    <Suspense fallback={<div>Loading...</div>}>
        <AppRow />
    </Suspense>,
    document.getElementById('root')
);

function AppRow() {
    return (
        <Provider store={store}>
            <LocalizeProvider store={store}>
                <Router>
                    <Switch>
                        <Route path="/" component={App} />
                    </Switch>                
                </Router>
            </LocalizeProvider>
        </Provider>
    );
}

export default AppRow;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
