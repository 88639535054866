import { HistoryEvents } from '../../RemoteCommands/type';

type Props = {
    historyEvents: HistoryEvents[];
};

export function HistoryOrder(props: Props) {
    const { 
        historyEvents 
    } = props;

    return (
        <div className="historyOrder">
            {historyEvents.map((history, i) => (
                <div 
                    key={history.eventText + i}
                    style={{ color: `${history.color}` }}>
                    {history.eventText}
                </div>
            ))}
        </div>
    );
};