import { ActionCreator, Action } from 'redux';
import { 
    TariffsActionsTypes
} from './types';
import { Tariff } from '../../RemoteCommands/type';

export interface IOpenTariffs extends Action<TariffsActionsTypes.OPEN_TARIFFS> { openTariffs: boolean }
export interface IOpenAddTariff extends Action<TariffsActionsTypes.OPEN_ADD_TARIFF> { openAddTariff: boolean }
export interface ITariffs extends Action<TariffsActionsTypes.TARIFFS> { tariffs: Tariff[] }
export interface ICurrentTariff extends Action<TariffsActionsTypes.CURRENT_TARIFF> { currentTariff: Tariff | undefined }
export interface IAddTariffSuccess extends Action<TariffsActionsTypes.ADD_TARIFF_SUCCESS> { addTariffSuccess: boolean }

export type TariffsActions =
    | IOpenTariffs
    | IOpenAddTariff
    | ITariffs
    | ICurrentTariff
    | IAddTariffSuccess;

export const addTariffSuccessAction: ActionCreator<IAddTariffSuccess> = (addTariffSuccess: boolean) => {
    return {
        type: TariffsActionsTypes.ADD_TARIFF_SUCCESS,
        addTariffSuccess
    }
}

export const currentTariffAction: ActionCreator<ICurrentTariff> = (currentTariff: Tariff | undefined) => {
    return {
        type: TariffsActionsTypes.CURRENT_TARIFF,
        currentTariff
    }
}

export const tariffsAction: ActionCreator<ITariffs> = (tariffs: Tariff[]) => {
    return {
        type: TariffsActionsTypes.TARIFFS,
        tariffs
    }
}

export const openAddTariffAction: ActionCreator<IOpenAddTariff> = (openAddTariff: boolean) => {
    return {
        type: TariffsActionsTypes.OPEN_ADD_TARIFF,
        openAddTariff
    }
}

export const openTariffsAction: ActionCreator<IOpenTariffs> = (openTariffs: boolean) => {
    return {
        type: TariffsActionsTypes.OPEN_TARIFFS,
        openTariffs
    }
}