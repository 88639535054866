import { Action, ActionCreator } from 'redux';
import { ThunkDispatch, ThunkAction } from "redux-thunk/index";
import { TranslationState } from './types';
import { Translation } from 'sedi-webserverproxy';
import { getWebServerProxy, initWebServerProxy } from '../../WebServerProxy';


export enum TranslationActionTypes {
    FETCH_TRANSLATIONS = 'FETCH_TRANSLATIONS',
    FETCH_TRANSLATIONS_ERROR = 'FETCH_TRANSLATIONS_ERROR',
    FETCH_TRANSLATIONS_SUCCESS = 'FETCH_TRANSLATIONS_SUCCESS',
}


export interface IFetchingTranslationsAction extends Action<TranslationActionTypes.FETCH_TRANSLATIONS> {
}
export interface IFetchTranslationsErrorAction extends Action<TranslationActionTypes.FETCH_TRANSLATIONS_ERROR> {
    error: string,
}
export interface IFetchTranslationsSuccessAction extends Action<TranslationActionTypes.FETCH_TRANSLATIONS_SUCCESS> {
    translations: Translation[],
}


export type TranslationActions =
    | IFetchingTranslationsAction
    | IFetchTranslationsErrorAction
    | IFetchTranslationsSuccessAction;

export const fetchTranslationsActionCreator: ActionCreator<ThunkAction<Promise<any>, TranslationState, string, IFetchTranslationsSuccessAction>> = () => {
    return async (dispatch: ThunkDispatch<TranslationState, void, Action<any>>) => {
        try {
            initWebServerProxy('');
            const wsProxy = getWebServerProxy();

            const fetchingAction: IFetchingTranslationsAction = {
                type: TranslationActionTypes.FETCH_TRANSLATIONS
            };
            dispatch(fetchingAction);

            const result = await wsProxy.WebClient.GetLocalization({});
            if (result.Success === false){
                const failAction: IFetchTranslationsErrorAction = {
                    type: TranslationActionTypes.FETCH_TRANSLATIONS_ERROR,
                    error: result.Message!
                };

                dispatch(failAction);
            }

            const successAction: IFetchTranslationsSuccessAction = {
                type: TranslationActionTypes.FETCH_TRANSLATIONS_SUCCESS,
                translations: result.Result!
            };

            dispatch(successAction);                   
        } catch (e) {
            const failAction: IFetchTranslationsErrorAction = {
                type: TranslationActionTypes.FETCH_TRANSLATIONS_ERROR,
                error: e.message
            };
            
            dispatch(failAction);
        }
    }
}

