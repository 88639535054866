import { Dispatch } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { RootState, actions } from '../../store';
import { connect } from 'react-redux';
import { OrderInfoModal } from './OrderInfoModal';
import { useEffect } from 'react';
import { errorCallback, SystemEvent } from '../../RemoteCommands/SystemEvent';
import { useState } from 'react';
import { IOrderInfo } from '../../RemoteCommands/type';
import './OrderInfoModal.scss';

type Props = 
    ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps>;

function OrderInfoModalContainer(props: Props) {
    const {
        openOrder,
        openOrderAction
    } = props;

    const [loading, setLoading] = useState(false);
    const [orderInfo, setOrderInfo] = useState<IOrderInfo | null>(null);

    const handleClose = () => {
        openOrderAction(false);
        setTimeout(() => {
            setOrderInfo(null);
        }, 300);
    }

    useEffect(() => {
        setLoading(true);
        SystemEvent.SubscribeEventGetOrderInfo(
            "EventGetOrderInfo", 
            (answer) => {
                setLoading(false);
                setOrderInfo(answer.orderInfo);
            }, 
            (error) => {
                setLoading(false);
                errorCallback(error);
            }
        );
    }, []);

    return (
        <OrderInfoModal 
            openOrder={openOrder} 
            handleClose={handleClose} 
            loading={loading} 
            orderInfo={orderInfo} />
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        openOrder: state.event.openOrder,
    }
};

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({ 
    openOrderAction: (openOrder: boolean) => 
        dispatch(actions.event.openOrderAction(openOrder)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderInfoModalContainer);