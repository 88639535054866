import { Marker, MarkerClusterer } from '@react-google-maps/api';
import React from 'react';
import { IActiveEmployees } from '../../RemoteCommands/type';
import { clustererOptions, markerIconSettings } from '../../utils/mapSettings';
import { Center } from '../Map/type';

type Props = {
    taxiDrivers: IActiveEmployees[];
    employeeInfoWindow: (marker: google.maps.Marker, taxiDriver: IActiveEmployees) => void;
}

const Clusterer:React.FC<Props> = (props) => {
    const {
        taxiDrivers,
        employeeInfoWindow
    } = props;
    
    return (
        <MarkerClusterer 
            options={clustererOptions}>
            {(clusterer) =>
                taxiDrivers.map((taxiDriver, i) => {
                    const position: Center = {
                        lat: taxiDriver.geoPoint.latitude,
                        lng: taxiDriver.geoPoint.longitude
                    }
                    return (
                        <Marker 
                            key={createKey(position) + i}
                            position={position} 
                            clusterer={clusterer} 
                            icon={markerIconSettings(taxiDriver.azimuth)}
                            title={`${taxiDriver.name} ${taxiDriver.role}`}
                            onLoad={(marker: google.maps.Marker) => employeeInfoWindow(marker, taxiDriver)}
                        />
                    )
                })
            }
        </MarkerClusterer>
    );
}

function createKey(location: Center) {
    return location.lat + location.lng
}

export default Clusterer;