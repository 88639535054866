import { Dispatch, useRef, useState } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { actions, RootState } from '../../store';
import { connect } from 'react-redux';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { useEffect } from 'react';
import { errorCallback, SystemEvent } from '../../RemoteCommands/SystemEvent';
import { ICarInfo, Property, PropertySet, PropertySetNames, PropertyShort, PropertyValuesSet } from '../../RemoteCommands/type';
import { Box, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { removeItemAtIndex, replaceItemAtIndex } from '../../utils/replaceItem';
import _ from 'lodash';
import './PropertiesModal.scss';

type Props = 
    ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps> & {
    open: boolean;
    handleClose: () => void;
    currentPropertyValues: PropertyValuesSet[];
    defaultCar?: ICarInfo;
    addCar?: boolean;
    tariffProperty?: boolean;
};

function PropertiesModal(props: Props) {
    const { t } = useTranslation();
    const {
        open,
        handleClose,
        lang,
        propertySetAction,
        propertySet,
        currentPropertyValues,
        defaultCar,
        addCar,
        tariffProperty
    } = props;

    const [loading, setLoading] = useState(false);
    const [propertySetNames, setPropertySetNames] = useState<PropertySetNames[]>([]);
    const [propertySetId, setPropertySetId] = useState(2);
    const [currentCar, setCurrentCar] = useState(defaultCar);
    const eventKey = useRef<any>(null);

    useEffect(() => {
        SystemEvent.SubscribeEventGetPropertySetNames(
            "PropertiesModal", 
            (answer) => {
                setPropertySetNames(answer.propertySets);
            }, 
            (error) => {
                errorCallback(error);
            }
        );
        SystemEvent.EventGetPropertySetNames("Taxi");
    }, []);

    useEffect(() => {
        SystemEvent.SubscribeEventSavePropertyValueSet(
            "PropertiesModal", 
            (answer) => {
                if(answer.eventKey === eventKey.current) {
                    if(currentCar) {
                        const сarInfo: ICarInfo = {
                            ...currentCar,
                            propertyValueSetId: answer.propertyValueSetId
                        }
                        if(addCar) {
                            SystemEvent.EventAddCar(сarInfo);
                        } else {
                            SystemEvent.EventSetCarInfo(сarInfo);
                            SystemEvent.EventGetPropertyValueSets([answer.propertyValueSetId]);
                        }
                    }
                    if(tariffProperty) {
                        handleClose();
                    }
                }
            }, 
            (error) => {
                errorCallback(error);
            }
        );
    }, [currentCar]);

    useEffect(() => {
        setLoading(true);
        SystemEvent.SubscribeEventGetPropertySet(
            "PropertiesModal", 
            (answer) => {
                let newProperties: Property[] = answer.propertySet.properties;
                for(let property of newProperties) {
                    const currentProperty = currentPropertyValues.find(item => item.propertyId === property.propertyId)
                    if(currentProperty) {
                        property.selectedValueId = currentProperty.propertyValueId
                    }
                }
                propertySetAction({
                    ...answer.propertySet,
                    properties: newProperties
                });
                setLoading(false);
            }, 
            (error) => {
                setLoading(false);
                errorCallback(error);
            }
        );
        SystemEvent.EventGetPropertySet("Taxi", lang, propertySetId);
    }, [propertySetId]);

    const handleNumberChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        setCurrentCar(prevProps => ({
            ...prevProps!,
            [name]: value
        }));
    }

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>, property: Property) => {
        const value = event.target.value as number | undefined;
        if(propertySet) {
            let newList: Property[] = [];
            const index = propertySet.properties.findIndex((listItem) => listItem.propertyId === property.propertyId);
            if(value === undefined) {
                newList = removeItemAtIndex(propertySet.properties, index);
            } else {
                newList = replaceItemAtIndex(propertySet.properties, index, {
                    ...property,
                    selectedValueId: event.target.value as number,
                });
            }
            propertySetAction({
                ...propertySet,
                properties: newList
            });
        }
    };

    const handleTypeAutoChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const value = event.target.value as number;
        setPropertySetId(value);
    }

    const savePropertyClick = () => {
        let properties: PropertyShort[] = [];
        if(!propertySet) {
            return null;
        }
        const filterProperties = propertySet.properties.filter(item => item.selectedValueId !== -1);
        for(let property of filterProperties) {
            properties.push({
                propertyId: property.propertyId,
                propertyValueId: property.selectedValueId
            })
        }
        eventKey.current = SystemEvent.EventSavePropertyValueSet(properties);
    }

    const propertiesView = () => {
        if(loading) {
            return (
                <Box className="loading loading_vertical-center" minHeight={500}>
                    <CircularProgress />
                </Box>
            )
        }
        return (
            <Grid container spacing={1}>
                {propertySet && propertySet.properties.map(property => {
                    const values = property.propertyId === 2 ? _.sortBy(property.values, a => parseInt(a.value)) : property.values;
                    return (
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="fiter-select-label">{property.localizedName}</InputLabel>
                                <Select
                                    labelId="fiter-select-label"
                                    value={property.selectedValueId > 0 ? property.selectedValueId : undefined}
                                    onChange={(event) => handleChange(event, property)}>
                                    <MenuItem value={undefined}>{t("notIndicated")}</MenuItem>
                                    {values.map(item => (
                                        <MenuItem key={item.valueId} value={item.valueId}>{item.value}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    )
                })}
            </Grid>
        )
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth
            maxWidth="xs"
            className="properties-modal"
        >
            <DialogTitle onClose={handleClose}>
                {currentCar ? t("machineBinding") : t("filter")}
                <Grid container spacing={1}>
                    {currentCar && 
                        <Grid item xs={12}>
                            <TextField 
                                fullWidth
                                label={t("numberOfTheCar")}
                                name="carNumber"
                                value={currentCar.carNumber} 
                                onChange={handleNumberChange}
                                disabled={!addCar}
                                className="disabled" 
                                autoFocus={addCar} />
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="type-auto-label">{t("carType")}</InputLabel>
                            <Select
                                labelId="type-auto-label"
                                value={propertySetId}
                                onChange={handleTypeAutoChange}>
                                {propertySetNames.map(item => (
                                    <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent dividers>
                {propertiesView()}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {t("cancel")}
                </Button>
                <Button onClick={savePropertyClick} color="primary">
                    {tariffProperty ? "Ок" : (addCar ? t("add") : t("save"))}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = (state: RootState) => ({
    lang: state.app.lang,
    propertySet: state.event.propertySet,
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    propertySetAction: (propertySet: PropertySet | undefined) => 
        dispatch(actions.event.propertySetAction(propertySet)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PropertiesModal);

const styles = (theme: Theme) =>
    createStyles({
        root: {
        margin: 0,
        padding: theme.spacing(2),
        },
        closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
        },
});

export interface DialogTitleProps extends WithStyles<typeof styles> {
  children: React.ReactNode;
  onClose: () => void;
}


const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <CloseIcon />
            </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
  });
  