import { Dispatch, useState } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { actions, RootState } from '../../store';
import { connect } from 'react-redux';
import { 
    Box, ButtonGroup, Checkbox, FormControl, FormControlLabel, Grid, IconButton, MenuItem, 
    Select, TextField, Tooltip 
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import { Trans, useTranslation } from 'react-i18next';
import { TooltipCustom } from '../../utils/TooltipCustom';
import { RateAllowance, Tariff, TariffParameter, TimeRange } from '../../RemoteCommands/type';
import { replaceItemAtIndex } from '../../utils/replaceItem';
import PropertyDeleteModal from './PropertyDeleteModal';
import { SystemEvent } from '../../RemoteCommands/SystemEvent';
import PeriodDate from './PeriodDate';

type Props = 
    ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps> & {
    item: RateAllowance;
    rateAllowances: RateAllowance[];
};

function RateAllowanceRow(props: Props) {
    const {t} = useTranslation();
    const {
        item,
        currentTariff,
        currentTariffAction,
        rateAllowances,
    } = props;

    const index = rateAllowances.findIndex((listItem) => listItem.rowId === item.rowId);
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    }

    const handleTimeRange = (timeRange: TimeRange) => {
        const newList = replaceItemAtIndex(rateAllowances, index, {
            ...item,
            timeRange
        });
        if(currentTariff) {
            currentTariffAction({
                ...currentTariff,
                rateAllowances: newList
            })
        }
    }

    const rateAllowanceChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        let newList: RateAllowance[] = rateAllowances;
        switch (name) {
            case 'priorityParameter':
                newList = replaceItemAtIndex(rateAllowances, index, {
                    ...item,
                    priorityParameter: {
                        isEnabled: true,
                        value: Number(value),
                    }
                });
                break;
            default:
                newList = replaceItemAtIndex(rateAllowances, index, {
                    ...item,
                    [name]: Number(value),
                });
        }
        if(currentTariff) {
            currentTariffAction({
                ...currentTariff,
                rateAllowances: newList
            })
        }
    }

    const handleSelectChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        const name = event.target.name as keyof typeof item;
        const value = event.target.value as number;
        const newList = replaceItemAtIndex(rateAllowances, index, {
            ...item,
            [name]: Number(value),
        });
        if(currentTariff) {
            currentTariffAction({
                ...currentTariff,
                rateAllowances: newList
            })
        }
    };

    const handleCheckedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.name;
        const checked = event.target.checked;
        const newList = replaceItemAtIndex(rateAllowances, index, {
            ...item,
            [name]: checked
        });
        if(currentTariff) {
            currentTariffAction({
                ...currentTariff,
                rateAllowances: newList
            })
        }
      };

    const saveMainCost = () => {
        if(!currentTariff) return;
        const tariffParameter: TariffParameter = {
            rowId: item.rowId,
            basicTariffId: currentTariff.basicTariffId,
            forExecutorTariff: false,
            rateAllowance: item,
        }
        SystemEvent.EventUpdateTariffParameters(tariffParameter);
    }

    return (
        <>
            <Box className="advanced-tariff">
                <Box className="advanced-tariff__btn">
                    <Grid 
                        container>
                        <Grid item>
                            <Tooltip title={<Trans i18nKey="save"/>} placement="top">
                                <IconButton 
                                    size="small"
                                    onClick={saveMainCost}>
                                    <SaveIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip title={<Trans i18nKey="delete"/>} placement="top">
                                <IconButton 
                                    size="small"
                                    onClick={() => setOpen(true)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Box>
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <ButtonGroup 
                            color="primary"
                            size="small"
                            className="btn-group"
                            fullWidth>
                            <TextField 
                                placeholder="0.00"
                                size="small" 
                                type="number" 
                                value={item.value}
                                name="value"
                                onChange={rateAllowanceChange}
                            />
                            <FormControl 
                                variant="outlined"
                                size="small"
                                fullWidth>
                                <Select
                                    value={item.rateType}
                                    name="rateType"
                                    onChange={handleSelectChange}>
                                    <MenuItem value={3}>{t("coefficient")}</MenuItem>
                                    <MenuItem value={6}>{t("Discountonthereturntrip")}</MenuItem>
                                </Select>
                            </FormControl>
                        </ButtonGroup>      
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                                control={
                                <Checkbox
                                    checked={item.isCashless}
                                    name="isCashless"
                                    onChange={handleCheckedChange}
                                    color="primary"
                                />
                                }
                                label={t("cashless")}
                            />     
                    </Grid>
                    <Grid item xs={12}>
                        <PeriodDate 
                            timeRange={item.timeRange} 
                            handleTimeRange={handleTimeRange} />
                    </Grid>
                    <Grid item xs={3}>
                        <TooltipCustom title={<Trans i18nKey="Priority"/>} placement="top">
                            <TextField 
                                variant="outlined"
                                placeholder="0"
                                size="small" 
                                type="number"
                                fullWidth 
                                value={item.priorityParameter ? item.priorityParameter.value : ""}
                                name="priorityParameter"
                                onChange={rateAllowanceChange}
                            />
                        </TooltipCustom>           
                    </Grid>
                </Grid>
            </Box>
            {open && currentTariff &&
                <PropertyDeleteModal 
                    open={open} 
                    handleClose={handleClose}
                    basicTariffId={currentTariff.basicTariffId} 
                    rowId={item.rowId} 
                    array={rateAllowances}
                    name="mainCosts"
                    currentTariff={currentTariff}
                    currentTariffAction={currentTariffAction} />
            }
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    currentTariff: state.tariffs.currentTariff
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({ 
    currentTariffAction: (currentTariff: Tariff | undefined) => 
        dispatch(actions.tariffs.currentTariffAction(currentTariff)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RateAllowanceRow);