import { Reducer } from "redux";
import { TariffsState} from "./types";
import { TariffsActions } from "./actions";
import { TariffsActionsTypes } from "./types";

const defaultState: TariffsState = {
    openTariffs: false,
    openAddTariff: false,
    tariffs: [],
    currentTariff: undefined,
    addTariffSuccess: false
};

export const authReducer: Reducer<TariffsState, TariffsActions> = (state = defaultState, action) => {
    switch (action.type) {
        case TariffsActionsTypes.ADD_TARIFF_SUCCESS:
            return {
                ...state,
                addTariffSuccess: action.addTariffSuccess
            };
        case TariffsActionsTypes.CURRENT_TARIFF:
            return {
                ...state,
                currentTariff: action.currentTariff
            };
        case TariffsActionsTypes.TARIFFS:
            return {
                ...state,
                tariffs: action.tariffs
            };
        case TariffsActionsTypes.OPEN_ADD_TARIFF:
            return {
                ...state,
                openAddTariff: action.openAddTariff
            };
        case TariffsActionsTypes.OPEN_TARIFFS:
            return {
                ...state,
                openTariffs: action.openTariffs
            };
        default:
            return state;
    }
};

export default authReducer;