import store from "../store";
import * as eventActions from '../store/event/actions';
import { EventItem, EventsHandler, EventsQueue } from "./EventsQueue";
import { 
    CalculateCost,
    CarInfoParams,
    EmployeeFilter, EventCalculateCost, EventError, EventGetActiveEmployees, EventGetTariffs, GeoPoint, GetActiveOrdersForDispatcher, 
    GetEmployeeDetailedInfo, GetEmployeesShortInfo, GetHistoryEvents, GetSettings, 
    ICarInfo, 
    IDetailedEmployeeInfo, IEventUserIdentification, PropertyShort, RegisterOrder, ShortGroups, SpecialServices, TariffParameter, UpdateTariffParameterResult 
} from "./type";

export class SystemEvent {
    public static EventUserIdentification(userIdentificationKey: string, type: string) {
        const eventName = "EventUserIdentification";
        const eventParameters = {
            "UserIdentificationKey": userIdentificationKey,
            "IdentificationKeyType": type,
            "DeviceId": localStorage.getItem("deviceId") || "-"
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventUserIdentificationPin(PIN: string) {
        const eventName = "EventUserIdentificationPin";
        const eventParameters = {
            "PIN": PIN,
            "DeviceId": localStorage.getItem("deviceId") || "-",
            "UserType": "Employee"
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventGetActiveEmployees() {
        const eventName = "EventGetActiveEmployees";
        const eventParameters = {};
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventGetSettings() {
        const eventName = "EventGetSettings";
        const eventParameters = {};
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventGetEmployeesShortInfo(employeeFilter: EmployeeFilter) {
        const eventName = "EventGetEmployeesShortInfo";
        const eventParameters = {
            "EmployeeFilter": employeeFilter
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventGetEmployeeDetailedInfo(employeeId: number) {
        const eventName = "EventGetEmployeeDetailedInfo";
        const eventParameters = {
            "EmployeeId": employeeId
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventChangeEmployeeData(employeeData: IDetailedEmployeeInfo) {
        const eventName = "EventChangeEmployeeData";
        const eventParameters = {
            "EmployeeData": employeeData
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventGetActiveOrdersForDispatcher() {
        const eventName = "EventGetActiveOrdersForDispatcher";
        const eventParameters = {};
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventGetOrderInfo(orderId: number) {
        const eventName = "EventGetOrderInfo";
        const eventParameters = {
            "OrderId": orderId
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventGetHistoryEvents(orderId: number) {
        const eventName = "EventGetHistoryEvents";
        const eventParameters = {
            "OrderId": orderId
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventAssignExecutorToOrder(orderId: number, executorId: number, withFilters: boolean) {
        const eventName = "EventAssignExecutorToOrder";
        const eventParameters = {
            "OrderId": orderId,
            "ExecutorId": executorId,
            "WithFilters": withFilters
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventWithdrawOrderFromExecutor(orderId: number) {
        const eventName = "EventWithdrawOrderFromExecutor";
        const eventParameters = {
            "OrderId": orderId
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventRegisterOrder(registerOrder: RegisterOrder) {
        const eventName = "EventRegisterOrder";

        const eventParameters = {
            "CostCalculationId": registerOrder.costCalculationId,
            "IsCashlessOrder": registerOrder.isCashlessOrder,
            "ReferenceCardId": registerOrder.referenceCardId,
            "CustomerPhone": registerOrder.customerPhone,
            "CustomerName": registerOrder.customerName,
            "CustomerId": registerOrder.customerId,
            "GroupId": registerOrder.groupId,
            "CompanyId": registerOrder.companyId,
            "Comment": registerOrder.comment,
            "AuctionNominalPercent": registerOrder.auctionNominalPercent,
            "OwnerChargePart": registerOrder.ownerChargePart,
            "OwnerChargeSum": registerOrder.ownerChargeSum
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventCalculateCost(calculateCost: CalculateCost) {
        const eventName = "EventCalculateCost";

        const eventParameters = {
            "OrderTime": calculateCost.orderTime,
            "SpecialServices": calculateCost.specialServices,
            "IsCashless": calculateCost.isCashless,
            "KeyWord": calculateCost.keyWord,
            "Route": calculateCost.route,
            "GroupId": calculateCost.groupId
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventGetGroups(text: string) {
        const eventName = "EventGetGroups";

        const eventParameters = {
            "Text": text
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventGetSpecialServices() {
        const eventName = "EventGetSpecialServices";

        const eventParameters = {};

        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventGetTariffs(groupId?: number) {
        const eventName = "EventGetTariffs";

        const eventParameters = {
            "GroupId": groupId
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventGetTariff(basicTariffId: number, groupId?: number) {
        const eventName = "EventGetTariff";

        const eventParameters = {
            "GroupId": groupId,
            "BasicTariffId": basicTariffId
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventGetPropertySet(propertySetName: string, lang: string | null, propertySetId?: number) {
        const eventName = "EventGetPropertySet";

        const eventParameters = {
            "PropertySetName": propertySetName,
            "Language": lang,
            "PropertySetId": propertySetId,
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventGetPropertyValueSets(propertyValueSetsIds: number[]) {
        const eventName = "EventGetPropertyValueSets";

        const eventParameters = {
            "PropertyValueSetsIds": propertyValueSetsIds
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventSetTariff(tariff: any) {
        const eventName = "EventSetTariff";

        const eventParameters = {
            "Tariff": tariff
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventSavePropertyValueSet(properties: PropertyShort[]): string {
        const eventName = "EventSavePropertyValueSet";

        const eventParameters = {
            "Properties": properties
        };
        return EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventUpdateTariffParameters(tariffParameter: TariffParameter): string {
        const eventName = "EventUpdateTariffParameters";

        const eventParameters = {
            "TariffParameter": tariffParameter
        };
        return EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventDeleteTariffParameters(basicTariffId: number, rowId: number) {
        const eventName = "EventDeleteTariffParameters";

        const eventParameters = {
            "BasicTariffId": basicTariffId,
            "TariffParameterId": rowId
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventDeleteTariff(basicTariffId: number) {
        const eventName = "EventDeleteTariff";

        const eventParameters = {
            "BasicTariffId": basicTariffId
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventGetEmployeeRolesList(lang: string | null) {
        const eventName = "EventGetEmployeeRolesList";

        const eventParameters = {
            "Language": lang
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventGetRoutingInfo(coordinates: GeoPoint[], dateTime: string) {
        const eventName = "EventGetRoutingInfo";

        const eventParameters = {
            "Coordinates": coordinates,
            "DateTime": dateTime
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventCancelOrder(orderId: number) {
        const eventName = "EventCancelOrder";

        const eventParameters = {
            "OrderId": orderId
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventGetCarInfo(payload: CarInfoParams) {
        const eventName = "EventGetCarInfo";

        const eventParameters = {
            "CarIds": payload.carIds,
            "CarNumbers": payload.сarNumbers
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventDeleteCar(carId: number, driverId: number) {
        const eventName = "EventDeleteCar";

        const eventParameters = {
            "CarId": carId,
            "DriverId": driverId
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventSetCarInfo(сarInfo: ICarInfo) {
        const eventName = "EventSetCarInfo";

        const eventParameters = {
            "CarInfo": сarInfo
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventGetPropertySetNames(propertySetType: string) {
        const eventName = "EventGetPropertySetNames";

        const eventParameters = {
            "PropertySetType": propertySetType
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    public static EventAddCar(сarInfo: ICarInfo) {
        const eventName = "EventAddCar";

        const eventParameters = {
            "CarInfo": сarInfo
        };
        EventsQueue.SendEvent(eventName, eventParameters);
    }

    // Подписаться на события
    public static SubscribeEventAddCar(
        pageName: string, 
        callback: (answer: any) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventAddCar", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const carId  = event.eventParameters["carId"];
                callback({
                    carId
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventSetCarInfo(
        pageName: string, 
        callback: (answer: any) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventSetCarInfo", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const eventParameters  = event.eventParameters;
                callback({
                    eventParameters
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventGetPropertySetNames(
        pageName: string, 
        callback: (answer: any) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventGetPropertySetNames", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const propertySets  = event.eventParameters["propertySets"];
                callback({
                    propertySets
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventDeleteCar(
        pageName: string, 
        callback: (answer: any) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventDeleteCar", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const eventParameters  = event.eventParameters;
                callback({
                    eventParameters
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventGetCarInfo(
        pageName: string, 
        callback: (answer: any) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventGetCarInfo", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const cars  = event.eventParameters["cars"];
                callback({
                    cars
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventCancelOrder(
        pageName: string, 
        callback: (answer: any) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventCancelOrder", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const eventParameters  = event.eventParameters;
                callback({
                    eventParameters
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventGetRoutingInfo(
        pageName: string, 
        callback: (answer: any) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventGetRoutingInfo", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const route  = event.eventParameters["route"];
                callback({
                    route
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventGetEmployeeRolesList(
        pageName: string, 
        callback: (answer: any) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventGetEmployeeRolesList", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const roles  = event.eventParameters["roles"];
                callback({
                    roles
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventDeleteTariff(
        pageName: string, 
        callback: (answer: any) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventDeleteTariff", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const eventParameters  = event.eventParameters;
                callback({
                    eventParameters
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventDeleteTariffParameters(
        pageName: string, 
        callback: (answer: any) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventDeleteTariffParameters", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const eventParameters  = event.eventParameters;
                callback({
                    eventParameters
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventUpdateTariffParameters(
        pageName: string, 
        callback: (answer: any) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventUpdateTariffParameters", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const updateTariffParameterResult  = event.eventParameters["updateTariffParameterResult"] as UpdateTariffParameterResult;
                const eventKey = event.eventKey
                callback({
                    updateTariffParameterResult,
                    eventKey
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventSavePropertyValueSet(
        pageName: string, 
        callback: (answer: any) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventSavePropertyValueSet", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const propertyValueSetId  = event.eventParameters["propertyValueSetId"];
                const eventKey = event.eventKey;
                callback({
                    propertyValueSetId,
                    eventKey
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventSetTariff(
        pageName: string, 
        callback: (answer: any) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventSetTariff", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const tariffId  = event.eventParameters["tariffId"];
                callback({
                    tariffId
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventGetPropertyValueSets(
        pageName: string, 
        callback: (answer: any) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventGetPropertyValueSets", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const propertyValueSets  = event.eventParameters["propertyValueSets"];
                callback({
                    propertyValueSets
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventGetPropertySet(
        pageName: string, 
        callback: (answer: any) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventGetPropertySet", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const propertySet  = event.eventParameters["propertySet"];
                callback({
                    propertySet
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventGetTariffs(
        pageName: string, 
        callback: (answer: EventGetTariffs) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventGetTariffs", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const tariffs  = event.eventParameters["tariffs"];
                callback({
                    tariffs
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventGetTariff(
        pageName: string, 
        callback: (answer: any) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventGetTariff", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const tariff  = event.eventParameters["tariff"];
                callback({
                    tariff
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventGetSpecialServices(
        pageName: string, 
        callback: (answer: any) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventGetSpecialServices", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const specialServices  = event.eventParameters["specialServices"] as SpecialServices;
                callback({
                    specialServices
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventGetGroups(
        pageName: string, 
        callback: (answer: any) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventGetGroups", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const shortGroups  = event.eventParameters["shortGroups"] as ShortGroups[];
                callback({
                    shortGroups
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventCalculateCost(
        pageName: string, 
        callback: (answer: EventCalculateCost) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventCalculateCost", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const routeTotalDuration  = event.eventParameters["routeTotalDuration"];
                const routeTotalDistance  = event.eventParameters["routeTotalDistance"];
                const calculatedCosts  = event.eventParameters["calculatedCosts"];
                const routeTrack  = event.eventParameters["routeTrack"];
                callback({
                    routeTotalDuration,
                    routeTotalDistance,
                    calculatedCosts,
                    routeTrack
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventRegisterOrder(
        pageName: string, 
        callback: (answer: any) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventRegisterOrder", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const orderId  = event.eventParameters["orderId"];
                callback({
                    orderId
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventUserIdentification(
        pageName: string, 
        callback: (answer: IEventUserIdentification) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventUserIdentification", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const isNewUser  = event.eventParameters["isNewUser"];
                const userKey  = event.eventParameters["userKey"];
                const userName  = event.eventParameters["userName"];
                const needVerify  = event.eventParameters["needVerify"];
                const availableApplicationFunctions  = event.eventParameters["availableApplicationFunctions"];
                callback({
                    isNewUser,
                    userKey,
                    userName,
                    needVerify,
                    availableApplicationFunctions
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventUserIdentificationPin(
        pageName: string, 
        callback: (answer: IEventUserIdentification) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventUserIdentificationPin", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const isNewUser  = event.eventParameters["isNewUser"];
                const userKey  = event.eventParameters["userKey"];
                const userName  = event.eventParameters["userName"];
                const needVerify  = event.eventParameters["needVerify"];
                const availableApplicationFunctions  = event.eventParameters["availableApplicationFunctions"];
                callback({
                    isNewUser,
                    userKey,
                    userName,
                    needVerify,
                    availableApplicationFunctions
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventGetActiveEmployees(
        pageName: string, 
        callback: (answer: EventGetActiveEmployees) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventGetActiveEmployees", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const activeEmployees  = event.eventParameters["activeEmployees"];
                callback({
                    activeEmployees
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventGetSettings(
        pageName: string, 
        callback: (answer: GetSettings) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventGetSettings", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const sipLogin  = event.eventParameters["sipLogin"];
                const sipPassword  = event.eventParameters["sipPassword"];
                callback({
                    sipLogin,
                    sipPassword
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventGetEmployeesShortInfo(
        pageName: string, 
        callback: (answer: GetEmployeesShortInfo) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventGetEmployeesShortInfo", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const shortEmployeesInfo  = event.eventParameters["shortEmployeesInfo"];
                callback({
                    shortEmployeesInfo
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventGetEmployeeDetailedInfo(
        pageName: string, 
        callback: (answer: GetEmployeeDetailedInfo) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventGetEmployeeDetailedInfo", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const detailedEmployeeInfo  = event.eventParameters["detailedEmployeeInfo"];
                callback({
                    detailedEmployeeInfo
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventChangeEmployeeData(
        pageName: string, 
        callback: (answer: any) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventChangeEmployeeData", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const employeeId  = event.eventParameters["employeeId"];
                callback({
                    employeeId
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventGetActiveOrdersForDispatcher(
        pageName: string, 
        callback: (answer: GetActiveOrdersForDispatcher) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventGetActiveOrdersForDispatcher", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const activeOrders  = event.eventParameters["activeOrders"];
                callback({
                    activeOrders
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventGetOrderInfo(
        pageName: string, 
        callback: (answer: any) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventGetOrderInfo", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const orderInfo  = event.eventParameters;
                callback({
                    orderInfo
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventGetHistoryEvents(
        pageName: string, 
        callback: (answer: GetHistoryEvents) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventGetHistoryEvents", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const historyEvents  = event.eventParameters["historyEvents"];
                callback({
                    historyEvents
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventAssignExecutorToOrder(
        pageName: string, 
        callback: (answer: any) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventAssignExecutorToOrder", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const assignExecutorParameters  = event.eventParameters;
                callback({
                    assignExecutorParameters
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventWithdrawOrderFromExecutor(
        pageName: string, 
        callback: (answer: any) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventWithdrawOrderFromExecutor", pageName, (event: EventItem) => {
            if(event.eventType === "Answer" || event.eventType === "Send") {
                const success  = event.eventParameters["success"];
                callback({
                    success
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventUpdateGpsData3(
        pageName: string, 
        callback: (answer: any) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventUpdateGpsData3", pageName, (event: EventItem) => {
            if(event.eventType === "Retranslate") {
                const eventParameters  = event.eventParameters;
                callback({
                    eventParameters
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventOrderStatusChanged(
        pageName: string, 
        callback: (answer: any) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventOrderStatusChanged", pageName, (event: EventItem) => {
            if(event.eventType === "Retranslate") {
                const orderId  = event.eventParameters["orderId"];
                const orderStatus  = event.eventParameters["orderStatus"];
                callback({
                    orderId,
                    orderStatus
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }

    public static SubscribeEventExecutorLocationChanged(
        pageName: string, 
        callback: (answer: any) => void, 
        callbackError: (error: EventError) => void) 
    {
        EventsHandler.Subscribe("EventExecutorLocationChanged", pageName, (event: EventItem) => {
            if(event.eventType === "Retranslate") {
                const eventParameters  = event.eventParameters;
                callback({
                    eventParameters
                });
            } else {
                const errorMessage = event.eventParameters["errorMessage"];
                const errorCode = event.eventParameters["errorCode"];
                callbackError({ 
                    errorMessage,
                    errorCode,
                });
            }
        });
    }
}

export function errorCallback(error: EventError) {
    console.log('error:', error);
    store.dispatch(eventActions.eventErrorAction(error));
}
