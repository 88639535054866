import { Dispatch, useState } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { actions, RootState } from '../../store';
import { connect } from 'react-redux';
import { 
    Box, ButtonGroup, Grid, IconButton, 
    InputAdornment, TextField, Tooltip 
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import { Trans, useTranslation } from 'react-i18next';
import { TooltipCustom } from '../../utils/TooltipCustom';
import { IMinimalCost, Tariff, TariffParameter } from '../../RemoteCommands/type';
import { replaceItemAtIndex } from '../../utils/replaceItem';
import PropertyDeleteModal from './PropertyDeleteModal';
import { SystemEvent } from '../../RemoteCommands/SystemEvent';
import PeriodDate from './PeriodDate';
import { TimeRange } from '../../RemoteCommands/type';

type Props = 
    ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps> & {
    item: IMinimalCost;
    minimalCosts: IMinimalCost[];
};

// const top100Films = [
//     { title: 'КАД' },
//     { title: 'МКАД' }
// ];

function MinimalCostRow(props: Props) {
    const {t} = useTranslation();
    const {
        item,
        currentTariff,
        currentTariffAction,
        minimalCosts
    } = props;

    // const [zoneType, setZoneType] = useState("geotype");
    // const [borderType, setBorderType] = useState("in");
    const index = minimalCosts.findIndex((listItem) => listItem.rowId === item.rowId);
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    }

    const handleTimeRange = (timeRange: TimeRange) => {
        const newList = replaceItemAtIndex(minimalCosts, index, {
            ...item,
            timeRange
        });
        if(currentTariff) {
            currentTariffAction({
                ...currentTariff,
                minimalCosts: newList
            })
        }
    }

    const minCostChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const name = event.target.name;
        const value = event.target.value;
        let newList: IMinimalCost[] = minimalCosts;
        switch (name) {
            case 'includedKilometers':
                newList = replaceItemAtIndex(minimalCosts, index, {
                    ...item,
                    includedKilometers: {
                        isEnabled: true,
                        value: Number(value),
                    }
                });
                break;
            case 'includedMinutes':
                newList = replaceItemAtIndex(minimalCosts, index, {
                    ...item,
                    includedMinutes: {
                        isEnabled: true,
                        value: Number(value),
                    }
                });
                break;
            case 'distanceRangeFrom':
                newList = replaceItemAtIndex(minimalCosts, index, {
                    ...item,
                    distanceRange: {
                        ...item.distanceRange,
                        from: Number(value),
                        isEnabled: true
                    }
                });
                break;
            case 'distanceRangeTo':
                newList = replaceItemAtIndex(minimalCosts, index, {
                    ...item,
                    distanceRange: {
                        ...item.distanceRange,
                        to: Number(value),
                        isEnabled: true
                    }
                });
                break;
            case 'priorityParameter':
                newList = replaceItemAtIndex(minimalCosts, index, {
                    ...item,
                    priorityParameter: {
                        isEnabled: true,
                        value: Number(value)
                    }
                });
                break;
            default:
                newList = replaceItemAtIndex(minimalCosts, index, {
                    ...item,
                    [name]: Number(value),
                });
        }
        if(currentTariff) {
            currentTariffAction({
                ...currentTariff,
                minimalCosts: newList
            })
        }
    }

    const saveMinimalCost = () => {
        if(!currentTariff) return;
        const tariffParameter: TariffParameter = {
            rowId: item.rowId,
            basicTariffId: currentTariff.basicTariffId,
            forExecutorTariff: false,
            minimalCost: item,
        }
        SystemEvent.EventUpdateTariffParameters(tariffParameter);
    }

    return (
        <>
            <Box className="advanced-tariff">
                <Box className="advanced-tariff__btn">
                    <Grid 
                        container>
                        <Grid item>
                            <Tooltip title={<Trans i18nKey="save" />} placement="top">
                                <IconButton 
                                    size="small" onClick={saveMinimalCost}>
                                    <SaveIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip title={<Trans i18nKey="delete" />} placement="top">
                                <IconButton 
                                    size="small" onClick={() => setOpen(true)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Box>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <ButtonGroup 
                            color="primary"
                            size="small"
                            className="btn-group"
                            fullWidth>
                            <TooltipCustom title={<Trans i18nKey="Supply/Minimalamount"/>} placement="top">
                                <TextField 
                                    placeholder="0.00"
                                    size="small" 
                                    type="number" 
                                    fullWidth
                                    name="cost"
                                    value={item.cost}
                                    onChange={minCostChange}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">{t("currency")}</InputAdornment>
                                    }}
                                />
                            </TooltipCustom>
                            <TooltipCustom title={<Trans i18nKey="km"/>} placement="top">
                                <TextField 
                                    placeholder="0"
                                    size="small" 
                                    type="number" 
                                    fullWidth
                                    value={item.includedKilometers ? item.includedKilometers.value : ""}
                                    name="includedKilometers"
                                    onChange={minCostChange}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">{t("km")}</InputAdornment>
                                    }}
                                />
                            </TooltipCustom>
                            <TooltipCustom title={<Trans i18nKey="Minute" />} placement="top">
                                <TextField 
                                    placeholder="0"
                                    size="small" 
                                    type="number" 
                                    fullWidth
                                    value={item.includedMinutes ? item.includedMinutes.value : ""}
                                    name="includedMinutes"
                                    onChange={minCostChange}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">{t("mines.")}</InputAdornment>
                                    }}
                                />
                            </TooltipCustom>
                        </ButtonGroup>
                    </Grid>
                    {/* <Grid item xs={12}>
                        <ButtonGroup 
                            color="primary"
                            size="small"
                            className="btn-group select-group"
                            fullWidth>
                            <TooltipCustom title={<Trans i18nKey="Geofence" />} placement="top">
                                <FormControl 
                                    variant="outlined"
                                    size="small"
                                    fullWidth>
                                    <Select
                                        value={zoneType}
                                        onChange={(event) => setZoneType(event.target.value as string)}>
                                        <MenuItem value="geotype">{t("Geofence")}</MenuItem>
                                        <MenuItem value="geopoint">{t("Geotox")}</MenuItem>
                                    </Select>
                                </FormControl>     
                            </TooltipCustom>
                            <TooltipCustom title={<Trans i18nKey="border" />} placement="top">
                                <FormControl 
                                    variant="outlined"
                                    size="small"
                                    placeholder={t("border")}
                                    fullWidth>
                                    <Select
                                        value={borderType}
                                        onChange={(event) => setBorderType(event.target.value as string)}>
                                        <MenuItem value="in">{t("in")}</MenuItem>
                                        <MenuItem value="out">{t("dueto")}</MenuItem>
                                        <MenuItem value="Geozone">{t("ontheborder")}</MenuItem>
                                        <MenuItem value="Geozone">{t("halfpriceabroad")}</MenuItem>
                                    </Select>
                                </FormControl>     
                            </TooltipCustom>
                            <TooltipCustom title={<Trans i18nKey="Tariffzone" />} placement="top">
                                <Autocomplete
                                    fullWidth
                                    options={top100Films}
                                    getOptionLabel={(option) => option.title}
                                    renderInput={(params) => 
                                        <TextField 
                                            {...params} 
                                            placeholder={t("Tariffzone")}
                                            variant="outlined" />
                                    }
                                />     
                            </TooltipCustom>
                        </ButtonGroup>
                    </Grid> */}
                    <Grid item xs={12}>
                        <PeriodDate 
                            timeRange={item.timeRange} 
                            handleTimeRange={handleTimeRange} />
                    </Grid>
                    <Grid item xs={12}>
                        <ButtonGroup 
                            color="primary"
                            size="small"
                            className="btn-group"
                            fullWidth>
                            <TooltipCustom title={<Trans i18nKey="Intervalfrom" />} placement="top">
                                <TextField 
                                    placeholder="0"
                                    size="small" 
                                    type="number" 
                                    value={item.distanceRange ? item.distanceRange.from : ""}
                                    name="distanceRangeFrom"
                                    onChange={minCostChange}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">{t("km")}</InputAdornment>
                                    }}
                                />
                            </TooltipCustom>
                            <TooltipCustom title={<Trans i18nKey="Intervalto" />} placement="top">
                                <TextField 
                                    placeholder="0"
                                    size="small" 
                                    type="number" 
                                    value={item.distanceRange ? item.distanceRange.to : ""}
                                    name="distanceRangeTo"
                                    onChange={minCostChange}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">{t("km")}</InputAdornment>
                                    }}
                                />
                            </TooltipCustom>
                            <TooltipCustom title={<Trans i18nKey="Priority" />} placement="top">
                                <TextField 
                                    variant="outlined"
                                    placeholder="0"
                                    size="small" 
                                    type="number"
                                    value={item.priorityParameter ? item.priorityParameter.value : ""}
                                    name="priorityParameter"
                                    onChange={minCostChange}
                                />
                            </TooltipCustom>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            </Box>
            {open && currentTariff &&
                <PropertyDeleteModal 
                    open={open} 
                    handleClose={handleClose}
                    basicTariffId={currentTariff.basicTariffId} 
                    rowId={item.rowId} 
                    array={minimalCosts}
                    name="minimalCosts"
                    currentTariff={currentTariff}
                    currentTariffAction={currentTariffAction} />
            }
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    currentTariff: state.tariffs.currentTariff
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({ 
    currentTariffAction: (currentTariff: Tariff | undefined) => 
        dispatch(actions.tariffs.currentTariffAction(currentTariff)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MinimalCostRow);