import { Dispatch, useState, useEffect} from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { actions, RootState } from '../../store';
import { connect } from 'react-redux';
import { errorCallback, SystemEvent } from '../../RemoteCommands/SystemEvent';
import { SpecialServices } from '../../RemoteCommands/type';
import { useTranslation } from 'react-i18next';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Checkbox, FormControlLabel, Tooltip } from '@material-ui/core';

type Props = 
    ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps>;

function AdditionalServices(props: Props) {
    const { t } = useTranslation();
    const {
        additionalServices,
        additionalServicesAction
    } = props;

    const [specialServices, setSpecialServices] = useState<SpecialServices[]>([]);

    useEffect(() => {
        SystemEvent.SubscribeEventGetSpecialServices(
            "AdditionalServicesModal", 
            (answer) => {
                setSpecialServices(answer.specialServices);
            }, 
            (error) => {
                errorCallback(error);
            }
        );
        SystemEvent.EventGetSpecialServices();
    }, []);

    const handleToggle = (value: string) => () => {
        const currentIndex = additionalServices.indexOf(value);
        const newChecked = [...additionalServices];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        additionalServicesAction(newChecked);
    };

    const settings = {
        dots: false,
        arrows: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4
    };
    return (
        <div className="additionalServices">
            <Slider 
                {...settings}>
                {specialServices.map((service) => {
                    return (
                        <Tooltip 
                            title={service.displayName} 
                            placement="top">
                            <FormControlLabel
                                key={service.serviceName}
                                className="formControlLabel"
                                control={
                                    <Checkbox 
                                        checked={additionalServices.indexOf(service.serviceName) !== -1}
                                        onChange={handleToggle(service.serviceName)} />
                                }
                                label={`${service.displayName}`}
                            />
                        </Tooltip>
                    );
                })}
            </Slider>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    additionalServices: state.event.additionalServices
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({ 
    additionalServicesAction: (additionalServices: string[]) => 
        dispatch(actions.event.additionalServicesAction(additionalServices))
});

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalServices)
  