import { Dispatch, useEffect, useState } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { RootState, actions } from '../../store';
import { connect } from 'react-redux';
import { ActiveOrdersTable } from './ActiveOrdersTable';
import { errorCallback, SystemEvent } from '../../RemoteCommands/SystemEvent';
import ContextMenu from '../bricks/ContextMenu';
import { IActiveOrders } from '../../RemoteCommands/type';
import { AssignDriverContainer } from '../AssignDriver';
import MessageShackbar from '../bricks/MessageShackbar';
import './ActiveOrdersTable.scss';
import { useTranslation } from 'react-i18next';

type Props = 
    ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps>;

function ActiveOrdersTableContainer(props: Props) {
    const { t } = useTranslation();
    const {
        activeOrders,
        openOrderAction
    } = props;

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [activeOrder, setActiveOrder] = useState<IActiveOrders | null>(null);
    const [open, setOpen] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
        setAnchorEl(null);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const contextMenuOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>, activeOrder: IActiveOrders) => {
        event.preventDefault();
        setActiveOrder(activeOrder);
        setAnchorEl(event.currentTarget);
    };
    
    const contextMenuClose = () => {
        setAnchorEl(null);
    };

    const openOrderInfoClick = (orderID: number) => {
        openOrderAction(true);
        SystemEvent.EventGetOrderInfo(orderID);
    }

    useEffect(() => {
        SystemEvent.SubscribeEventWithdrawOrderFromExecutor(
            "EventWithdrawOrderFromExecutor", 
            (answer) => {
                setSuccess(answer.success);
                SystemEvent.EventGetActiveOrdersForDispatcher();
            }, 
            (error) => {
                errorCallback(error);
            }
        );
    })

    const removeOrderClick = () => {
        if(activeOrder) {
            SystemEvent.EventWithdrawOrderFromExecutor(activeOrder.orderId);
            setAnchorEl(null);
        }
    }

    const handleMessageClose = () => {
        setSuccess(false);
    }

    return (
        <>
            <ActiveOrdersTable 
                activeOrders={activeOrders} 
                openOrderInfoClick={(orderID: number) => 
                    openOrderInfoClick(orderID)} 
                contextMenuOpen={(event, activeOrder) => contextMenuOpen(event, activeOrder)}
            />
            {activeOrder &&
                <ContextMenu 
                    anchorEl={anchorEl}  
                    contextMenuClose={contextMenuClose} 
                    activeOrder={activeOrder} 
                    handleClickOpen={handleClickOpen} 
                    removeOrderClick={removeOrderClick} />
            }
            {activeOrder && 
                <AssignDriverContainer 
                    activeOrder={activeOrder} 
                    open={open} 
                    handleClose={handleClose} />
            }
            {success && 
                <MessageShackbar 
                    message={t("youWithdrawnDriver")}
                    variant="success"
                    messageOpen={success} 
                    vertical="top"
                    horizontal="center" 
                    messageShackbarClose={handleMessageClose} />
            }
        </>
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        activeOrders: state.event.activeOrders
    }
};

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({ 
    openOrderAction: (openOrder: boolean) => 
        dispatch(actions.event.openOrderAction(openOrder)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActiveOrdersTableContainer);