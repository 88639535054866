import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { actions, RootState } from '../../store';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { Tooltip } from '@material-ui/core';
import WidgetAutocomplete from '../WidgetAutocomplete/WidgetAutocomplete';
import ClearIcon from '@material-ui/icons/Clear';
import { AddressInfo, SearchType } from 'sedi-webserverproxy';
import { Trans } from 'react-i18next';
import { localStorageRemoveItem } from '../../utils/storage';

type Props = ReturnType<typeof mapStateToProps> 
    & ReturnType<typeof mapDispatchToProps> & {
    addressInfo: AddressInfo | null;
    value?: string;
    placeholder?: string;
    disabled?: boolean;
    inputClassName?: string;
    onChange: (value: string) => void;
    cityName?: string | undefined;
}

const GetCityAutocomplete:React.FC<Props> = (props) => {
    const {
        placeholder,
        disabled,
        inputClassName, 
        value,
        onChange,
        cityName,
        addressValueAction
    } = props;

    const addressChange = (newValue: string) => {
        onChange(newValue);
        if(newValue.length === 0) {
            addressValueAction("");
            localStorageRemoveItem("city");
        }
    }

    return (
        <div className="getAddressAutocomplete-row cancelDraggable">
            <WidgetAutocomplete 
                value={value}
                inputClassName={`getAddressAutocomplete ${inputClassName}`}
                inputClassNameLoading="getAddressAutocomplete"
                onChangeInput={(newValue: string) => addressChange(newValue)} 
                cityClassName="getAddressAutocomplete-city" 
                errorClassName="getAddressAutocomplete-error" 
                placeholder={placeholder}
                disabled={disabled} 
                searchTypeCity={SearchType.city} 
                cityName={cityName} 
                onlyCity={true} />
            {!disabled && 
                <Tooltip title={<Trans i18nKey="clearField" />} placement="top">
                    <ClearIcon 
                        className="getAddressAutocomplete-icon"
                        onClick={() => addressChange("")} />
                </Tooltip>
            }
        </div>
    );
}

const mapStateToProps = (state: RootState) => ({
    selectedAddress: state.event.selectedAddress
});

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({
    addressValueAction: (addressValue: string) => 
        dispatch(actions.event.addressValueAction(addressValue)),
});


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GetCityAutocomplete);