
import React, { Dispatch, useEffect } from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { Action, AnyAction } from 'redux';
import { actions, RootState } from '../../store';
import { connect } from 'react-redux';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { EmployeeRole } from '../../store/event/types';
import { errorCallback, SystemEvent } from '../../RemoteCommands/SystemEvent';

type Props = 
    ReturnType<typeof mapDispatchToProps> 
    & ReturnType<typeof mapStateToProps> & {
    currentRoles: EmployeeRole | null;
    handleRole: (roles: EmployeeRole | null) => void;
    error?: boolean;
    variant?: "standard" | "filled" | "outlined";
    edit?: boolean;
    label?: string;
};

function RoleAutocomplete(props: Props) {
    const { t } = useTranslation();
    const { handleRole, employeeRoles, variant, currentRoles, edit, label, employeeRolesAction, lang } = props;

    useEffect(() => {
        SystemEvent.SubscribeEventGetEmployeeRolesList(
            "RoleAutocomplete", 
            (answer) => {
                employeeRolesAction(answer.roles);
            }, 
            (error) => {
                errorCallback(error);
            }
        );
        SystemEvent.EventGetEmployeeRolesList(lang);
    }, []);

    const roleOnChange = (changeValue: EmployeeRole | null) => {
        handleRole(changeValue);
    }
    

    return (
        <Autocomplete
            options={employeeRoles}
            className="cancelDraggable"
            getOptionLabel={(option: EmployeeRole) => option.roleLocalization}
            onChange={(event: React.ChangeEvent<{}>, changeValue: EmployeeRole | null) => roleOnChange(changeValue)}
            defaultValue={currentRoles}
            value={currentRoles}
            disabled={edit}
            size="small"
            fullWidth
            renderInput={params => (
                <TextField
                    {...params}
                    variant={variant}
                    label={label || t("roles")}
                    fullWidth
                    size="small"
                />
            )}
        />
    );
};

const mapStateToProps = (state: RootState) => {
    return {
        employeeRoles: state.event.employeeRoles,
        lang: state.app.lang
    }
};

const mapDispatchToProps = (dispatch: Dispatch<Action> & ThunkDispatch<any, any, AnyAction>) => ({ 
    employeeRolesAction: (employeeRoles: EmployeeRole[]) => 
        dispatch(actions.event.employeeRolesAction(employeeRoles))
});

export default connect(mapStateToProps, mapDispatchToProps)(RoleAutocomplete);